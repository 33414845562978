
const resolveProperty = (obj: object, keys: any) => {
  return keys.split('.').reduce(function (cur: any, key: any) {
    return cur[key];
  }, obj);
};

const proxyToPlain = (obj: object) => {
  if (obj) {
    return JSON.parse(JSON.stringify(obj));
  }
  return {};
};
function isDefined(obj: any): boolean {
  return obj !== undefined && obj !== null && obj !== '';
}
export { resolveProperty, proxyToPlain, isDefined };

