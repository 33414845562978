import Client from '@/api/client/httpClient';
import type { APIListResponse, APIResponse } from '@/api/client/client';
import { schemas } from '@/api/clients/ManagementConfigApi';
import { z } from 'zod';

type TenantDto = z.infer<typeof schemas.TenantDto>;
type UpdateUserRequest = z.infer<typeof schemas.UpdateUserRequest>;
type CreateUserRequest = z.infer<typeof schemas.CreateUserRequest>;
type PageUserDto = z.infer<typeof schemas.PageUserDto>;
type RoleRepresentation = z.infer<typeof schemas.RoleRepresentation>;
type KeycloakUserSearch = z.infer<typeof schemas.KeycloakUserSearch>;

export default class UserManagementApi extends Client {
  constructor(baseUrl = import.meta.env.VITE_SERVICE_ADMIN_CONFIG) {
    super(baseUrl);
    this.baseUrl = baseUrl;
  }

  //getUsersPageByTenantIdAndEnabledCriteria_2
  public async getUsers(query: string) {
    return this.instance.get<APIListResponse<PageUserDto>>(`/management-config/v2/tenant/users/${query}`);
  }

  //getUsersByAccessLevel
  public async getUsersByAccessLevel(query:string) {
    return this.instance.get<APIListResponse<PageUserDto>>(`management-config/v2/tenant/users/accesslevels/${query}`);
  }

  //disableUser_1
  public async deleteUser(userId: string) {
    return this.instance.delete<APIResponse<string>>(`/management-config/v1/tenant/users/id/${userId}`);
  }

  //updateUser_1
  public async updateUser(userId: string, userRecord: UpdateUserRequest) {
    return this.instance.patch<APIResponse<TenantDto>>(`/management-config/v1/tenant/users/id/${userId}`, userRecord);
  }

  //createUser_1
  public async createUser(userRecord: CreateUserRequest) {
    return this.instance.post<APIResponse<TenantDto>>(`/management-config/v1/tenant/users`, userRecord);
  }

  //getCustomerTenantUserAccessLevelsV2
  public async getAccessLevels() {
    return this.instance.get<APIListResponse<RoleRepresentation>>('/management-config/v2/tenant/accesslevels');
  }

  //searchCustomerUsers
  public async searchUsers(searchObj: KeycloakUserSearch, query: string) {
    return this.instance.post<APIListResponse<PageUserDto>>(`/management-config/v1/tenant/users/search${query}`, searchObj);
  }
}