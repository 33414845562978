import Client from '@/api/client/httpClient';
import type { APIListResponse } from '@/api/client/client';
import type { WorkOrders } from '../schema';
import { schemas } from '@/api/clients/AssetUiApi';
import { z } from 'zod';

type WorkorderDto = z.infer<typeof schemas.WorkorderDto>;

export default class WorkOdersApi extends Client {
  constructor(baseUrl = import.meta.env.VITE_SERVICE_WORK_ORDER_ANALYTICS) {
    super(baseUrl);
    this.baseUrl = baseUrl;
  }

  getWorkOrdersPartsHealth() {
    return this.instance.get<WorkorderDto[]>(`/workorder-analytics/v1/parts/health`);
  }

  getWorkOrdersParts() {
    return this.instance.get<any>(`/workorder-analytics/v1/parts`);
  }

  geShopsCost(filterRequest: WorkOrders['AssetTaskFiltersRequest']) {
    return this.instance.post<WorkOrders['CostsByShopDto']>(`/workorder-analytics/v1/shops/costs`, filterRequest);
  }

  getReasonsCosts(filterRequest: WorkOrders['AssetTaskFiltersRequest']) {
    return this.instance.post<WorkOrders['CostsByReasonDto']>(`/workorder-analytics/v1/reasons/costs`, filterRequest);
  }

  getPartsCosts(filterRequest: WorkOrders['AssetTaskFiltersRequest']) {
    return this.instance.post<WorkOrders['CostsByPartDto']>(`/workorder-analytics/v1/parts/costs`, filterRequest);
  }

  getCostsYear(filterRequest: WorkOrders['AssetTaskFiltersRequest']) {
    return this.instance.post<WorkOrders['CostsByWorkorderYearDto']>(`/workorder-analytics/v1/costs/year`, filterRequest);
  }
  
  getCostsSummary(filterRequest: WorkOrders['AssetTaskFiltersRequest']) {
    return this.instance.post<WorkOrders['CostsSummaryDto']>(`/workorder-analytics/v1/costs/summary`, filterRequest);
  }

  getTotalCostsByAssetConfiguration(filterRequest: WorkOrders['AssetTaskFiltersRequest']) {
    return this.instance.post<WorkOrders['CostsByAssetConfigurationDto']>(`/workorder-analytics/v1/costs/assets/configurations`, filterRequest);
  }

  getCostsFilters(filterRequest: WorkOrders['AssetTaskFiltersRequest']) {
    return this.instance.post<WorkOrders['AssetTaskFiltersDto']>(`/workorder-analytics/v1/costs/filters`, filterRequest);
  }
  
  getComplaintsCosts(filterRequest: WorkOrders['AssetTaskFiltersRequest']) {
    return this.instance.post<WorkOrders['CostsByComplaintDto']>(`/workorder-analytics/v1/complaints/costs`, filterRequest);
  }
  
  getAssetsSummary(filterRequest: WorkOrders['AssetTaskFiltersRequest']) {
    return this.instance.post<WorkOrders['AssetSummaryDto']>(`/workorder-analytics/v1/assets/summary`, filterRequest);
  }

  getWorkOrdersPartsSurvivability(query?: string) {
    return this.instance.get<APIListResponse<WorkOrders['WorkorderDto']>>(`/workorder-analytics/v1/assets/parts/survivability?period=P90D`);
  }

  getWorkOrdersPartsSurvivalCurves(query: string) {
    return this.instance.get<any>(`workorder-analytics/v1/parts/survival/curves${query}`);
  }

  getAssetDowntime(filterRequest: WorkOrders['AssetTaskFiltersRequest']) {
    return this.instance.post<WorkOrders['AssetDowntimeDto']>(`/workorder-analytics/v1/assets/downtime`, filterRequest);
  }

  getAssetDetails(filterRequest: WorkOrders['AssetTaskFiltersRequest']) {
    return this.instance.post<WorkOrders['AssetDetailsDto']>(`/workorder-analytics/v1/costs/assets/details`, filterRequest);
  }

  getYearDetails(filterRequest: WorkOrders['AssetTaskFiltersRequest']) {
    return this.instance.post<WorkOrders['CostsByWorkorderYearDetailsDto']>(`/workorder-analytics/v1/costs/year/details`, filterRequest);
  }
}