<template>
  <div
    :id="$style.sidebar"
    :class="horizontalNav ? $style['horizontal-sidebar'] : $style['vertical-sidebar']"
  >
    <nav>
      <template
        v-for="item in allListings"
        :key="item.text"
      >
        <component
          :is="item.disabled ? 'span' : 'router-link'"
          :to="item.path"
          :href="item.path"
          :active-class="$style['active-route']"
          :class="{ [$style['disabled']]: item.disabled }"
          :style="{ 'text-decoration': 'none' }"
        >
          <div
            :class="[
              { [$style['sidebar-item']]: true },
              { [$style['sidebar-item--disabled']]: item.disabled },
              { [$style['sidebar-item--active']]: true },
            ]"
          >
            <div :class="$style['sidebar-item__border']">
              <div :class="$style['sidebar-item__link']">
                <div :class="[$style['sidebar-item__link__content'], { [$style['sidebar-item--locked']]: true }]">
                  <component
                    :is="item.icon"
                    :style="{
                      width:
                        item.path === '/AssetsInsightsCases'
                          ? '50px'
                          : item.path === '/FleetInsightAssessment'
                            ? '45px'
                            : '35px',
                    }"
                    :class="$style['sidebar-item__image']"
                  />
                  <p
                    :data-cy="item.cypressId"
                    :aria-label="item.text + ' Page'"
                  >
                    {{ item.text }}
                  </p>
                </div>
              </div>
            </div>
          </div>
        </component>
      </template>
    </nav>
    <div
      v-if="horizontalNav"
      :class="$style['icon-set']"
    >
      <HelpIcon
        :class="$style.icon"
        data-cy="Help_icon"
        @click="handleHelpClick"
      />
      <router-link
        v-if="product === 'commercial'"
        to="/Notifications"
      >
        <BellIcon
          :class="$style.icon"
          data-cy="Notification_management_icon"
        />
      </router-link>
    </div>
  </div>
</template>

<script lang="ts" setup>
import {
  SiteSectionImageFilename,
  SiteSectionHref,
  SiteSectionText,
  SiteSectionCustomPropertyValue,
} from '@/components/constants/SiteNavigation';
import { computed, ref, watchEffect } from 'vue';
import { useLocalStorage } from '@/utils/localStorageUtils';
import useTenantStore from '@/features/auth/tenant/store/tenantStore';
import useAuthStore from '@/features/auth/store/authStore';
import { addNewRoute } from '@/router/dynamicRoutes';
import type { SiteNavigationMenuItem, RouteInfo } from '@/components/types/shared';
import type { Dashboard } from '@/features/dashboards/types';


const tenantStore = useTenantStore();

const handleHelpClick = () => {
  window.open(tenantStore.helpPDFPath, '_blank');
};
const horizontalNav = computed(() => tenantStore.horizontalNav);
const product = ref(tenantStore.config.product);
const quickSightDashboards = computed(() => tenantStore.quickSightDashboardsList);
const workOrdersEnabled = computed(() => tenantStore.workOrderAnalyticsFeatureEnabled);
const FIADashboardEnabled = computed(() => tenantStore.fleetInsightAssessmentDashboardFeatureEnabled);
const allListings = computed(() => getAllListings(quickSightDashboards.value, product.value));

function getAllListings(quickSightDashboards: Dashboard | undefined, product: string | undefined) {
  if (!quickSightDashboards || !product) {
    return [];
  }
  const defaultListing = getDefaultListing();
  // const quickSightMenuItems = getQuickSightMenuItems(quickSightDashboards);

  return product === 'federal'
    ? [...defaultListing, getCalculationDetails()]
    : [...defaultListing];
}

watchEffect(() => {
  const newProduct = useLocalStorage().product;
  if (newProduct !== product.value) {
    product.value = newProduct;
  }
});

// watch(product, (newProduct, oldProduct) => {
//   if (newProduct !== oldProduct) {
//     clearDynamicRoutes();
//   }
// });

function getDefaultListing(): SiteNavigationMenuItem[] {
  const listing = [getAIC(), getRiskExplorer()];
  if (workOrdersEnabled.value) listing.push(getWorkOrderAnalytics());
  if (FIADashboardEnabled.value && useAuthStore().isAdmin && product.value === 'commercial')
    listing.push(getFIADashboard());
  return listing;
}

function getQuickSightMenuItems(quickSightDashboards?: Dashboard[]): SiteNavigationMenuItem[] {
  const menuItems: SiteNavigationMenuItem[] = [];
  if (quickSightDashboards) quickSightDashboardItem(menuItems, quickSightDashboards);
  return menuItems;
}

function quickSightDashboardItem(container: SiteNavigationMenuItem[], quickSightDashboards: Dashboard) {
  quickSightDashboards.forEach((dashboard: Dashboard) => {
    const dashboardTitle = dashboard.title.replace(/\W/g, '');
    if (dashboard.enabled && dashboard.embedded_link) {
      container.push({
        disabled: false,
        text: dashboard.title,
        icon: `${dashboardTitle}Icon` || 'DefaultIcon',
        path: `/${dashboardTitle}`,
        cypressId: `[data-cy=SectionLink_${dashboardTitle}Icon]`,
      });

      const newRouteInfo: RouteInfo = {
        path: `/${dashboardTitle}`,
        name: dashboard.title,
      };
      addNewRoute(newRouteInfo);
    }
  });
}

function getRiskExplorer(): SiteNavigationMenuItem {
  return {
    disabled: false,
    text: SiteSectionText.RiskExplorer,
    icon: SiteSectionImageFilename.RiskExplorer,
    path: SiteSectionHref.RiskExplorer,
    cypressId: SiteSectionCustomPropertyValue.RiskExplorer,
  };
}

function getAIC(): SiteNavigationMenuItem {
  const text =
    product.value === 'federal' || !tenantStore.casesFeatureEnabled
      ? SiteSectionText.AssetsInsights
      : SiteSectionText.AssetsInsightsCases;

  let aicImage = SiteSectionImageFilename.AssetsInsightsCases;
  if (product.value === 'federal') {
    aicImage = SiteSectionImageFilename.FederalAssets;
  }
  return {
    disabled: false,
    text,
    icon: aicImage,
    path: SiteSectionHref.AssetsInsightsCases,
    cypressId: SiteSectionCustomPropertyValue.AssetsInsightsCases,
  };
}

function getWorkOrderAnalytics(): SiteNavigationMenuItem {
  return {
    disabled: false,
    text: SiteSectionText.WorkOrderAnalytics,
    icon: SiteSectionImageFilename.WorkOrderAnalytics,
    path: SiteSectionHref.WorkOrderAnalytics,
    cypressId: SiteSectionCustomPropertyValue.WorkOrderAnalytics,
  };
}

function getFIADashboard(): SiteNavigationMenuItem {
  return {
    disabled: false,
    text: SiteSectionText.FleetInsightAssessment,
    icon: SiteSectionImageFilename.FleetInsightAssessment,
    path: SiteSectionHref.FleetInsightAssessment,
    cypressId: SiteSectionCustomPropertyValue.FleetInsightAssessment,
  };
}
function getCalculationDetails(): SiteNavigationMenuItem {
  return {
    disabled: false,
    text: SiteSectionText.CalculationDetails,
    icon: SiteSectionImageFilename.CalculationDetails,
    path: SiteSectionHref.CalculationDetails,
    cypressId: SiteSectionCustomPropertyValue.CalculationDetails,
  };
}
</script>

<style module lang="scss">
#sidebar {
  background-color: ds-get-color(uptake-nav-default-background);
  border-radius: $border-radius;
  width: inherit;
  overflow: hidden;

  p {
    margin: 0px;
  }

  .icon-set {
    display: flex;
    align-items: center;
    padding-right: 40px;
    gap: 30px;

    .icon {
      fill: ds-get-color(uptake-interactive-text-color);

      &:hover {
        cursor: pointer;
      }
    }
  }

  &.vertical-sidebar {
    height: 100%;

    .sidebar-item {
      &__border {
        padding: 20px 0;
      }

      &__link {
        width: 90px;
      }
    }
  }

  &.horizontal-sidebar {
    display: flex;
    justify-content: space-between;
    align-items: center;
    height: 90px;

    nav {
      display: flex;
      height: 100%;
    }

    .sidebar-item {
      height: 100%;
      align-items: center;

      &__border {
        border-left: 1px solid ds-get-color(uptake-nav-selected-background);
      }

      &__link {
        width: 112px;
        height: 65%;
        padding: 0px 40px;

        &__content {
          display: flex;
          height: 100%;
          flex-direction: column;
          justify-content: space-around;
        }
      }
    }
  }

  .sidebar-item {
    display: flex;
    justify-content: center;

    &__image {
      display: block;
      height: 35px;
      // margin-bottom: 5px;
      margin-left: auto;
      margin-right: auto;
      width: 35px;
      fill: ds-get-color(uptake-nav-default-font-color);
    }

    &__link {
      color: ds-get-color(uptake-nav-default-font-color);
      cursor: pointer;
      display: flex;
      font-size: 13px;
      justify-content: center;
      margin-left: auto;
      margin-right: auto;
      position: relative;
      text-align: center;
      text-decoration: none;
      text-transform: uppercase;
      @include ds-font(medium);

      &:focus {
        filter: invert(80%) sepia(29%) saturate(321%) hue-rotate(164deg) brightness(96%) contrast(79%);
      }
    }

    &__text {
      margin-left: -4px;
      margin-right: 5px;
      text-transform: lowercase;
    }

    &__lock-icon {
      background-image: url("data:image/svg+xml;utf8,<svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 21' fill='%23ffffff'><path d='M14,7H13V5A5,5,0,0,0,3,5V7H2A2.006,2.006,0,0,0,0,9V19a2.006,2.006,0,0,0,2,2H14a2.006,2.006,0,0,0,2-2V9a2.006,2.006,0,0,0-2-2M8,16a2,2,0,1,1,2-2,2.006,2.006,0,0,1-2,2m3.1-9H4.9V5a3.1,3.1,0,0,1,6.2,0Z'/></svg>");
      background-repeat: no-repeat;
      height: 21px;
      position: absolute;
      right: 17px;
      top: 22px;
      width: 16px;
    }

    &:after {
      background: #62778c; //need to difine in _colors.scss
      bottom: -1px;
      content: '';
      display: block;
      height: 2px;
      left: auto;
      opacity: 0.5;
      position: absolute;
      width: calc(100% - 40px);
    }

    &:last-of-type::after {
      display: none;
    }

    &:hover {
      background-color: ds-get-color(uptake-nav-hover-background);

      div {
        color: ds-get-color(uptake-nav-hover-font-color);
      }

      svg {
        fill: ds-get-color(uptake-nav-hover-font-color);
      }
    }
  }

  .sidebar-item--disabled {
    cursor: not-allowed;

    &:hover {
      background-color: unset !important;
    }

    .sidebar-item--locked {
      cursor: not-allowed;

      p,
      .sidebar-item__image,
      .sidebar-item__link {
        opacity: 0.5;
      }
    }
  }

  .active-route {
    .sidebar-item--active {
      background-color: ds-get-color(uptake-nav-selected-background);

      div {
        color: ds-get-color(uptake-nav-selected-font-color);
        border-bottom: none;
      }

      svg {
        fill: ds-get-color(uptake-nav-selected-font-color);
      }
    }
  }

  a:last-child {
    .sidebar-item__border {
      border-bottom: none;
    }
  }
}
</style>
