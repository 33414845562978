import type { APIResponse } from '@/api/client/client';
import Client from '@/api/client/httpClient';
import { type AnalyticsDashboard } from '@/features/dashboards/types/dashboardTypes';

export default class AnalyticsApi extends Client {
  constructor(baseUrl = import.meta.env.VITE_VISUAL_PROXY_API) {
    super(baseUrl);
    this.baseUrl = baseUrl;
  }

  getDashboard(dashboardId: string) {
    return this.instance.get<APIResponse<AnalyticsDashboard>>(`/visual-proxy/dashboard/${dashboardId}`);
  }
}