enum Constants {
  taxonomies = 'taxonomies',
  currentPage = 'currentPage',
  tenant = 'tenant',
  filterId = 'filterId',
  insightsTableSort = 'insightsTableSort',
}
const setLocalStorage = (key: string, data: string) => {
  window.localStorage.setItem(key, data);
};
const getLocalStorage = (key: string) => {
  return window.localStorage.getItem(key);
};
const deleteLocalStorage = (name: string) => {
  window.localStorage.removeItem(name);
};
const localStorageApi = {
  setLocalStorage,
  getLocalStorage,
  deleteLocalStorage,
  Constants,
};

interface ILocalStorage {
  currentPage: any;
  tenant: any;
  product?: string;
  filterId: string | null | undefined;
  termsConsents: string[] | null;
  userSavedFilterId: string | null | undefined;
  enableCase?:string;
  insightsTableSort: object;
  filtersAic?: string;
  filtersUav?: string;
  insight?: any;

  // Support for legacy calls:
  // (e.g. useLocalStorage().getLocalStorage(useLocalStorage().Constants.filterId)

  /** @deprecated
   * Use setters instead e.g.: useLocalStorage().tenant = {};
   */
  setLocalStorage: (key: string, data: string) => void;
  /** @deprecated
   * Use getters instead e.g.: useLocalStorage().tenant
   */
  getLocalStorage: (key: string) => never;
  /** @deprecated
   * Use setters with `null` values e.g.: useLocalStorage().tenant = null;
   */
  deleteLocalStorage: (key: string) => void;
  /** @deprecated */
  Constants: { [key in Constants]: never };
}

const isLegacyCall = (prop: string) =>
  ['setLocalStorage', 'getLocalStorage', 'deleteLocalStorage', 'Constants'].includes(prop);

const localStorageProxy = new Proxy(localStorageApi as ILocalStorage, {
  get(target: ILocalStorage, prop) {
    if (isLegacyCall(prop as string)) {
      return (target as any)[prop];
    }
    const value = window.localStorage.getItem(prop as string);
    try {
      return JSON.parse(value as never); // casting because TS needs string, but JSON.parse accepts null
    } catch (err) {
      if (err instanceof SyntaxError) {
        return value; // `undefined` and data that hasn't been serialized prior to saving
      }
      throw err;
    }
  },

  set(target: ILocalStorage, storageKey: string, value: never) {
    if (isLegacyCall(storageKey)) {
      return false;
    }
    // if a storage key doesn't exist, `localStorage.getItem` returns null,
    // so setting a key to null becomes equivalent to removing it
    if (value === null) {
      window.localStorage.removeItem(storageKey);
    } else {
      window.localStorage.setItem(storageKey, JSON.stringify(value));
    }
    return true;
  },
});

export function useLocalStorage(): ILocalStorage {
  return localStorageProxy;
}
