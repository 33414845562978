import type { RouteRecordRaw } from 'vue-router';
import { defaultGuardProvider } from './helpers';

const federal: Array<RouteRecordRaw> = [
  {
    path: '/CalculationDetails',
    name: 'Calculation Details',
    beforeEnter: defaultGuardProvider(),
    component: () => import('@/features/federal/views/CalculationDetails.vue'),
  },
];

export default federal;
