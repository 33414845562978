import type { Option } from '@/features/sitewideFilters/types/sitewideFiltersTypes';
import type { Component } from 'vue';

export enum FleetModalButton {
  close = 'close',
  cancel = 'cancel',
  submit = 'submit',
  back = 'back',
}

//   enum FleetModalButton {
//     close = t('manageInsights.FleetModalButton.close'),
//     cancel = t('manageInsights.FleetModalButton.cancel'),
//     submit = t('manageInsights.FleetModalButton.submit'),
//     back = t('manageInsights.FleetModalButton.back'),
//   }

export type Column = 'title' | 'customer_asset_name' | 'customer_asset_id';

export interface TableColumn {
  id: string;
  label: string;
  sortName: string;
  sortable: boolean;
}
export interface ColumnHeader {
  id: string;
  label: string;
  align?: string;
  sortable?: boolean;
}

export interface Dropdown {
  id: string;
  title: string;
  tag: string;
  options: Array<Option>;
  prevOptions?: null | Array<Option>;
  show?: null | boolean;
  search?: null | string;
}

export interface SiteNavigationMenuItem {
  disabled: boolean;
  text: string;
  icon: Component;
  path: string;
  cypressId: string;
}

export type IconType = {
  [key: string]: any;
};

export interface RouteInfo {
  path: string;
  name: string;
  beforeEnter?: () => void;
  component: () => Promise<typeof import('@/features/dashboards/quicksight/views/QuickSightView.vue')>;
}

export enum FleetCookie {
  Authenticating = 'authenticating',
  Authenticated = 'authenticated',
  SignedTermsOfUseFederal = 'uptakeSignedTerms',
  LogOutStarted = 'loggedOutStarted',
  LoggedOut = 'loggedOut',
  Realm = 'realm',
  CurrentPage = 'currentPage',
}

export enum SortDirection {
  ASC = 'ASC',
  DESC = 'DESC',
}

export interface TimelineData {
  id: number;
  item: {
    id: number;
    description: string;
    date: string;
  };
  enabled: boolean;
}

export interface TimelineCategory {
  description: string;
  startDate: string;
  endDate: string;
  type: string;
  color: string;
}

export interface TimelineXAxis {
  startLabel: string;
  middleLabel: string;
  endLabel: string;
  type: string;
}

export interface ErrorMessageProps {
  errorTitle?: string;
  errorSubtitle?: string;
}

export interface LegendItem {
  color: string;
  title: string;
}

export interface DataObject {
  name: string;
  y: number;
  color: string;
  dataLabels: any;
}

export interface validationResponse {
  valid: Boolean;
  message: String;
}

export enum Action {
  INSIGHTS,
  ACTIONED_BY,
}

export type OptionType = {
  disabled: boolean;
  searchResult: boolean;
  selected: boolean;
  title: string;
  value: string;
};
