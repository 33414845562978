import type { RouteRecordRaw } from 'vue-router';
import { defaultGuardProvider } from './helpers';

const fleet: Array<RouteRecordRaw> = [
  {
    path: '/Notifications',
    name: 'NotificationManagement',
    beforeEnter: defaultGuardProvider(),
    component: () => import('@/features/notifications/views/NotificationManagementView.vue'),
    meta: { 
      title: 'Notification Management' 
    }
  },
  {
    path: '/WorkOrderAnalytics',
    name: 'WorkOrderAnalytics',
    beforeEnter: defaultGuardProvider(),
    component: () => import('@/features/workOrder/workOrderAnalytics/views/WorkOrderAnalyticsView.vue'),
    meta: {
      workOrderAnalytics: true,
      title: 'Work Order Analytics'
    },
    children: [
      {
        name: 'workOrderAnalytics.componentHealthAnalytics',
        path: 'componentHealthAnalytics',
        beforeEnter: defaultGuardProvider(),
        component: () => import('@/features/workOrder/componentHealthAnalytics/views/ComponentHealthAnalyticsView.vue'),
        meta: {
          workOrderAnalytics: true,
          title: 'WOA - Component Health Analytics'
        },
      },
      {
        name: 'workOrderAnalytics.costAnalytics',
        path: 'costAnalytics',
        beforeEnter: defaultGuardProvider(),
        component: () => import('@/features/workOrder/costAnalytics/views/CostAnalyticsView.vue'),
        meta: {
          workOrderAnalytics: true,
          title: 'WOA - Cost Analytics'
        },
      },
      {
        name: 'workOrderAnalytics.downtimeAnalytics',
        path: 'downtimeAnalytics',
        beforeEnter: defaultGuardProvider(),
        component: () => import('@/features/workOrder/downtimeAnalytics/views/DowntimeAnalyticsView.vue'),
        meta: {
          workOrderAnalytics: true,
          title: 'WOA - Downtime Analytics'
        },
      },
    ],
  },
  {
    path: '/FleetInsightAssessment',
    name: 'FleetInsightAssessment',
    beforeEnter: defaultGuardProvider(),
    component: () => import('@/features/dashboards/FIA/FleetInsightAssessmentView.vue'),
    meta: {
      title: 'Fleet Insight Assessment'
    },
  }
];

export default fleet;
