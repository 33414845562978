import { createRouter, createWebHistory } from 'vue-router';
import { useLocalStorage } from '@/utils/localStorageUtils';
import federal from '@/router/federal';
import fleet from '@/router/fleet';
import shared from '@/router/shared';
import admin from '@/router/admin';
import Analytics from '@/router/analytics/analytics';
import { AnalyticsEventType } from '@/router/analytics/analyticsData';

export const routes: any = [...shared];

if (useLocalStorage().product === 'commercial') {
  routes.push(...fleet);
}

if (useLocalStorage().product === 'federal') {
  routes.push(...federal);
}

routes.push(... admin);

const router = createRouter({
  history: createWebHistory(import.meta.env.BASE_URL),
  routes,
});

router.afterEach((to, from) => {
  const pageTitle = to.meta.title || document.title; 
  Analytics.pageView(to.path, pageTitle);
});

export const mergedRoutes = routes;

export default router;
