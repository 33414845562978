import Client from '@/api/client/httpClient';
import { type Taxonomy } from '@/features/auth/taxonomy/api/types/taxonomyTypes';

export default class TaxonomyApi extends Client {
  constructor(baseUrl = import.meta.env.VITE_SERVICE_TAXONOMY) {
    super(baseUrl);
    this.baseUrl = baseUrl;
  }

  getTaxonomies(taxonomyName: string, taxonomyVersion: string) {
    return this.instance.get(`/taxonomy/v2/${taxonomyName}/${taxonomyVersion}/tree`);
  }
}