import type { KeycloakConfig } from 'keycloak-js';

export const multiTenant = import.meta.env.VITE_MULTITENANT as string;
export const authUrl = import.meta.env.VITE_AUTH_URL as string;
export const clientId = import.meta.env.VITE_AUTH_JS_CLIENT_ID as string;
export const realm = import.meta.env.VITE_SINGLETENANT_REALM_NAME as string;

const keycloakClientOptions: KeycloakConfig = {
  realm,
  url: authUrl + '/auth/',
  clientId,
};

export default keycloakClientOptions;
