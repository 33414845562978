import type { RouteParams } from 'vue-router';
import type { RouteLocationRaw } from 'vue-router';

export enum State {
  Loading,
  Error,
  Ready,
}

export interface Crumb {
  label: string;
  name?: string;
  params?: RouteParams;
}

export enum MessageType {
  Error,
  Warning,
  Success,
}

export interface ToastMessage {
  type: MessageType;
  title: string;
  message: string;
  id: number;
  action?: RouteLocationRaw;
}

export type SystemState = {
  messages: ToastMessage[],
  showSidebar: boolean,
  darkMode: boolean,
  hideMainScreenBecauseOfModal: boolean,
};