<template>
  <div :class="$style['main']">
    <div :class="$style['failed']">
      <ErrorTriangleIcon width="250" />
      <div :class="$style['failed__message']">
        <h1>FAILURE MODE: CREDENTIALS</h1>
        <p> We didn’t recognize the credentials used to access this Uptake App. </p>
        <p>If you think you’re seeing this message in error, please contact <span>support@uptake.com</span>. </p>
      </div>
    </div>
  </div>
</template>

<script lang="ts" setup>
</script>

<style lang="scss" module scoped>
.main {
  width: 100%;
  background-color: #fff;
  height: 100vh;
  border-radius: 10px;
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: center;
  .failed {
    display: flex;
    &__message {
      margin:30px;
    }
  }
}
h1 {
  font-size: 28px;
  color: ds-get-color(uptake-primary-text-color);
}
p {
  font-size: 14px;
  color: ds-get-color(uptake-primary-text-color);
  font-family: ds-font(medium);
  span {
    color: ds-get-color(uptake-interactive-text-color);
    cursor: pointer;
    text-decoration: underline;
  }
}
</style>
