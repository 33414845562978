import queryString from 'query-string';

type QueryKeys =
  | 'accessLevelNames'
  | 'activeOnTs'
  | 'appId'
  | 'assetAttributePatternFilters'
  | 'componentId'
  | 'domainId'
  | 'enabledOnly'
  | 'endTs'
  | 'high'
  | 'low'
  | 'medium'
  | 'page'
  | 'partIds'
  | 'productId'
  | 'responseFormat'
  | 'size'
  | 'sort'
  | 'sortCriteria'
  | 'sortDirection'
  | 'startTs'
  | 'userSavedFilterId'
  | 'componentId'
  | 'caseState'
  | 'veryHigh';

type QueryParams = {
  [key in QueryKeys]?: string | number | boolean | string[];
};

export function buildQuery(options: QueryParams): string {
  if (options.sortCriteria && options.sortDirection) {
    options.sort = `${options.sortCriteria},${options.sortDirection}`;
    delete options.sortCriteria;
    delete options.sortDirection;
  }
  const query = queryString.stringify(options);
  return query ? `?${query}` : '';
}
export function buildMultiSortQuery(options: QueryParams): string {
  const query = queryString.stringify(options);
  return query ? `?${query}` : '';
}