import Client from '@/api/client/httpClient';
import type { APIListResponse } from '@/api/client/client';
import { schemas } from '@/api/clients/TenantConfigApi';
import { z } from "zod";

type InsightPreferencesDto = z.infer<typeof schemas.InsightPreferencesDto>;
type TenantConfigInsightPreferenceRequest = z.infer<typeof schemas.TenantConfigInsightPreferenceRequest>;

export default class AdminApi extends Client {
  constructor(baseUrl = import.meta.env.VITE_SERVICE_ADMIN_CONFIG) {
    super(baseUrl);
    this.baseUrl = baseUrl;
  }

  getInsightPreferences() {
    return this.instance.get<APIListResponse<InsightPreferencesDto>>(`/tenant-config/v1/tenants/insights/preferences?responseFormat=array`);
  }

  postInsightPreferences(preferencesData:TenantConfigInsightPreferenceRequest) {
    return this.instance.post<APIListResponse<InsightPreferencesDto>>(`/tenant-config/v1/tenants/insights/preferences`, preferencesData);
  }
}