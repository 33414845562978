import { type RouteRecordRaw } from 'vue-router';
import { defaultGuardProvider, adminGuardProvider } from './helpers';

const admin: Array<RouteRecordRaw> = [
  {
    path: '/admin',
    beforeEnter: [defaultGuardProvider(),adminGuardProvider()],
    children: [
      {
        name: 'admin.userManagement',
        path: 'UserManagement',
        beforeEnter: [defaultGuardProvider(),adminGuardProvider()],
        component: () => import('@/features/admin/userManagement/views/UserManagementView.vue'),
        meta: { 
          title: 'Admin - User Management' 
        }
      },
      {
        name: 'admin.connectorManagement',
        path: 'ManageConnectors',
        beforeEnter: [defaultGuardProvider(),adminGuardProvider()],
        component: () => import('@/features/admin/connectorManagement/views/ManageConnectorsView.vue'),
        meta: { 
          title: 'Admin - Manage Connectors' 
        }
      },
      {
        name: 'admin.manageInsights',
        path: 'ManageInsights',
        beforeEnter: [defaultGuardProvider(),adminGuardProvider()],
        component: () => import('@/features/admin/insightManagement/views/ManageInsightsView.vue'),
        meta: { 
          title: 'Admin - Manage Insights' 
        }
      },
    ]    
  },
  
];

export default admin;
