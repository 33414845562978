import type { Asset } from '@/features/assets/types/asset.ts';

export interface Insight {
  confidence: number;
  insightDescription: string;
  insightType: string;
  severity: string;
  subsystem: string;
  visibleInUi: boolean;
  insightTitle: string;
  insightMetadataKey: string;
  autoCreateCase: boolean;
}

// export interface Insight {
//   insightId: string;
//   insightMetadata: InsightMetadata;
//   modelName: string;
//   modelVersion: string;
//   status: string;
//   startTs: string;
//   endTs: string;
//   attributes: any;
//   attributesDefinitionId: string;
//   inactivityTs: string;
//   actions: InsightAction[];
//   latestAction: InsightAction;
//   firstAction: InsightAction;
//   media: Media;
// }

export enum InsightActionContext {
  accept,
  disregard,
}

export interface CachedInsight {
  id: string;
  title: string;
  resolution?: string;
  notes?: string;
  date?: string;
}

export enum ActionState {
  New = 'NEW',
  Disregarded = 'DISREGARDED',
  Assigned = 'ASSIGNED_TO_CASE',
  Accepted = 'ACCEPTED',
}

export enum ActionResolutionReason {
  ActionTaken = 'ACTION_TAKEN',
  ActionOnHold = 'ACTION_ON_HOLD',
  Other = 'OTHER',
  NoIssueFound = 'NO_ISSUE_FOUND',
  InconclusiveInspection = 'INCONCLUSIVE_INSPECTION',
  Ignore = 'IGNORE',
  Duplicate = 'INSIGHT_IS_A_DUPLICATE',
}

export interface AssetInsightAction {
  assetInsightActionId: string;
  actionState: ActionState;
  actionedBy: string;
  customerActionTakenTs: string;
  resolutionReason: ActionResolutionReason;
  notes: string;
}

export interface AssetInsight {
  assetInsightId: string;
  asset: Asset;
  insight: Insight;
  attributes: any;
  riskScore: string;
  actions: AssetInsightAction[];
  firstAction: AssetInsightAction;
  latestAction: AssetInsightAction;
}

export interface InsightAction {
  actionState: string;
}

export enum insightSeverityEnum {
  NEGLIGIBLE = 'LOW',
  MARGINAL = 'MEDIUM',
  CRITICAL = 'HIGH',
  CATASTROPHIC = 'VERY HIGH',
}

export type InsightTableItem = {
  title: string;
  severity: string;
  startTs: string;
  endTs?: string;
  insightType: string;
  insightStatus: string;
  assetInsightId: string;
  assetId: string;
  stillActive: string;
  customerAssetId?: string;
  customer_asset_name?: string;
  customer_asset_id?: string;
};

export enum insightTableType {
  AIC = 'AIC',
  UAV = 'UAV',
}