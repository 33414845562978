import Client from '@/api/client/httpClient';
import { buildQuery } from '@/utils/queryBuilder';
import { z } from "zod";
import { schemas } from "@/api/clients/NotificationsApi";

type SubscriptionDto = z.infer<typeof schemas.SubscriptionDto>;
type SubscriptionRequest = z.infer<typeof schemas.SubscriptionRequest>;
type SubscriptionUpdateRequest = z.infer<typeof schemas.SubscriptionUpdateRequest>;
type PageSubscriptionDto = z.infer<typeof schemas.PageSubscriptionDto>;

export default class NotificationsApi extends Client {
  constructor(baseUrl = import.meta.env.VITE_SERVICE_NOTIFICATION) {
    super(baseUrl);
    this.baseUrl = baseUrl;
  }

  //getUserSubscription **
  getSubscribersSubscriptions() {
    const query = buildQuery({
      size: 1000,
    });
    return this.instance.get<PageSubscriptionDto>(
      `/notifications-management/subscriber/subscriptions/FLEET${query}`
    );
  }

  //createSubscription **
  createSubscription(subscriptionData: SubscriptionRequest) {
    return this.instance.post<SubscriptionDto>(
      `/notifications-management/subscriber/subscriptions/FLEET`,
      subscriptionData
    );
  }

  //updateSubscription **
  updateSubscriptionSubscription(subscriptionData: SubscriptionUpdateRequest, subscriptionId: string) {
    return this.instance.patch<SubscriptionDto>(`/notifications-management/subscriber/subscriptions/${subscriptionId}`, subscriptionData);
  }

  //deleteSubscription ?
  deleteSubscription(subscriptionId: string) {
    return this.instance.delete<void>(`/notifications-management/subscriber/subscriptions/${subscriptionId}`);
  }
}