export enum UserRole {
    TenantAdmin = 'tenant_admin',
    TenantWrite = 'tenant_write',
    TenantRead = 'tenant_read',
  }

  export enum AccessLevels {
    tenant_admin = 'Administrator',
    tenant_write = 'Read/Write',
    tenant_read = 'Read Only',
  }