import { useLocalStorage } from '@/utils/localStorageUtils';
import { keycloak } from '@/api/auth';
import { cookieUtils } from '@/utils/cookieUtils';
import { FleetCookie } from '@/components/types/shared';

const TermsCurrentCommercialVersion = 1;

const product = useLocalStorage().product;

// hash a string, returning a 32-bit int. Fast, but not cryptographic
const getHashCode = function (data: string) {
  let hash = 0;
  if (data.length > 0) {
    for (let i = 0; i < data.length; i++) {
      hash = (hash << 5) - hash + data.charCodeAt(i);
      hash |= 0;
    }
  }
  return hash;
};

// produce an unencrypted hash of user's unique (non-sensitive) info
const getFingerprint = () =>
  keycloak.profile &&
  getHashCode(
    JSON.stringify({
      realm: cookieUtils().getCookie('realm'),
      user: keycloak.profile.username,
    })
  ).toString();

// combine the fingerprint and the terms' current version
const getTermsConsent = () => {
  const fingerPrint = getFingerprint();
  return fingerPrint && `${fingerPrint}-${TermsCurrentCommercialVersion}`;
};

async function hasConsented() {
  await keycloak.loadUserProfile();
  if (product === 'federal') {
    return cookieUtils().getCookie(FleetCookie.SignedTermsOfUseFederal);
  }
  const consent = getTermsConsent();
  return !!consent && (useLocalStorage().termsConsents || []).includes(consent);
}

function doConsent() {
  if (product === 'federal') {
    return cookieUtils().setCookie(FleetCookie.SignedTermsOfUseFederal, 'true', 1);
  }
  const fingerPrint = getFingerprint();
  if (fingerPrint) {
    // remove old consents, add the new
    useLocalStorage().termsConsents = [
      ...(useLocalStorage().termsConsents || []).filter((consent) => consent.split('-')[0] !== fingerPrint),
      getTermsConsent() as string,
    ];
  }
}

export const useTerms = () => ({
  hasConsented,
  doConsent,
});
