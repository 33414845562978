/**
 *
 * @param paramName
 * @returns
 * This function is used to get the value of a query param from the URL.
 */
function getQueryParam(paramName: string) {
  const urlParams = new URLSearchParams(window.location.search);
  return urlParams.get(paramName);
}

/**
 * This function is used to sanitize the realm in the URL and the realm in the local storage.
 * if a user passes a URL in the realm that does not match local storage the param realm will override localstorage
 */
export function realmSanitationUtils() {
  const storedRealm = localStorage.getItem('realm');
  const urlRealm = getQueryParam('realm'); // Assume you have a function to get query params
  const hasSearch = Boolean(window.location.search);

  if (urlRealm && storedRealm) {
    if (urlRealm !== storedRealm) {
      localStorage.removeItem('realm'); // Clear only 'realm' in localStorage
      window.location.href = window.location.origin; // Redirect to the base URL
      return;
    }
    return;
  }

  if (storedRealm && !urlRealm) {
    const appendRealm = hasSearch ? '&realm=' : '?realm=';
    window.location.href += appendRealm + storedRealm;
    return;
  }
  if (urlRealm && !storedRealm) {
    localStorage.setItem('realm', urlRealm);
    // No need to change the URL since it already has a realm
    return;
  }
}
