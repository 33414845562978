import Client from '@/api/client/httpClient';

export default class AuthApi extends Client {
  constructor(baseUrl = import.meta.env.VITE_TENANT_CONFIG_URL) {
    super(baseUrl);
    this.baseUrl = baseUrl;
  }

  public async logout(): Promise<void> {
    this.keycloak.logout();
  }
}