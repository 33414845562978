import {
  createI18n,
  type IntlDateTimeFormats,
  type IntlNumberFormats,
  type LocaleMessageDictionary,
  type VueMessageType,
} from 'vue-i18n';

const values = {
  locale: 'en-US', 
  fallbackLocale: 'en-US',
  messages: {} as Record<string, LocaleMessageDictionary<VueMessageType>>,
  datetimeFormats: {} as Record<string, IntlDateTimeFormats>,
  numberFormats: {} as Record<string, IntlNumberFormats>,
  legacy: false,
  globalInjection: true
} as any;

const files = import.meta.glob('./lang/*.js', { eager: true })

for (const file in files) {
  const locale = files[file].default;
  // @ts-ignore
  values.messages[locale.key] = {
    namespaced: true,
    ...locale.strings,
  };
  values.datetimeFormats[locale.key] = locale.datetimeFormats;
  values.numberFormats[locale.key] = locale.numberFormats;
}

const componentStrings = {
  messages: {} as Record<string, LocaleMessageDictionary<VueMessageType>>,
  datetimeFormats: {} as Record<string, IntlDateTimeFormats>,
};

Object.keys(componentStrings.messages).forEach((locale) => {
  values.messages[locale] = Object.assign({}, values.messages[locale], componentStrings.messages[locale]);
});

export default createI18n(values);
