<template>
  <header
    :id="$style.header"
    data-cy="Header_LogoLink"
    aria-label="TopHeader"
  >
    <router-link
      to="/RiskExplorer"
      aria-label="Risk Explorer"
    >
      <img
        v-if="product === 'federal'"
        alt="Uptake logo"
        :src="uptakeLogoFederal"
        :class="$style.logo"
        data-cy="Header_LogoImage"
      >
      <img
        v-else
        alt="Uptake logo"
        :src="uptakeLogo"
        :class="$style.logo"
        data-cy="Header_LogoImage"
      >
    </router-link>
    <div :class="$style.iconSet">
      <AdminDropdown v-if="role === adminRole" />
      <router-link
        v-if="product === 'commercial'"
        to="/Notifications"
      >
        <BellIcon
          :class="$style.icon"
          data-cy="Notification_management_icon"
        />
      </router-link>

      <UserProfileDropdown
        data-cy="HeaderPage_Profile"
      />
    </div>
  </header>
</template>

<script setup lang="ts">
import { computed } from 'vue';
import { useI18n } from 'vue-i18n';
import { useRouter } from 'vue-router';
import UserProfileDropdown from '@/components/global/UserProfileDropdown.vue';
import { UserRole } from '@/features/admin/userManagement/types/userManagementTypes';
import AdminDropdown from '@/features/admin/adminSettings/components/AdminDropdown.vue';
import uptakeLogo from '@/assets/logo/uptake-logo.svg';
import uptakeLogoFederal from '@/assets/logo/logo.svg';
import useTenantStore from '@/features/auth/tenant/store/tenantStore';
import useAuthStore from '@/features/auth/store/authStore';

const { t } = useI18n();
const router = useRouter();

const tenantStore = useTenantStore();
const authStore = useAuthStore();

const product = computed(() => tenantStore.config.product);
const role = computed(() => authStore.role);

const adminRole = UserRole.TenantAdmin;

const openLinkUrl = (url: string) => {
  window.open(url);
};

</script>

<style module lang="scss">
#header {
  align-items: center;
  background-color: ds-get-color(uptake-modules-and-modals);
  border-bottom: solid 1px ds-get-color(uptake-medium-accent);
  display: flex;
  justify-content: space-between;
  padding: 0 25px;
  position: sticky;
  top: 0;
  left: 0;
  right: 0;
  z-index: 1002;

  .logo {
    display: block;
    padding-top: 7px;
    padding-bottom: 6px;
    width: 130px;
    height: 24px;
  }
}
.iconSet {
  display: flex;
  padding-left: 25px;
}
.icon {
  // fill: ds-get-color(uptake-interactive-text-color);
  padding: 0px 15px;
  width: 25px;
  height: 25px;
}
</style>
