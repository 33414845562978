import Client from '@/api/client/httpClient';
import type { APIListResponse, APIResponse } from '@/api/client/client';
import { schemas } from '@/api/clients/ManagementConfigApi';
import { z } from 'zod';

type TspDto = z.infer<typeof schemas.TspDto>;
type createTsp_Body = z.infer<typeof schemas.createTsp_Body>;

export default class ConnectorsApi extends Client {
    constructor(baseUrl = import.meta.env.VITE_SERVICE_ADMIN_CONFIG) {
      super(baseUrl);
      this.baseUrl = baseUrl;
    }
    
    async getConnectorsList() {
      return this.instance.get<APIListResponse<TspDto>>('/management-config/v1/tenants/tsps');
    }
    async getConnectorsListBySearch(searchData:string, query:string){
      // TODO: should be repalced by actual API
      return 'GeoTab'.toLowerCase().indexOf(searchData.toLowerCase()) > -1 ? data : noData;
    }
    async getConnectionById(connectorId:string){
      // TODO: should be repalced by actual API
      return data;
    }
    async deleteConnector(connectorId:string){
      // TODO: Integrate API
      return this.instance.delete<APIResponse<string>>(`/management-config/v1/tenants/tsps/${connectorId}`);
    }
    async updateConnector(configurationId:string, connectorObj:createTsp_Body){
      return this.instance.patch<APIResponse<TspDto>>(`/management-config/v1/tenants/tsps/${configurationId}`, connectorObj);
    }
    async createConnector(connectorObj:createTsp_Body){
      return this.instance.post<APIResponse<TspDto>>(`/management-config/v1/tenants/tsps`, connectorObj);
    }
}

export const noData:any = {
  'data':{
    'content' : [],
    'totalElements' : 0,
    'totalPages' : 0,
    'pageable' : {
        'sort': {
          'empty': false,
          'sorted': true,
          'unsorted': false
        },
        'offset': 0,
        'pageNumber': 0,
        'pageSize': 100,
        'paged': true,
        'unpaged': false
      }
    }
};

export const data = {'data':{
  'content' : [{ 
    connectorType: 'TSP', 
    connector: 'GeoTab', 
    connectorName: 'geotab_name', 
    createdBy: 'Jon Doe', 
    dateCreated: '2023-06-23T21:12:20.036742Z', 
    dateEdited: '2023-06-23T21:12:20.036742Z',
    URL:'http://new.geotag.connector.com',
    domain:'new.geotag.domain.com', 
    username: 'new.username.geotab',
    password: 'uptake',
    connectorid: 'b2e55666-87fe-477e-91cd-d6ce4c9f4199'
  }],
  'totalElements' : 1,
  'totalPages' : 1,
  'pageable' : {
      'sort': {
        'empty': false,
        'sorted': true,
        'unsorted': false
      },
      'offset': 0,
      'pageNumber': 0,
      'pageSize': 100,
      'paged': true,
      'unpaged': false
    }
  }
};