import { makeApi, Zodios } from '@zodios/core';
import { z } from 'zod';

type SubscriptionDto = Partial<{
  subscriptionId: string;
  user: UserDto;
  /**
   * @enum FLEET
   */
  productId: 'FLEET';
  tenantId: string;
  /**
   * @enum ASSET_INSIGHT, INSIGHT
   */
  eventType: 'ASSET_INSIGHT' | 'INSIGHT';
  topic: string;
  realTime: boolean;
  subscribedTs: string;
  dailySummary: boolean;
}>;
type UserDto = Partial<{
  userId: string;
  preferredUsername: string;
  givenName: string;
  familyName: string;
  email: string;
}>;
type PageSubscriptionDto = Partial<{
  totalPages: number;
  totalElements: number;
  first: boolean;
  last: boolean;
  size: number;
  content: Array<SubscriptionDto>;
  number: number;
  sort: Array<SortObject>;
  numberOfElements: number;
  pageable: PageableObject;
  empty: boolean;
}>;
type SortObject = Partial<{
  direction: string;
  nullHandling: string;
  ascending: boolean;
  property: string;
  ignoreCase: boolean;
}>;
type PageableObject = Partial<{
  offset: number;
  sort: Array<SortObject>;
  unpaged: boolean;
  paged: boolean;
  pageNumber: number;
  pageSize: number;
}>;

const UserDto: z.ZodType<UserDto> = z
  .object({
    userId: z.string().uuid(),
    preferredUsername: z.string(),
    givenName: z.string(),
    familyName: z.string(),
    email: z.string(),
  })
  .partial()
  .passthrough();
const SubscriptionDto: z.ZodType<SubscriptionDto> = z
  .object({
    subscriptionId: z.string().uuid(),
    user: UserDto,
    productId: z.literal('FLEET'),
    tenantId: z.string(),
    eventType: z.enum(['ASSET_INSIGHT', 'INSIGHT']),
    topic: z.string(),
    realTime: z.boolean(),
    subscribedTs: z.string().datetime({ offset: true }),
    dailySummary: z.boolean(),
  })
  .partial()
  .passthrough();
const SubscriptionRequest = z
  .object({
    eventType: z.enum(['ASSET_INSIGHT', 'INSIGHT']),
    topic: z.string(),
    realTime: z.boolean().optional(),
    dailySummary: z.boolean().optional(),
  })
  .passthrough();
const SubscriptionUpdateRequest = z
  .object({ realTime: z.boolean(), dailySummary: z.boolean() })
  .partial()
  .passthrough();
const SortObject: z.ZodType<SortObject> = z
  .object({
    direction: z.string(),
    nullHandling: z.string(),
    ascending: z.boolean(),
    property: z.string(),
    ignoreCase: z.boolean(),
  })
  .partial()
  .passthrough();
const PageableObject: z.ZodType<PageableObject> = z
  .object({
    offset: z.number().int(),
    sort: z.array(SortObject),
    unpaged: z.boolean(),
    paged: z.boolean(),
    pageNumber: z.number().int(),
    pageSize: z.number().int(),
  })
  .partial()
  .passthrough();
const PageSubscriptionDto: z.ZodType<PageSubscriptionDto> = z
  .object({
    totalPages: z.number().int(),
    totalElements: z.number().int(),
    first: z.boolean(),
    last: z.boolean(),
    size: z.number().int(),
    content: z.array(SubscriptionDto),
    number: z.number().int(),
    sort: z.array(SortObject),
    numberOfElements: z.number().int(),
    pageable: PageableObject,
    empty: z.boolean(),
  })
  .partial()
  .passthrough();

export const schemas = {
  UserDto,
  SubscriptionDto,
  SubscriptionRequest,
  SubscriptionUpdateRequest,
  SortObject,
  PageableObject,
  PageSubscriptionDto,
};

const subscription_controllerEndpoints = makeApi([
  {
    method: 'get',
    path: '/subscriber/subscriptions/:productId',
    alias: 'getSubscribersSubscriptions',
    requestFormat: 'json',
    parameters: [
      {
        name: 'productId',
        type: 'Path',
        schema: z.literal('FLEET').describe('The product'),
      },
    ],
    response: SubscriptionDto,
    errors: [
      {
        status: 400,
        description: `JWT did not contain a tenantId`,
        schema: z.void(),
      },
      {
        status: 404,
        description: `A user&#x27;s subscriptions was not found for the supplied product`,
        schema: z.void(),
      },
    ],
  },
  {
    method: 'post',
    path: '/subscriber/subscriptions/:productId',
    alias: 'createSubscription',
    requestFormat: 'json',
    parameters: [
      {
        name: 'body',
        type: 'Body',
        schema: SubscriptionRequest,
      },
      {
        name: 'productId',
        type: 'Path',
        schema: z.literal('FLEET').describe('The product id'),
      },
    ],
    response: SubscriptionRequest,
    errors: [
      {
        status: 400,
        description: `JWT did not contain a tenantId`,
        schema: z.void(),
      },
      {
        status: 404,
        description: `A user&#x27;s subscriptions was not found for the supplied product`,
        schema: z.void(),
      },
    ],
  },
  {
    method: 'delete',
    path: '/subscriber/subscriptions/:subscriptionId',
    alias: 'deleteSubscriptionSubscription',
    requestFormat: 'json',
    parameters: [
      {
        name: 'subscriptionId',
        type: 'Path',
        schema: z.string().uuid().describe('The subscriptionId'),
      },
    ],
    response: SubscriptionDto,
    errors: [
      {
        status: 400,
        description: `JWT did not contain a tenantId`,
        schema: z.void(),
      },
      {
        status: 404,
        description: `A user&#x27;s subscriptions was not found for the supplied subscriptionId`,
        schema: z.void(),
      },
    ],
  },
  {
    method: 'patch',
    path: '/subscriber/subscriptions/:subscriptionId',
    alias: 'updateSubscriptionSubscription',
    requestFormat: 'json',
    parameters: [
      {
        name: 'body',
        type: 'Body',
        schema: SubscriptionUpdateRequest,
      },
      {
        name: 'subscriptionId',
        type: 'Path',
        schema: z.string().uuid().describe('The subscriptionId'),
      },
    ],
    response: SubscriptionRequest,
    errors: [
      {
        status: 400,
        description: `JWT did not contain a tenantId`,
        schema: z.void(),
      },
      {
        status: 404,
        description: `A user&#x27;s subscriptions was not found for the supplied subscriptionId`,
        schema: z.void(),
      },
    ],
  },
]);

export const subscription_controllerApi = new Zodios(subscription_controllerEndpoints);

export function createApiClient(baseUrl: string, options?: ZodiosOptions) {
  return new Zodios(baseUrl, endpoints, options);
}
