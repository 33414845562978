import { defineStore } from 'pinia';
import { type SystemState } from '@/features/auth/types/System';

export default defineStore('system', {
  state: (): SystemState => ({
    messages: [],
    showSidebar: true,
    darkMode: false,
    hideMainScreenBecauseOfModal: false,
  }),

  getters: {
    getSystem: (state: SystemState) => state.messages,
  },

  actions: {
    addMessage(error:any) {
      this.messages.push(error);
      setTimeout(() => {
        this.removeMessage();
      }, 4000);
    },
    removeMessage() {
      this.messages = [];
    },
  },
});
