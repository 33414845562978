import { UserRole } from '@/features/admin/userManagement/types/userManagementTypes';
import { defineStore } from 'pinia';

export type IAuthenticationState = {
  isAuthenticated: boolean;
  signedTerms: boolean;
  token: string;
  tenant?: string;
  impersonator?: string;
  impersonatorId?: string;
  userId?: string;
  userName?: string;
  email?: string;
  role?: string;
}

export default defineStore('auth', {
  state: (): IAuthenticationState => ({
    isAuthenticated: false,
    signedTerms: false,
    token: '',
    tenant: '',
    impersonator: '',
    impersonatorId: '',
    userId: '',
    userName: '',
    email: '',
    role: '',
  }),
  getters: {
    isAdmin: (state) => state.role === UserRole.TenantAdmin,
    isReadOnly: (state) => state.role ===  UserRole.TenantRead,
    isEmailAvailable: (state) => state.email !== undefined && state.email !== "",
    isImpersonation: (state) => state.impersonator !== undefined && state.impersonator !== "",
    getImpersonator: (state) => state.impersonator !== undefined ? state.impersonator : 'not available',
    getEmail: (state) => state.email !== undefined && state.email !== "" ? state.email : 'not available',
    getUserName: (state) => state.userName !== undefined ? state.userName : 'not available',
    getUserId: (state) => state.userId !== undefined ? state.userId : 'not available',
    getTenantId: (state) => state.tenant !== undefined ? state.tenant : 'not available',
    getRole: (state) => state.role !== undefined ? state.role : 'not available'
  },
});
