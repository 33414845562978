import Client from '@/api/client/httpClient';
import { schemas } from '@/api/clients/AssetUiApi';
import { z } from 'zod';
import type { APIResponse } from '@/api/client/client';

type NewCaseResponse = z.infer<typeof schemas.CaseDto>; 
type InsightResolution = z.infer<typeof schemas.AssetInsightActionUpdateRequest>;
type CaseActionDto = z.infer<typeof schemas.CaseActionDto>; 
type AssetInsightDto = z.infer<typeof schemas.AssetInsightDto>;

export default class CasesApi extends Client {
  constructor(baseUrl = import.meta.env.VITE_SERVICE_ASSET_UI) {
    super(baseUrl);
    this.baseUrl = baseUrl;
  }

  //findCaseById_1 
  getCasesByCaseId(caseId: string) {
    return this.instance.get<APIResponse<CaseActionDto>>(`/asset-ui/cases/${caseId}`);
  }

  //findCaseById_1
  async getCaseInsights(caseId: string) {
    const response = (await this.instance.get<any>(`/asset-ui/cases/${caseId}`)).data;

    return {
      ...response,
      closed: response.actions?.some((action: CaseActionDto) => action.caseState === 'CLOSED'),
      insights: response.assetInsights?.map((insight: AssetInsightDto) => ({
        id: insight.assetInsightId,
        title: insight.insight?.insightMetadata?.title,
      })),
    };
  }

  //createCase **
  createCase(title: string, description: string, insightId: string) {
    return this.instance.post<APIResponse<NewCaseResponse>>('/asset-ui/v1/cases', {
      title,
      description,
      assetInsightIds: [insightId],
    });
  }

  createOneCase(title: string, description: string, insightIds: Array<string>) {
    return this.instance.post<APIResponse<NewCaseResponse>>('/asset-ui/v1/cases', {
      title,
      description,
      assetInsightIds: insightIds,
    });
  }

  //addAssetInsightToCase
  addToCase(caseId: string, assetInsightId: string) {
    return this.instance.post<APIResponse<NewCaseResponse>>(`/asset-ui/v1/cases/${caseId}/assetinsight/${assetInsightId}`);
  }

  addXInsightToCase(caseId: string, assetInsightIds: Array<string>) {
    return this.instance.post<APIResponse<NewCaseResponse>>(`/asset-ui/v1/cases/${caseId}/assetinsights`, assetInsightIds);
  }

  //addNoteToCase **
  addCaseNote(caseId: string, noteContent: string) {
    return this.instance.post<APIResponse<CaseActionDto>>(`/asset-ui/v1/cases/${caseId}/notes`, {
      note: noteContent,
    });
  }

  //closeCase ** 
  async closeCase(caseId: string, insights?: InsightResolution[]) {
    const assetInsightsToResolve = insights?.reduce((acc, resolution) => {
      acc[resolution.assetInsightId] = resolution;
      return acc;
    }, {} as Record<string, InsightResolution>);

    return this.instance.patch<APIResponse<CaseActionDto>>(`/asset-ui/v1/cases/${caseId}/close`, {
      assetInsightsToResolve,
      caseResolution: 'Case Resolved',
    });
  }
}