import Client from '@/api/client/httpClient';
import { buildQuery } from '@/utils/queryBuilder';
import type { APIListResponse, APIResponse } from '@/api/client/client';
import { schemas } from '@/api/clients/TenantConfigApi.ts';
import { z } from "zod";

type ConfigurationDto = z.infer<typeof schemas.ConfigurationDto>;

export default class TenantApi extends Client {
  constructor(baseUrl = import.meta.env.VITE_SERVICE_TENANT_CONFIG) {
    super(baseUrl);
    this.baseUrl = baseUrl;
  }

  //getMergedConfigurations
  async getTenantConfig() {
    const query = buildQuery({
      productId: 'fleet',
      domainId: 'frontend',
      appId: 'fleet-frontend',
    });
      return (await this.instance.get<APIListResponse<ConfigurationDto>>(`/tenant-config/v1/configurations/merged${query}`)).data;
    }

  //getMergedConfigurations
  async getTenantFeatureSubscriptions() {
    const query = buildQuery({
      componentId: 'feature-subscriptions',
    });
    const response:any = (await this.instance.get<APIListResponse<ConfigurationDto>>(`/tenant-config/v1/configurations/merged${query}`)).data;
    return response[0].config;
  }

  //getTenantConfigByComponentId **
  getConfigurationDto(componentId?: string) {
    const query = buildQuery({
      domainId: 'frontend',
      productId: 'fleet',
      appId: 'fleet-frontend',
      componentId,
    });
    return this.instance.get<APIListResponse<ConfigurationDto>>(`/tenant-config/v1/configuration/merged${query}`);
  }

  //createLegendConfig **
  createConfiguration(critical: string, marginal: string, negligible: string, catastrophic: string) {
    const config = this.generateLegendConfig(critical, marginal, negligible, catastrophic);
    return this.instance.post<APIResponse<ConfigurationDto>>(`/tenant-config/v1/configurations`, config);
  }

  //updateLegendConfig **
  updateConfiguration(
    configurationId: string,
    critical: string,
    marginal: string,
    negligible: string,
    catastrophic: string
  ) {
    const config = this.generateLegendConfig(critical, marginal, negligible, catastrophic);
    return this.instance.put<APIResponse<ConfigurationDto>>(`/tenant-config/v1/configurations/${configurationId}`, config);
  }
  generateLegendConfig(critical: string, marginal: string, negligible: string, catastrophic: string) {
    return {
      productId: 'fleet',
      domainId: 'frontend',
      appId: 'fleet-frontend',
      componentId: 'insight-preferences',
      config: {
        insightPreferences: {
          guidance: {
            CRITICAL: {
              description: critical,
            },
            MARGINAL: {
              description: marginal,
            },
            NEGLIGIBLE: {
              description: negligible,
            },
            CATASTROPHIC: {
              description: catastrophic,
            },
          },
        },
      },
    };
  }
}
