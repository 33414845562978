import Client from '@/api/client/httpClient';
import type { APIListResponse, APIResponse } from '@/api/client/client';
import { schemas } from '@/api/clients/AssetUiApi';
import { z } from 'zod';

type InsightDto = z.infer<typeof schemas.InsightDto>;
type InsightMetadataDto = z.infer<typeof schemas.InsightMetadataDto>;

export default class InsightApi extends Client {
  constructor(baseUrl = import.meta.env.VITE_SERVICE_ASSET_UI) {
    super(baseUrl);
    this.baseUrl = baseUrl;
  }

  //getInsights **
  queryAssetInsightsPaged(query: string, data: any) {
    return this.instance.post<APIListResponse<InsightDto>>(`/asset-ui/v4/assets/insights${query}`, data);
  }

  //getInsightCount **
  queryAssetInsightsCount(query: string, data: any) {
    return this.instance.post<APIResponse<number>>(`/asset-ui/v1/assets/insights/count${query}`, data);
  }

  //getInsightsTitles **
  getInsightTitlesByTenant() {
    return this.instance.get<APIListResponse<InsightMetadataDto>>(`/asset-ui/v1/insights/metadata/titles`);
  }
}