import { makeApi, Zodios } from '@zodios/core';
import { z } from 'zod';

type UpdateTenantRequest = {
  displayName?: string | undefined;
  salesforceId?: /**
   * The salesforce id
   *
   * @example "0015500000WOHciAAH"
   * @pattern ^[a-zA-Z0-9]{18}|[a-zA-Z0-9]{15}$
   */
  string | undefined;
  adminUserActionLifespan?:
    | Partial<{
        seconds: number;
        zero: boolean;
        nano: number;
        negative: boolean;
        units: Array<
          Partial<{
            durationEstimated: boolean;
            timeBased: boolean;
            dateBased: boolean;
          }>
        >;
      }>
    | undefined;
  userActionLifespan?:
    | Partial<{
        seconds: number;
        zero: boolean;
        nano: number;
        negative: boolean;
        units: Array<
          Partial<{
            durationEstimated: boolean;
            timeBased: boolean;
            dateBased: boolean;
          }>
        >;
      }>
    | undefined;
  defaultUserPermissions?: TenantDefaultUserPermissionsDto | undefined;
  /**
   * The tenant's parent id
   *
   * @example "geotab"
   * @pattern ^(?=[a-z0-9-]{3,36}$)[a-z0-9]+(-[a-z0-9]+){0,17}$
   */
  parentTenantId: string;
  childTenantLimit?: /**
   * The number of child tenants a parent tenant is allowed to have.
   *
   * @minimum 0
   */
  number | undefined;
  partner?: /**
   * If the tenant is an affinity partner
   */
  boolean | undefined;
};
type TenantDefaultUserPermissionsDto = Partial<{
  /**
   * @enum ALL, NONE
   */
  defaultGlobalPermissions: 'ALL' | 'NONE';
}>;
type TenantDto = {
  /**
   * The tenantId
   *
   * @example "lncm-trucking"
   * @pattern ^(?=[a-z0-9-]{3,36}$)[a-z0-9]+(-[a-z0-9]+){0,17}$
   */
  tenantId: string;
  displayName?: string | undefined;
  enabled?: boolean | undefined;
  salesforceId?: /**
   * The salesforce id
   *
   * @example "0015500000WOHciAAH"
   * @pattern ^[a-zA-Z0-9]{18}|[a-zA-Z0-9]{15}$
   */
  string | undefined;
  defaultUserPermissions?: TenantDefaultUserPermissionsDto | undefined;
  deletedTs?: string | undefined;
  parentTenantId?: string | undefined;
  childTenantLimit?: number | undefined;
  partner?: boolean | undefined;
};
type CreateTenantRequest = {
  tenantId?: string | undefined;
  displayName?: string | undefined;
  salesforceId?: /**
   * The salesforce id
   *
   * @example "0015500000WOHciAAH"
   * @pattern ^[a-zA-Z0-9]{18}|[a-zA-Z0-9]{15}$
   */
  string | undefined;
  adminUserActionLifespan?:
    | Partial<{
        seconds: number;
        zero: boolean;
        nano: number;
        negative: boolean;
        units: Array<
          Partial<{
            durationEstimated: boolean;
            timeBased: boolean;
            dateBased: boolean;
          }>
        >;
      }>
    | undefined;
  userActionLifespan?:
    | Partial<{
        seconds: number;
        zero: boolean;
        nano: number;
        negative: boolean;
        units: Array<
          Partial<{
            durationEstimated: boolean;
            timeBased: boolean;
            dateBased: boolean;
          }>
        >;
      }>
    | undefined;
  defaultUserPermissions: TenantDefaultUserPermissionsDto;
  /**
   * The tenant's parent id
   *
   * @example "geotab"
   * @pattern ^(?=[a-z0-9-]{3,36}$)[a-z0-9]+(-[a-z0-9]+){0,17}$
   */
  parentTenantId: string;
  childTenantLimit?: /**
   * The number of child tenants a parent tenant is allowed to have.
   *
   * @minimum 0
   */
  number | undefined;
  partner?: /**
   * If the tenant is an affinity partner
   */
  boolean | undefined;
};
type AssetGroupPermissionsDto = PermissionDto &
  Partial<{
    assetGroup: {};
  }>;
type PermissionDto = Partial<{
  /**
   * @enum TAXONOMY, ASSET_GROUP
   */
  type: 'TAXONOMY' | 'ASSET_GROUP';
  taxonomy: TaxonomyDto;
  permittedActions: {};
  taxonomyPath: Array<TaxonomyNodeDto>;
}>;
type TaxonomyDto = {
  tenantId: string;
  taxonomyName: string;
  taxonomyVersion: string;
};
type TaxonomyNodeDto = {
  level: string;
  item: string;
};
type CreateUserRequest = {
  /**
   * @pattern ^[a-z0-9_\-\.@]{3,63}$
   */
  username: string;
  email?: string | undefined;
  userPermissions?: Array<AssetGroupPermissionsDto | TaxonomyPermissionDto> | undefined;
  assignedRoles?: Array<RoleRepresentation> | undefined;
  assignedAccessLevels?: Array<RoleRepresentation> | undefined;
  firstname?: /**
   * @pattern ^[^<>&"\v$%!#?§;*~/\\|^=\[\]{}()\p{Cntrl}]+$
   */
  string | undefined;
  lastname?: /**
   * @pattern ^[^<>&"\v$%!#?§;*~/\\|^=\[\]{}()\p{Cntrl}]+$
   */
  string | undefined;
};
type TaxonomyPermissionDto = PermissionDto;
type RoleRepresentation = Partial<{
  id: string;
  name: string;
  description: string;
  /**
   * @deprecated
   */
  scopeParamRequired: boolean;
  composite: boolean;
  composites: Composites;
  clientRole: boolean;
  containerId: string;
  attributes: {};
}>;
type Composites = Partial<{
  realm: Array<string>;
  client: {};
  /**
   * @deprecated
   */
  application: {};
}>;
type UserDto = Partial<{
  username: string;
  email: string;
  enabled: boolean;
  userPermissions: Array<PermissionDto>;
  assignedRoles: Array<RoleRepresentation>;
  assignedAccessLevels: Array<RoleRepresentation>;
  userid: string;
  firstname: string;
  lastname: string;
}>;
type PageUserDto = Partial<{
  totalPages: number;
  totalElements: number;
  first: boolean;
  last: boolean;
  size: number;
  content: Array<UserDto>;
  number: number;
  sort: Array<SortObject>;
  pageable: PageableObject;
  numberOfElements: number;
  empty: boolean;
}>;
type SortObject = Partial<{
  direction: string;
  nullHandling: string;
  ascending: boolean;
  property: string;
  ignoreCase: boolean;
}>;
type PageableObject = Partial<{
  offset: number;
  sort: Array<SortObject>;
  paged: boolean;
  pageNumber: number;
  pageSize: number;
  unpaged: boolean;
}>;
type CalAmpRequest = TspRequestTspSecret &
  Partial<{
    /**
     * @pattern ^(?=[a-z0-9-]{3,16}$)[a-z0-9]+(-[a-z0-9]+){0,8}$
     */
    connectorName: string;
    tspAttributes: CalampSecret;
  }>;
type TspRequestTspSecret = {
  enabled?: boolean | undefined;
  connectorName?: string | undefined;
  /**
   * @enum GEOTAB, CALAMP, SAMSARA, PLATFORM_SCIENCE
   */
  tspType: 'GEOTAB' | 'CALAMP' | 'SAMSARA' | 'PLATFORM_SCIENCE';
  tspAttributes: TspSecret;
  dataLakeAutomation?: boolean | undefined;
};
type TspSecret = {};
type CalampSecret = {
  app_id: string;
  username: string;
  password: string;
};
type GeoTabRequest = TspRequestTspSecret &
  Partial<{
    /**
     * @pattern ^(?=[a-z0-9-]{3,16}$)[a-z0-9]+(-[a-z0-9]+){0,8}$
     */
    connectorName: string;
    tspAttributes: GeotabSecret;
  }>;
type GeotabSecret = {
  username: string;
  password: string;
  database: string;
  server?: string | undefined;
};
type PlatSciRequest = TspRequestTspSecret &
  Partial<{
    /**
     * @pattern ^(?=[a-z0-9-]{3,16}$)[a-z0-9]+(-[a-z0-9]+){0,8}$
     */
    connectorName: string;
    tspAttributes: PlatSciSecret;
    vhost: string;
  }>;
type PlatSciSecret = {
  asset_url: string;
  ASSET_BEARER_TOKEN: string;
};
type SamsaraRequest = TspRequestTspSecret &
  Partial<{
    /**
     * @pattern ^(?=[a-z0-9-]{3,16}$)[a-z0-9]+(-[a-z0-9]+){0,8}$
     */
    connectorName: string;
    tspAttributes: SamsaraSecret;
  }>;
type SamsaraSecret = {
  bearer_token: string;
};
type CreateIdentityProviderRequest = {
  name: string;
  clientId: string;
  clientSecret: string;
  authorizationUrl?: string | undefined;
  tokenUrl?: string | undefined;
  userInfoUrl?: string | undefined;
  issuerUrl?: string | undefined;
  jwksUrl?: string | undefined;
  firstLoginFlow: string;
  mappers?: Array<IdentityProviderMapper> | undefined;
};
type IdentityProviderMapper = {
  name: string;
  claim: string;
  userAttribute: string;
};
type ApplicationRepresentation = Partial<{
  id: string;
  clientId: string;
  name: string;
  description: string;
  rootUrl: string;
  adminUrl: string;
  baseUrl: string;
  surrogateAuthRequired: boolean;
  enabled: boolean;
  alwaysDisplayInConsole: boolean;
  clientAuthenticatorType: string;
  secret: string;
  registrationAccessToken: string;
  /**
   * @deprecated
   */
  defaultRoles: Array</**
   * @deprecated
   */
  string>;
  redirectUris: Array<string>;
  webOrigins: Array<string>;
  notBefore: number;
  bearerOnly: boolean;
  consentRequired: boolean;
  standardFlowEnabled: boolean;
  implicitFlowEnabled: boolean;
  directAccessGrantsEnabled: boolean;
  serviceAccountsEnabled: boolean;
  authorizationServicesEnabled: boolean;
  /**
   * @deprecated
   */
  directGrantsOnly: boolean;
  publicClient: boolean;
  frontchannelLogout: boolean;
  protocol: string;
  attributes: {};
  authenticationFlowBindingOverrides: {};
  fullScopeAllowed: boolean;
  nodeReRegistrationTimeout: number;
  registeredNodes: {};
  protocolMappers: Array<ProtocolMapperRepresentation>;
  /**
   * @deprecated
   */
  clientTemplate: string;
  /**
   * @deprecated
   */
  useTemplateConfig: boolean;
  /**
   * @deprecated
   */
  useTemplateScope: boolean;
  /**
   * @deprecated
   */
  useTemplateMappers: boolean;
  defaultClientScopes: Array<string>;
  optionalClientScopes: Array<string>;
  authorizationSettings: ResourceServerRepresentation;
  access: {};
  origin: string;
  claims: ClaimRepresentation;
}>;
type ProtocolMapperRepresentation = Partial<{
  id: string;
  name: string;
  protocol: string;
  protocolMapper: string;
  /**
   * @deprecated
   */
  consentRequired: boolean;
  /**
   * @deprecated
   */
  consentText: string;
  config: {};
}>;
type ResourceServerRepresentation = Partial<{
  id: string;
  clientId: string;
  name: string;
  allowRemoteResourceManagement: boolean;
  /**
   * @enum ENFORCING, PERMISSIVE, DISABLED
   */
  policyEnforcementMode: 'ENFORCING' | 'PERMISSIVE' | 'DISABLED';
  resources: Array<ResourceRepresentation>;
  policies: Array<PolicyRepresentation>;
  scopes: Array<ScopeRepresentation>;
  /**
   * @enum AFFIRMATIVE, UNANIMOUS, CONSENSUS
   */
  decisionStrategy: 'AFFIRMATIVE' | 'UNANIMOUS' | 'CONSENSUS';
}>;
type ResourceOwnerRepresentation = Partial<{
  id: string;
  name: string;
}>;
type ClaimRepresentation = Partial<{
  name: boolean;
  username: boolean;
  profile: boolean;
  picture: boolean;
  website: boolean;
  email: boolean;
  gender: boolean;
  locale: boolean;
  address: boolean;
  phone: boolean;
}>;
type AuthenticationFlowRepresentation = Partial<{
  id: string;
  alias: string;
  description: string;
  providerId: string;
  topLevel: boolean;
  builtIn: boolean;
  authenticationExecutions: Array<AuthenticationExecutionExportRepresentation>;
}>;
type AuthenticationExecutionExportRepresentation = Partial<{
  authenticatorConfig: string;
  authenticator: string;
  authenticatorFlow: boolean;
  requirement: string;
  priority: number;
  /**
   * @deprecated
   */
  autheticatorFlow: boolean;
  flowAlias: string;
  userSetupAllowed: boolean;
}>;
type ClientRepresentation = Partial<{
  id: string;
  clientId: string;
  name: string;
  description: string;
  rootUrl: string;
  adminUrl: string;
  baseUrl: string;
  surrogateAuthRequired: boolean;
  enabled: boolean;
  alwaysDisplayInConsole: boolean;
  clientAuthenticatorType: string;
  secret: string;
  registrationAccessToken: string;
  /**
   * @deprecated
   */
  defaultRoles: Array</**
   * @deprecated
   */
  string>;
  redirectUris: Array<string>;
  webOrigins: Array<string>;
  notBefore: number;
  bearerOnly: boolean;
  consentRequired: boolean;
  standardFlowEnabled: boolean;
  implicitFlowEnabled: boolean;
  directAccessGrantsEnabled: boolean;
  serviceAccountsEnabled: boolean;
  authorizationServicesEnabled: boolean;
  /**
   * @deprecated
   */
  directGrantsOnly: boolean;
  publicClient: boolean;
  frontchannelLogout: boolean;
  protocol: string;
  attributes: {};
  authenticationFlowBindingOverrides: {};
  fullScopeAllowed: boolean;
  nodeReRegistrationTimeout: number;
  registeredNodes: {};
  protocolMappers: Array<ProtocolMapperRepresentation>;
  /**
   * @deprecated
   */
  clientTemplate: string;
  /**
   * @deprecated
   */
  useTemplateConfig: boolean;
  /**
   * @deprecated
   */
  useTemplateScope: boolean;
  /**
   * @deprecated
   */
  useTemplateMappers: boolean;
  defaultClientScopes: Array<string>;
  optionalClientScopes: Array<string>;
  authorizationSettings: ResourceServerRepresentation;
  access: {};
  origin: string;
}>;
type ClientScopeRepresentation = Partial<{
  id: string;
  name: string;
  description: string;
  protocol: string;
  attributes: {};
  protocolMappers: Array<ProtocolMapperRepresentation>;
}>;
type ClientTemplateRepresentation = Partial<{
  id: string;
  name: string;
  description: string;
  protocol: string;
  fullScopeAllowed: boolean;
  bearerOnly: boolean;
  consentRequired: boolean;
  standardFlowEnabled: boolean;
  implicitFlowEnabled: boolean;
  directAccessGrantsEnabled: boolean;
  serviceAccountsEnabled: boolean;
  publicClient: boolean;
  frontchannelLogout: boolean;
  attributes: {};
  protocolMappers: Array<ProtocolMapperRepresentation>;
}>;
type ComponentExportRepresentation = Partial<{
  id: string;
  name: string;
  providerId: string;
  subType: string;
  config: MultivaluedHashMapStringString;
}>;
type MultivaluedHashMapStringString = Partial<
  {
    empty: boolean;
  } & {
    [key: string]: /**
     * @deprecated
     */
    Array</**
     * @deprecated
     */
    string>;
  }
>;
type OAuthClientRepresentation = Partial<{
  id: string;
  clientId: string;
  name: string;
  description: string;
  rootUrl: string;
  adminUrl: string;
  baseUrl: string;
  surrogateAuthRequired: boolean;
  enabled: boolean;
  alwaysDisplayInConsole: boolean;
  clientAuthenticatorType: string;
  secret: string;
  registrationAccessToken: string;
  /**
   * @deprecated
   */
  defaultRoles: Array</**
   * @deprecated
   */
  string>;
  redirectUris: Array<string>;
  webOrigins: Array<string>;
  notBefore: number;
  bearerOnly: boolean;
  consentRequired: boolean;
  standardFlowEnabled: boolean;
  implicitFlowEnabled: boolean;
  directAccessGrantsEnabled: boolean;
  serviceAccountsEnabled: boolean;
  authorizationServicesEnabled: boolean;
  /**
   * @deprecated
   */
  directGrantsOnly: boolean;
  publicClient: boolean;
  frontchannelLogout: boolean;
  protocol: string;
  attributes: {};
  authenticationFlowBindingOverrides: {};
  fullScopeAllowed: boolean;
  nodeReRegistrationTimeout: number;
  registeredNodes: {};
  protocolMappers: Array<ProtocolMapperRepresentation>;
  /**
   * @deprecated
   */
  clientTemplate: string;
  /**
   * @deprecated
   */
  useTemplateConfig: boolean;
  /**
   * @deprecated
   */
  useTemplateScope: boolean;
  /**
   * @deprecated
   */
  useTemplateMappers: boolean;
  defaultClientScopes: Array<string>;
  optionalClientScopes: Array<string>;
  authorizationSettings: ResourceServerRepresentation;
  access: {};
  origin: string;
  claims: ClaimRepresentation;
}>;
type PolicyRepresentation = Partial<{
  id: string;
  name: string;
  description: string;
  type: string;
  policies: Array<string>;
  resources: Array<string>;
  scopes: Array<string>;
  /**
   * @enum POSITIVE, NEGATIVE
   */
  logic: 'POSITIVE' | 'NEGATIVE';
  /**
   * @enum AFFIRMATIVE, UNANIMOUS, CONSENSUS
   */
  decisionStrategy: 'AFFIRMATIVE' | 'UNANIMOUS' | 'CONSENSUS';
  owner: string;
  resourcesData: Array<ResourceRepresentation>;
  scopesData: Array<ScopeRepresentation>;
  config: {};
}>;
type RealmRepresentation = Partial<{
  id: string;
  realm: string;
  displayName: string;
  displayNameHtml: string;
  notBefore: number;
  defaultSignatureAlgorithm: string;
  revokeRefreshToken: boolean;
  refreshTokenMaxReuse: number;
  accessTokenLifespan: number;
  accessTokenLifespanForImplicitFlow: number;
  ssoSessionIdleTimeout: number;
  ssoSessionMaxLifespan: number;
  ssoSessionIdleTimeoutRememberMe: number;
  ssoSessionMaxLifespanRememberMe: number;
  offlineSessionIdleTimeout: number;
  offlineSessionMaxLifespanEnabled: boolean;
  offlineSessionMaxLifespan: number;
  clientSessionIdleTimeout: number;
  clientSessionMaxLifespan: number;
  clientOfflineSessionIdleTimeout: number;
  clientOfflineSessionMaxLifespan: number;
  accessCodeLifespan: number;
  accessCodeLifespanUserAction: number;
  accessCodeLifespanLogin: number;
  actionTokenGeneratedByAdminLifespan: number;
  actionTokenGeneratedByUserLifespan: number;
  oauth2DeviceCodeLifespan: number;
  oauth2DevicePollingInterval: number;
  enabled: boolean;
  sslRequired: string;
  /**
   * @deprecated
   */
  passwordCredentialGrantAllowed: boolean;
  registrationAllowed: boolean;
  registrationEmailAsUsername: boolean;
  rememberMe: boolean;
  verifyEmail: boolean;
  loginWithEmailAllowed: boolean;
  duplicateEmailsAllowed: boolean;
  resetPasswordAllowed: boolean;
  editUsernameAllowed: boolean;
  bruteForceProtected: boolean;
  permanentLockout: boolean;
  maxTemporaryLockouts: number;
  maxFailureWaitSeconds: number;
  minimumQuickLoginWaitSeconds: number;
  waitIncrementSeconds: number;
  quickLoginCheckMilliSeconds: number;
  maxDeltaTimeSeconds: number;
  failureFactor: number;
  /**
   * @deprecated
   */
  privateKey: string;
  /**
   * @deprecated
   */
  publicKey: string;
  /**
   * @deprecated
   */
  certificate: string;
  /**
   * @deprecated
   */
  codeSecret: string;
  roles: RolesRepresentation;
  groups: Array<GroupRepresentation>;
  /**
   * @deprecated
   */
  defaultRoles: Array</**
   * @deprecated
   */
  string>;
  defaultRole: RoleRepresentation;
  defaultGroups: Array<string>;
  /**
   * @deprecated
   */
  requiredCredentials: Array</**
   * @deprecated
   */
  string>;
  passwordPolicy: string;
  otpPolicyType: string;
  otpPolicyAlgorithm: string;
  otpPolicyInitialCounter: number;
  otpPolicyDigits: number;
  otpPolicyLookAheadWindow: number;
  otpPolicyPeriod: number;
  otpPolicyCodeReusable: boolean;
  otpSupportedApplications: Array<string>;
  localizationTexts: {};
  webAuthnPolicyRpEntityName: string;
  webAuthnPolicySignatureAlgorithms: Array<string>;
  webAuthnPolicyRpId: string;
  webAuthnPolicyAttestationConveyancePreference: string;
  webAuthnPolicyAuthenticatorAttachment: string;
  webAuthnPolicyRequireResidentKey: string;
  webAuthnPolicyUserVerificationRequirement: string;
  webAuthnPolicyCreateTimeout: number;
  webAuthnPolicyAvoidSameAuthenticatorRegister: boolean;
  webAuthnPolicyAcceptableAaguids: Array<string>;
  webAuthnPolicyExtraOrigins: Array<string>;
  webAuthnPolicyPasswordlessRpEntityName: string;
  webAuthnPolicyPasswordlessSignatureAlgorithms: Array<string>;
  webAuthnPolicyPasswordlessRpId: string;
  webAuthnPolicyPasswordlessAttestationConveyancePreference: string;
  webAuthnPolicyPasswordlessAuthenticatorAttachment: string;
  webAuthnPolicyPasswordlessRequireResidentKey: string;
  webAuthnPolicyPasswordlessUserVerificationRequirement: string;
  webAuthnPolicyPasswordlessCreateTimeout: number;
  webAuthnPolicyPasswordlessAvoidSameAuthenticatorRegister: boolean;
  webAuthnPolicyPasswordlessAcceptableAaguids: Array<string>;
  webAuthnPolicyPasswordlessExtraOrigins: Array<string>;
  users: Array<UserRepresentation>;
  federatedUsers: Array<UserRepresentation>;
  scopeMappings: Array<ScopeMappingRepresentation>;
  clientScopeMappings: {};
  clients: Array<ClientRepresentation>;
  clientScopes: Array<ClientScopeRepresentation>;
  defaultDefaultClientScopes: Array<string>;
  defaultOptionalClientScopes: Array<string>;
  browserSecurityHeaders: {};
  smtpServer: {};
  userFederationProviders: Array<UserFederationProviderRepresentation>;
  userFederationMappers: Array<UserFederationMapperRepresentation>;
  loginTheme: string;
  accountTheme: string;
  adminTheme: string;
  emailTheme: string;
  eventsEnabled: boolean;
  eventsExpiration: number;
  eventsListeners: Array<string>;
  enabledEventTypes: Array<string>;
  adminEventsEnabled: boolean;
  adminEventsDetailsEnabled: boolean;
  identityProviders: Array<IdentityProviderRepresentation>;
  identityProviderMappers: Array<IdentityProviderMapperRepresentation>;
  protocolMappers: Array<ProtocolMapperRepresentation>;
  components: Partial<
    {
      empty: boolean;
    } & {
      [key: string]: Array<ComponentExportRepresentation>;
    }
  >;
  internationalizationEnabled: boolean;
  supportedLocales: Array<string>;
  defaultLocale: string;
  authenticationFlows: Array<AuthenticationFlowRepresentation>;
  authenticatorConfig: Array<AuthenticatorConfigRepresentation>;
  requiredActions: Array<RequiredActionProviderRepresentation>;
  browserFlow: string;
  registrationFlow: string;
  directGrantFlow: string;
  resetCredentialsFlow: string;
  clientAuthenticationFlow: string;
  dockerAuthenticationFlow: string;
  firstBrokerLoginFlow: string;
  attributes: {};
  keycloakVersion: string;
  userManagedAccessAllowed: boolean;
  /**
   * @deprecated
   */
  social: boolean;
  /**
   * @deprecated
   */
  updateProfileOnInitialSocialLogin: boolean;
  /**
   * @deprecated
   */
  socialProviders: {};
  /**
   * @deprecated
   */
  applicationScopeMappings: {};
  /**
   * @deprecated
   */
  applications: Array<ApplicationRepresentation>;
  /**
   * @deprecated
   */
  oauthClients: Array<OAuthClientRepresentation>;
  /**
   * @deprecated
   */
  clientTemplates: Array<ClientTemplateRepresentation>;
  clientProfiles: JsonNode;
  clientPolicies: JsonNode;
}>;
type RolesRepresentation = Partial<{
  realm: Array<RoleRepresentation>;
  client: {};
  /**
   * @deprecated
   */
  application: {};
}>;
type GroupRepresentation = Partial<{
  id: string;
  name: string;
  path: string;
  parentId: string;
  subGroupCount: number;
  attributes: {};
  realmRoles: Array<string>;
  clientRoles: {};
  access: {};
}>;
type UserRepresentation = Partial<{
  id: string;
  username: string;
  firstName: string;
  lastName: string;
  email: string;
  emailVerified: boolean;
  attributes: {};
  userProfileMetadata: UserProfileMetadata;
  self: string;
  origin: string;
  createdTimestamp: number;
  enabled: boolean;
  /**
   * @deprecated
   */
  totp: boolean;
  federationLink: string;
  serviceAccountClientId: string;
  credentials: Array<CredentialRepresentation>;
  disableableCredentialTypes: Array<string>;
  requiredActions: Array<string>;
  federatedIdentities: Array<FederatedIdentityRepresentation>;
  realmRoles: Array<string>;
  clientRoles: {};
  clientConsents: Array<UserConsentRepresentation>;
  notBefore: number;
  /**
   * @deprecated
   */
  applicationRoles: {};
  /**
   * @deprecated
   */
  socialLinks: Array<SocialLinkRepresentation>;
  groups: Array<string>;
  access: {};
}>;
type UserProfileMetadata = Partial<{
  attributes: Array<UserProfileAttributeMetadata>;
  groups: Array<UserProfileAttributeGroupMetadata>;
}>;
type UserProfileAttributeMetadata = Partial<{
  name: string;
  displayName: string;
  required: boolean;
  readOnly: boolean;
  annotations: {};
  validators: {};
  group: string;
  multivalued: boolean;
}>;
type UserProfileAttributeGroupMetadata = Partial<{
  name: string;
  displayHeader: string;
  displayDescription: string;
  annotations: {};
}>;
type CredentialRepresentation = Partial<{
  id: string;
  type: string;
  userLabel: string;
  createdDate: number;
  secretData: string;
  credentialData: string;
  priority: number;
  value: string;
  temporary: boolean;
  /**
   * @deprecated
   */
  device: string;
  /**
   * @deprecated
   */
  hashedSaltedValue: string;
  /**
   * @deprecated
   */
  salt: string;
  /**
   * @deprecated
   */
  hashIterations: number;
  /**
   * @deprecated
   */
  counter: number;
  /**
   * @deprecated
   */
  algorithm: string;
  /**
   * @deprecated
   */
  digits: number;
  /**
   * @deprecated
   */
  period: number;
  /**
   * @deprecated
   */
  config: Partial<
    {
      empty: boolean;
    } & {
      [key: string]: /**
       * @deprecated
       */
      Array</**
       * @deprecated
       */
      string>;
    }
  >;
}>;
type FederatedIdentityRepresentation = Partial<{
  identityProvider: string;
  userId: string;
  userName: string;
}>;
type UserConsentRepresentation = Partial<{
  clientId: string;
  grantedClientScopes: Array<string>;
  createdDate: number;
  lastUpdatedDate: number;
  /**
   * @deprecated
   */
  grantedRealmRoles: Array</**
   * @deprecated
   */
  string>;
}>;
type SocialLinkRepresentation = Partial<{
  socialProvider: string;
  socialUserId: string;
  socialUsername: string;
}>;
type ScopeMappingRepresentation = Partial<{
  self: string;
  client: string;
  /**
   * @deprecated
   */
  clientTemplate: string;
  clientScope: string;
  roles: Array<string>;
}>;
type UserFederationProviderRepresentation = Partial<{
  id: string;
  displayName: string;
  providerName: string;
  config: {};
  priority: number;
  fullSyncPeriod: number;
  changedSyncPeriod: number;
  lastSync: number;
}>;
type UserFederationMapperRepresentation = Partial<{
  id: string;
  name: string;
  federationProviderDisplayName: string;
  federationMapperType: string;
  config: {};
}>;
type IdentityProviderRepresentation = Partial<{
  alias: string;
  displayName: string;
  internalId: string;
  providerId: string;
  enabled: boolean;
  /**
   * @deprecated
   */
  updateProfileFirstLoginMode: string;
  trustEmail: boolean;
  storeToken: boolean;
  addReadTokenRoleOnCreate: boolean;
  /**
   * @deprecated
   */
  authenticateByDefault: boolean;
  linkOnly: boolean;
  firstBrokerLoginFlowAlias: string;
  postBrokerLoginFlowAlias: string;
  config: {};
  /**
   * @deprecated
   */
  updateProfileFirstLogin: boolean;
}>;
type IdentityProviderMapperRepresentation = Partial<{
  id: string;
  name: string;
  identityProviderAlias: string;
  identityProviderMapper: string;
  config: {};
}>;
type AuthenticatorConfigRepresentation = Partial<{
  id: string;
  alias: string;
  config: {};
}>;
type RequiredActionProviderRepresentation = Partial<{
  alias: string;
  name: string;
  providerId: string;
  enabled: boolean;
  defaultAction: boolean;
  priority: number;
  config: {};
}>;
type JsonNode = {};
type ResourceRepresentation = Partial<{
  name: string;
  type: string;
  owner: ResourceOwnerRepresentation;
  ownerManagedAccess: boolean;
  displayName: string;
  attributes: {};
  _id: string;
  uris: Array<string>;
  scopes: Array<ScopeRepresentation>;
  icon_uri: string;
  resource_scopes: Array<ScopeRepresentation>;
  /**
   * @deprecated
   */
  uri: string;
}>;
type ScopeRepresentation = Partial<{
  id: string;
  name: string;
  iconUri: string;
  policies: Array<PolicyRepresentation>;
  resources: Array<ResourceRepresentation>;
  displayName: string;
}>;
type UpdateUserRequest = Partial<{
  email: string;
  sendWelcomeEmailOnPasswordReset: boolean;
  enabled: boolean;
  userPermissions: Array<AssetGroupPermissionsDto | TaxonomyPermissionDto>;
  assignedRoles: Array<RoleRepresentation>;
  assignedAccessLevels: Array<RoleRepresentation>;
  /**
   * @pattern ^[^<>&"\v$%!#?§;*~/\\|^=\[\]{}()\p{Cntrl}]+$
   */
  firstname: string;
  /**
   * @pattern ^[^<>&"\v$%!#?§;*~/\\|^=\[\]{}()\p{Cntrl}]+$
   */
  lastname: string;
  resetpassword: boolean;
}>;

const TenantDefaultUserPermissionsDto: z.ZodType<TenantDefaultUserPermissionsDto> = z
  .object({ defaultGlobalPermissions: z.enum(['ALL', 'NONE']) })
  .partial()
  .passthrough();
const TenantDto: z.ZodType<TenantDto> = z
  .object({
    tenantId: z
      .string()
      .regex(/^(?=[a-z0-9-]{3,36}$)[a-z0-9]+(-[a-z0-9]+){0,17}$/)
      .describe('The tenantId'),
    displayName: z.string().optional(),
    enabled: z.boolean().optional(),
    salesforceId: z
      .string()
      .regex(/^[a-zA-Z0-9]{18}|[a-zA-Z0-9]{15}$/)
      .describe('The salesforce id')
      .optional(),
    defaultUserPermissions: TenantDefaultUserPermissionsDto.optional(),
    deletedTs: z.string().datetime({ offset: true }).optional(),
    parentTenantId: z.string().optional(),
    childTenantLimit: z.number().int().optional(),
    partner: z.boolean().optional(),
  })
  .passthrough();
const UpdateTenantRequest: z.ZodType<UpdateTenantRequest> = z
  .object({
    displayName: z.string().optional(),
    salesforceId: z
      .string()
      .regex(/^[a-zA-Z0-9]{18}|[a-zA-Z0-9]{15}$/)
      .describe('The salesforce id')
      .optional(),
    adminUserActionLifespan: z
      .object({
        seconds: z.number().int(),
        zero: z.boolean(),
        nano: z.number().int(),
        negative: z.boolean(),
        units: z.array(
          z
            .object({ durationEstimated: z.boolean(), timeBased: z.boolean(), dateBased: z.boolean() })
            .partial()
            .passthrough()
        ),
      })
      .partial()
      .passthrough()
      .optional(),
    userActionLifespan: z
      .object({
        seconds: z.number().int(),
        zero: z.boolean(),
        nano: z.number().int(),
        negative: z.boolean(),
        units: z.array(
          z
            .object({ durationEstimated: z.boolean(), timeBased: z.boolean(), dateBased: z.boolean() })
            .partial()
            .passthrough()
        ),
      })
      .partial()
      .passthrough()
      .optional(),
    defaultUserPermissions: TenantDefaultUserPermissionsDto.optional(),
    parentTenantId: z
      .string()
      .regex(/^(?=[a-z0-9-]{3,36}$)[a-z0-9]+(-[a-z0-9]+){0,17}$/)
      .describe("The tenant's parent id"),
    childTenantLimit: z
      .number()
      .int()
      .gte(0)
      .describe('The number of child tenants a parent tenant is allowed to have.')
      .optional(),
    partner: z.boolean().describe('If the tenant is an affinity partner').optional(),
  })
  .passthrough();
const CreateTenantRequest: z.ZodType<CreateTenantRequest> = z
  .object({
    tenantId: z.string().optional(),
    displayName: z.string().optional(),
    salesforceId: z
      .string()
      .regex(/^[a-zA-Z0-9]{18}|[a-zA-Z0-9]{15}$/)
      .describe('The salesforce id')
      .optional(),
    adminUserActionLifespan: z
      .object({
        seconds: z.number().int(),
        zero: z.boolean(),
        nano: z.number().int(),
        negative: z.boolean(),
        units: z.array(
          z
            .object({ durationEstimated: z.boolean(), timeBased: z.boolean(), dateBased: z.boolean() })
            .partial()
            .passthrough()
        ),
      })
      .partial()
      .passthrough()
      .optional(),
    userActionLifespan: z
      .object({
        seconds: z.number().int(),
        zero: z.boolean(),
        nano: z.number().int(),
        negative: z.boolean(),
        units: z.array(
          z
            .object({ durationEstimated: z.boolean(), timeBased: z.boolean(), dateBased: z.boolean() })
            .partial()
            .passthrough()
        ),
      })
      .partial()
      .passthrough()
      .optional(),
    defaultUserPermissions: TenantDefaultUserPermissionsDto,
    parentTenantId: z
      .string()
      .regex(/^(?=[a-z0-9-]{3,36}$)[a-z0-9]+(-[a-z0-9]+){0,17}$/)
      .describe("The tenant's parent id"),
    childTenantLimit: z
      .number()
      .int()
      .gte(0)
      .describe('The number of child tenants a parent tenant is allowed to have.')
      .optional(),
    partner: z.boolean().describe('If the tenant is an affinity partner').optional(),
  })
  .passthrough();
const TaxonomyDto: z.ZodType<TaxonomyDto> = z
  .object({ tenantId: z.string(), taxonomyName: z.string(), taxonomyVersion: z.string() })
  .passthrough();
const TaxonomyNodeDto: z.ZodType<TaxonomyNodeDto> = z.object({ level: z.string(), item: z.string() }).passthrough();
const PermissionDto: z.ZodType<PermissionDto> = z
  .object({
    type: z.enum(['TAXONOMY', 'ASSET_GROUP']),
    taxonomy: TaxonomyDto,
    permittedActions: z.record(z.array(z.string())),
    taxonomyPath: z.array(TaxonomyNodeDto),
  })
  .partial()
  .passthrough();
const AssetGroupPermissionsDto: z.ZodType<AssetGroupPermissionsDto> = PermissionDto.and(
  z
    .object({ assetGroup: z.record(z.array(z.string())) })
    .partial()
    .passthrough()
);
const TaxonomyPermissionDto: z.ZodType<TaxonomyPermissionDto> = PermissionDto;
const Composites: z.ZodType<Composites> = z
  .object({
    realm: z.array(z.string()),
    client: z.record(z.array(z.string())),
    application: z.record(z.array(z.string())),
  })
  .partial()
  .passthrough();
const RoleRepresentation: z.ZodType<RoleRepresentation> = z
  .object({
    id: z.string(),
    name: z.string(),
    description: z.string(),
    scopeParamRequired: z.boolean(),
    composite: z.boolean(),
    composites: Composites,
    clientRole: z.boolean(),
    containerId: z.string(),
    attributes: z.record(z.array(z.string())),
  })
  .partial()
  .passthrough();
const CreateUserRequest: z.ZodType<CreateUserRequest> = z
  .object({
    username: z.string().regex(/^[a-z0-9_\-\.@]{3,63}$/),
    email: z.string().optional(),
    userPermissions: z.array(z.union([AssetGroupPermissionsDto, TaxonomyPermissionDto])).optional(),
    assignedRoles: z.array(RoleRepresentation).optional(),
    assignedAccessLevels: z.array(RoleRepresentation).optional(),
    firstname: z
      .string()
      .regex(/^[^<>&"\v$%!#?§;*~\/\\|^=\[\]{}()\p{Cntrl}]+$/)
      .optional(),
    lastname: z
      .string()
      .regex(/^[^<>&"\v$%!#?§;*~\/\\|^=\[\]{}()\p{Cntrl}]+$/)
      .optional(),
  })
  .passthrough();
const UserDto: z.ZodType<UserDto> = z
  .object({
    username: z.string(),
    email: z.string(),
    enabled: z.boolean(),
    userPermissions: z.array(PermissionDto),
    assignedRoles: z.array(RoleRepresentation),
    assignedAccessLevels: z.array(RoleRepresentation),
    userid: z.string().uuid(),
    firstname: z.string(),
    lastname: z.string(),
  })
  .partial()
  .passthrough();
const KeycloakUserSearch = z
  .object({
    search: z.string(),
    lastName: z.string(),
    firstName: z.string(),
    email: z.string(),
    username: z.string(),
    emailVerified: z.boolean(),
    idpAlias: z.string(),
    idpUserId: z.string(),
    enabled: z.boolean(),
    briefRepresentation: z.boolean(),
    exact: z.boolean(),
    searchQuery: z.string(),
  })
  .partial()
  .passthrough();
const TspDto = z
  .object({
    configurationId: z.string().uuid(),
    connectorName: z.string(),
    tspType: z.string(),
    tspAttributes: z.record(z.object({}).partial().passthrough()),
    enabled: z.boolean(),
    dataLakeAutomation: z.boolean(),
    vhost: z.string(),
  })
  .partial()
  .passthrough();
const TspSecret: z.ZodType<TspSecret> = z.object({}).partial().passthrough();
const TspRequestTspSecret: z.ZodType<TspRequestTspSecret> = z
  .object({
    enabled: z.boolean().optional(),
    connectorName: z.string().optional(),
    tspType: z.enum(['GEOTAB', 'CALAMP', 'SAMSARA', 'PLATFORM_SCIENCE']),
    tspAttributes: TspSecret,
    dataLakeAutomation: z.boolean().optional(),
  })
  .passthrough();
const CalampSecret: z.ZodType<CalampSecret> = z
  .object({ app_id: z.string(), username: z.string(), password: z.string() })
  .passthrough();
const CalAmpRequest: z.ZodType<CalAmpRequest> = TspRequestTspSecret.and(
  z
    .object({
      connectorName: z.string().regex(/^(?=[a-z0-9-]{3,16}$)[a-z0-9]+(-[a-z0-9]+){0,8}$/),
      tspAttributes: CalampSecret,
    })
    .partial()
    .passthrough()
);
const GeotabSecret: z.ZodType<GeotabSecret> = z
  .object({ username: z.string(), password: z.string(), database: z.string(), server: z.string().optional() })
  .passthrough();
const GeoTabRequest: z.ZodType<GeoTabRequest> = TspRequestTspSecret.and(
  z
    .object({
      connectorName: z.string().regex(/^(?=[a-z0-9-]{3,16}$)[a-z0-9]+(-[a-z0-9]+){0,8}$/),
      tspAttributes: GeotabSecret,
    })
    .partial()
    .passthrough()
);
const PlatSciSecret: z.ZodType<PlatSciSecret> = z
  .object({ asset_url: z.string(), ASSET_BEARER_TOKEN: z.string() })
  .passthrough();
const PlatSciRequest: z.ZodType<PlatSciRequest> = TspRequestTspSecret.and(
  z
    .object({
      connectorName: z.string().regex(/^(?=[a-z0-9-]{3,16}$)[a-z0-9]+(-[a-z0-9]+){0,8}$/),
      tspAttributes: PlatSciSecret,
      vhost: z.string(),
    })
    .partial()
    .passthrough()
);
const SamsaraSecret: z.ZodType<SamsaraSecret> = z.object({ bearer_token: z.string() }).passthrough();
const SamsaraRequest: z.ZodType<SamsaraRequest> = TspRequestTspSecret.and(
  z
    .object({
      connectorName: z.string().regex(/^(?=[a-z0-9-]{3,16}$)[a-z0-9]+(-[a-z0-9]+){0,8}$/),
      tspAttributes: SamsaraSecret,
    })
    .partial()
    .passthrough()
);
const createTsp_Body = z.union([CalAmpRequest, GeoTabRequest, PlatSciRequest, SamsaraRequest]);
const IdentityProviderMapper: z.ZodType<IdentityProviderMapper> = z
  .object({ name: z.string(), claim: z.string(), userAttribute: z.string() })
  .passthrough();
const CreateIdentityProviderRequest: z.ZodType<CreateIdentityProviderRequest> = z
  .object({
    name: z.string(),
    clientId: z.string(),
    clientSecret: z.string(),
    authorizationUrl: z.string().optional(),
    tokenUrl: z.string().optional(),
    userInfoUrl: z.string().optional(),
    issuerUrl: z.string().optional(),
    jwksUrl: z.string().optional(),
    firstLoginFlow: z.string(),
    mappers: z.array(IdentityProviderMapper).optional(),
  })
  .passthrough();
const UpdateUserRequest: z.ZodType<UpdateUserRequest> = z
  .object({
    email: z.string(),
    sendWelcomeEmailOnPasswordReset: z.boolean(),
    enabled: z.boolean(),
    userPermissions: z.array(z.union([AssetGroupPermissionsDto, TaxonomyPermissionDto])),
    assignedRoles: z.array(RoleRepresentation),
    assignedAccessLevels: z.array(RoleRepresentation),
    firstname: z.string().regex(/^[^<>&"\v$%!#?§;*~\/\\|^=\[\]{}()\p{Cntrl}]+$/),
    lastname: z.string().regex(/^[^<>&"\v$%!#?§;*~\/\\|^=\[\]{}()\p{Cntrl}]+$/),
    resetpassword: z.boolean(),
  })
  .partial()
  .passthrough();
const SortObject: z.ZodType<SortObject> = z
  .object({
    direction: z.string(),
    nullHandling: z.string(),
    ascending: z.boolean(),
    property: z.string(),
    ignoreCase: z.boolean(),
  })
  .partial()
  .passthrough();
const PageableObject: z.ZodType<PageableObject> = z
  .object({
    offset: z.number().int(),
    sort: z.array(SortObject),
    paged: z.boolean(),
    pageNumber: z.number().int(),
    pageSize: z.number().int(),
    unpaged: z.boolean(),
  })
  .partial()
  .passthrough();
const PageUserDto: z.ZodType<PageUserDto> = z
  .object({
    totalPages: z.number().int(),
    totalElements: z.number().int(),
    first: z.boolean(),
    last: z.boolean(),
    size: z.number().int(),
    content: z.array(UserDto),
    number: z.number().int(),
    sort: z.array(SortObject),
    pageable: PageableObject,
    numberOfElements: z.number().int(),
    empty: z.boolean(),
  })
  .partial()
  .passthrough();
const UserEventDto = z
  .object({ time: z.number().int(), type: z.string(), userid: z.string().uuid() })
  .partial()
  .passthrough();
const ProtocolMapperRepresentation: z.ZodType<ProtocolMapperRepresentation> = z
  .object({
    id: z.string(),
    name: z.string(),
    protocol: z.string(),
    protocolMapper: z.string(),
    consentRequired: z.boolean(),
    consentText: z.string(),
    config: z.record(z.string()),
  })
  .partial()
  .passthrough();
const ResourceOwnerRepresentation: z.ZodType<ResourceOwnerRepresentation> = z
  .object({ id: z.string(), name: z.string() })
  .partial()
  .passthrough();
const PolicyRepresentation: z.ZodType<PolicyRepresentation> = z.lazy(() =>
  z
    .object({
      id: z.string(),
      name: z.string(),
      description: z.string(),
      type: z.string(),
      policies: z.array(z.string()),
      resources: z.array(z.string()),
      scopes: z.array(z.string()),
      logic: z.enum(['POSITIVE', 'NEGATIVE']),
      decisionStrategy: z.enum(['AFFIRMATIVE', 'UNANIMOUS', 'CONSENSUS']),
      owner: z.string(),
      resourcesData: z.array(ResourceRepresentation),
      scopesData: z.array(ScopeRepresentation),
      config: z.record(z.string()),
    })
    .partial()
    .passthrough()
);
const ScopeRepresentation: z.ZodType<ScopeRepresentation> = z.lazy(() =>
  z
    .object({
      id: z.string(),
      name: z.string(),
      iconUri: z.string(),
      policies: z.array(PolicyRepresentation),
      resources: z.array(ResourceRepresentation),
      displayName: z.string(),
    })
    .partial()
    .passthrough()
);
const ResourceRepresentation: z.ZodType<ResourceRepresentation> = z.lazy(() =>
  z
    .object({
      name: z.string(),
      type: z.string(),
      owner: ResourceOwnerRepresentation,
      ownerManagedAccess: z.boolean(),
      displayName: z.string(),
      attributes: z.record(z.array(z.string())),
      _id: z.string(),
      uris: z.array(z.string()),
      scopes: z.array(ScopeRepresentation),
      icon_uri: z.string(),
      resource_scopes: z.array(ScopeRepresentation),
      uri: z.string(),
    })
    .partial()
    .passthrough()
);
const ResourceServerRepresentation: z.ZodType<ResourceServerRepresentation> = z
  .object({
    id: z.string(),
    clientId: z.string(),
    name: z.string(),
    allowRemoteResourceManagement: z.boolean(),
    policyEnforcementMode: z.enum(['ENFORCING', 'PERMISSIVE', 'DISABLED']),
    resources: z.array(ResourceRepresentation),
    policies: z.array(PolicyRepresentation),
    scopes: z.array(ScopeRepresentation),
    decisionStrategy: z.enum(['AFFIRMATIVE', 'UNANIMOUS', 'CONSENSUS']),
  })
  .partial()
  .passthrough();
const ClaimRepresentation: z.ZodType<ClaimRepresentation> = z
  .object({
    name: z.boolean(),
    username: z.boolean(),
    profile: z.boolean(),
    picture: z.boolean(),
    website: z.boolean(),
    email: z.boolean(),
    gender: z.boolean(),
    locale: z.boolean(),
    address: z.boolean(),
    phone: z.boolean(),
  })
  .partial()
  .passthrough();
const ApplicationRepresentation: z.ZodType<ApplicationRepresentation> = z
  .object({
    id: z.string(),
    clientId: z.string(),
    name: z.string(),
    description: z.string(),
    rootUrl: z.string(),
    adminUrl: z.string(),
    baseUrl: z.string(),
    surrogateAuthRequired: z.boolean(),
    enabled: z.boolean(),
    alwaysDisplayInConsole: z.boolean(),
    clientAuthenticatorType: z.string(),
    secret: z.string(),
    registrationAccessToken: z.string(),
    defaultRoles: z.array(z.string()),
    redirectUris: z.array(z.string()),
    webOrigins: z.array(z.string()),
    notBefore: z.number().int(),
    bearerOnly: z.boolean(),
    consentRequired: z.boolean(),
    standardFlowEnabled: z.boolean(),
    implicitFlowEnabled: z.boolean(),
    directAccessGrantsEnabled: z.boolean(),
    serviceAccountsEnabled: z.boolean(),
    authorizationServicesEnabled: z.boolean(),
    directGrantsOnly: z.boolean(),
    publicClient: z.boolean(),
    frontchannelLogout: z.boolean(),
    protocol: z.string(),
    attributes: z.record(z.string()),
    authenticationFlowBindingOverrides: z.record(z.string()),
    fullScopeAllowed: z.boolean(),
    nodeReRegistrationTimeout: z.number().int(),
    registeredNodes: z.record(z.number().int()),
    protocolMappers: z.array(ProtocolMapperRepresentation),
    clientTemplate: z.string(),
    useTemplateConfig: z.boolean(),
    useTemplateScope: z.boolean(),
    useTemplateMappers: z.boolean(),
    defaultClientScopes: z.array(z.string()),
    optionalClientScopes: z.array(z.string()),
    authorizationSettings: ResourceServerRepresentation,
    access: z.record(z.boolean()),
    origin: z.string(),
    claims: ClaimRepresentation,
  })
  .partial()
  .passthrough();
const AuthenticationExecutionExportRepresentation: z.ZodType<AuthenticationExecutionExportRepresentation> = z
  .object({
    authenticatorConfig: z.string(),
    authenticator: z.string(),
    authenticatorFlow: z.boolean(),
    requirement: z.string(),
    priority: z.number().int(),
    autheticatorFlow: z.boolean(),
    flowAlias: z.string(),
    userSetupAllowed: z.boolean(),
  })
  .partial()
  .passthrough();
const AuthenticationFlowRepresentation: z.ZodType<AuthenticationFlowRepresentation> = z
  .object({
    id: z.string(),
    alias: z.string(),
    description: z.string(),
    providerId: z.string(),
    topLevel: z.boolean(),
    builtIn: z.boolean(),
    authenticationExecutions: z.array(AuthenticationExecutionExportRepresentation),
  })
  .partial()
  .passthrough();
const AuthenticatorConfigRepresentation: z.ZodType<AuthenticatorConfigRepresentation> = z
  .object({ id: z.string(), alias: z.string(), config: z.record(z.string()) })
  .partial()
  .passthrough();
const ClientRepresentation: z.ZodType<ClientRepresentation> = z
  .object({
    id: z.string(),
    clientId: z.string(),
    name: z.string(),
    description: z.string(),
    rootUrl: z.string(),
    adminUrl: z.string(),
    baseUrl: z.string(),
    surrogateAuthRequired: z.boolean(),
    enabled: z.boolean(),
    alwaysDisplayInConsole: z.boolean(),
    clientAuthenticatorType: z.string(),
    secret: z.string(),
    registrationAccessToken: z.string(),
    defaultRoles: z.array(z.string()),
    redirectUris: z.array(z.string()),
    webOrigins: z.array(z.string()),
    notBefore: z.number().int(),
    bearerOnly: z.boolean(),
    consentRequired: z.boolean(),
    standardFlowEnabled: z.boolean(),
    implicitFlowEnabled: z.boolean(),
    directAccessGrantsEnabled: z.boolean(),
    serviceAccountsEnabled: z.boolean(),
    authorizationServicesEnabled: z.boolean(),
    directGrantsOnly: z.boolean(),
    publicClient: z.boolean(),
    frontchannelLogout: z.boolean(),
    protocol: z.string(),
    attributes: z.record(z.string()),
    authenticationFlowBindingOverrides: z.record(z.string()),
    fullScopeAllowed: z.boolean(),
    nodeReRegistrationTimeout: z.number().int(),
    registeredNodes: z.record(z.number().int()),
    protocolMappers: z.array(ProtocolMapperRepresentation),
    clientTemplate: z.string(),
    useTemplateConfig: z.boolean(),
    useTemplateScope: z.boolean(),
    useTemplateMappers: z.boolean(),
    defaultClientScopes: z.array(z.string()),
    optionalClientScopes: z.array(z.string()),
    authorizationSettings: ResourceServerRepresentation,
    access: z.record(z.boolean()),
    origin: z.string(),
  })
  .partial()
  .passthrough();
const ClientScopeRepresentation: z.ZodType<ClientScopeRepresentation> = z
  .object({
    id: z.string(),
    name: z.string(),
    description: z.string(),
    protocol: z.string(),
    attributes: z.record(z.string()),
    protocolMappers: z.array(ProtocolMapperRepresentation),
  })
  .partial()
  .passthrough();
const ClientTemplateRepresentation: z.ZodType<ClientTemplateRepresentation> = z
  .object({
    id: z.string(),
    name: z.string(),
    description: z.string(),
    protocol: z.string(),
    fullScopeAllowed: z.boolean(),
    bearerOnly: z.boolean(),
    consentRequired: z.boolean(),
    standardFlowEnabled: z.boolean(),
    implicitFlowEnabled: z.boolean(),
    directAccessGrantsEnabled: z.boolean(),
    serviceAccountsEnabled: z.boolean(),
    publicClient: z.boolean(),
    frontchannelLogout: z.boolean(),
    attributes: z.record(z.string()),
    protocolMappers: z.array(ProtocolMapperRepresentation),
  })
  .partial()
  .passthrough();
const MultivaluedHashMapStringString: z.ZodType<MultivaluedHashMapStringString> = z.record(z.array(z.string()));
const ComponentExportRepresentation: z.ZodType<ComponentExportRepresentation> = z
  .object({
    id: z.string(),
    name: z.string(),
    providerId: z.string(),
    subType: z.string(),
    config: MultivaluedHashMapStringString,
  })
  .partial()
  .passthrough();
const CredentialRepresentation: z.ZodType<CredentialRepresentation> = z
  .object({
    id: z.string(),
    type: z.string(),
    userLabel: z.string(),
    createdDate: z.number().int(),
    secretData: z.string(),
    credentialData: z.string(),
    priority: z.number().int(),
    value: z.string(),
    temporary: z.boolean(),
    device: z.string(),
    hashedSaltedValue: z.string(),
    salt: z.string(),
    hashIterations: z.number().int(),
    counter: z.number().int(),
    algorithm: z.string(),
    digits: z.number().int(),
    period: z.number().int(),
    config: z.record(z.array(z.string())),
  })
  .partial()
  .passthrough();
const FederatedIdentityRepresentation: z.ZodType<FederatedIdentityRepresentation> = z
  .object({ identityProvider: z.string(), userId: z.string(), userName: z.string() })
  .partial()
  .passthrough();
const GroupRepresentation: z.ZodType<GroupRepresentation> = z
  .object({
    id: z.string(),
    name: z.string(),
    path: z.string(),
    parentId: z.string(),
    subGroupCount: z.number().int(),
    attributes: z.record(z.array(z.string())),
    realmRoles: z.array(z.string()),
    clientRoles: z.record(z.array(z.string())),
    access: z.record(z.boolean()),
  })
  .partial()
  .passthrough();
const IdentityProviderMapperRepresentation: z.ZodType<IdentityProviderMapperRepresentation> = z
  .object({
    id: z.string(),
    name: z.string(),
    identityProviderAlias: z.string(),
    identityProviderMapper: z.string(),
    config: z.record(z.string()),
  })
  .partial()
  .passthrough();
const IdentityProviderRepresentation: z.ZodType<IdentityProviderRepresentation> = z
  .object({
    alias: z.string(),
    displayName: z.string(),
    internalId: z.string(),
    providerId: z.string(),
    enabled: z.boolean(),
    updateProfileFirstLoginMode: z.string(),
    trustEmail: z.boolean(),
    storeToken: z.boolean(),
    addReadTokenRoleOnCreate: z.boolean(),
    authenticateByDefault: z.boolean(),
    linkOnly: z.boolean(),
    firstBrokerLoginFlowAlias: z.string(),
    postBrokerLoginFlowAlias: z.string(),
    config: z.record(z.string()),
    updateProfileFirstLogin: z.boolean(),
  })
  .partial()
  .passthrough();
const JsonNode: z.ZodType<JsonNode> = z.object({}).partial().passthrough();
const OAuthClientRepresentation: z.ZodType<OAuthClientRepresentation> = z
  .object({
    id: z.string(),
    clientId: z.string(),
    name: z.string(),
    description: z.string(),
    rootUrl: z.string(),
    adminUrl: z.string(),
    baseUrl: z.string(),
    surrogateAuthRequired: z.boolean(),
    enabled: z.boolean(),
    alwaysDisplayInConsole: z.boolean(),
    clientAuthenticatorType: z.string(),
    secret: z.string(),
    registrationAccessToken: z.string(),
    defaultRoles: z.array(z.string()),
    redirectUris: z.array(z.string()),
    webOrigins: z.array(z.string()),
    notBefore: z.number().int(),
    bearerOnly: z.boolean(),
    consentRequired: z.boolean(),
    standardFlowEnabled: z.boolean(),
    implicitFlowEnabled: z.boolean(),
    directAccessGrantsEnabled: z.boolean(),
    serviceAccountsEnabled: z.boolean(),
    authorizationServicesEnabled: z.boolean(),
    directGrantsOnly: z.boolean(),
    publicClient: z.boolean(),
    frontchannelLogout: z.boolean(),
    protocol: z.string(),
    attributes: z.record(z.string()),
    authenticationFlowBindingOverrides: z.record(z.string()),
    fullScopeAllowed: z.boolean(),
    nodeReRegistrationTimeout: z.number().int(),
    registeredNodes: z.record(z.number().int()),
    protocolMappers: z.array(ProtocolMapperRepresentation),
    clientTemplate: z.string(),
    useTemplateConfig: z.boolean(),
    useTemplateScope: z.boolean(),
    useTemplateMappers: z.boolean(),
    defaultClientScopes: z.array(z.string()),
    optionalClientScopes: z.array(z.string()),
    authorizationSettings: ResourceServerRepresentation,
    access: z.record(z.boolean()),
    origin: z.string(),
    claims: ClaimRepresentation,
  })
  .partial()
  .passthrough();
const RolesRepresentation: z.ZodType<RolesRepresentation> = z
  .object({
    realm: z.array(RoleRepresentation),
    client: z.record(z.array(RoleRepresentation)),
    application: z.record(z.array(RoleRepresentation)),
  })
  .partial()
  .passthrough();
const UserProfileAttributeMetadata: z.ZodType<UserProfileAttributeMetadata> = z
  .object({
    name: z.string(),
    displayName: z.string(),
    required: z.boolean(),
    readOnly: z.boolean(),
    annotations: z.record(z.object({}).partial().passthrough()),
    validators: z.record(z.record(z.object({}).partial().passthrough())),
    group: z.string(),
    multivalued: z.boolean(),
  })
  .partial()
  .passthrough();
const UserProfileAttributeGroupMetadata: z.ZodType<UserProfileAttributeGroupMetadata> = z
  .object({
    name: z.string(),
    displayHeader: z.string(),
    displayDescription: z.string(),
    annotations: z.record(z.object({}).partial().passthrough()),
  })
  .partial()
  .passthrough();
const UserProfileMetadata: z.ZodType<UserProfileMetadata> = z
  .object({ attributes: z.array(UserProfileAttributeMetadata), groups: z.array(UserProfileAttributeGroupMetadata) })
  .partial()
  .passthrough();
const UserConsentRepresentation: z.ZodType<UserConsentRepresentation> = z
  .object({
    clientId: z.string(),
    grantedClientScopes: z.array(z.string()),
    createdDate: z.number().int(),
    lastUpdatedDate: z.number().int(),
    grantedRealmRoles: z.array(z.string()),
  })
  .partial()
  .passthrough();
const SocialLinkRepresentation: z.ZodType<SocialLinkRepresentation> = z
  .object({ socialProvider: z.string(), socialUserId: z.string(), socialUsername: z.string() })
  .partial()
  .passthrough();
const UserRepresentation: z.ZodType<UserRepresentation> = z
  .object({
    id: z.string(),
    username: z.string(),
    firstName: z.string(),
    lastName: z.string(),
    email: z.string(),
    emailVerified: z.boolean(),
    attributes: z.record(z.array(z.string())),
    userProfileMetadata: UserProfileMetadata,
    self: z.string(),
    origin: z.string(),
    createdTimestamp: z.number().int(),
    enabled: z.boolean(),
    totp: z.boolean(),
    federationLink: z.string(),
    serviceAccountClientId: z.string(),
    credentials: z.array(CredentialRepresentation),
    disableableCredentialTypes: z.array(z.string()),
    requiredActions: z.array(z.string()),
    federatedIdentities: z.array(FederatedIdentityRepresentation),
    realmRoles: z.array(z.string()),
    clientRoles: z.record(z.array(z.string())),
    clientConsents: z.array(UserConsentRepresentation),
    notBefore: z.number().int(),
    applicationRoles: z.record(z.array(z.string())),
    socialLinks: z.array(SocialLinkRepresentation),
    groups: z.array(z.string()),
    access: z.record(z.boolean()),
  })
  .partial()
  .passthrough();
const ScopeMappingRepresentation: z.ZodType<ScopeMappingRepresentation> = z
  .object({
    self: z.string(),
    client: z.string(),
    clientTemplate: z.string(),
    clientScope: z.string(),
    roles: z.array(z.string()),
  })
  .partial()
  .passthrough();
const UserFederationProviderRepresentation: z.ZodType<UserFederationProviderRepresentation> = z
  .object({
    id: z.string(),
    displayName: z.string(),
    providerName: z.string(),
    config: z.record(z.string()),
    priority: z.number().int(),
    fullSyncPeriod: z.number().int(),
    changedSyncPeriod: z.number().int(),
    lastSync: z.number().int(),
  })
  .partial()
  .passthrough();
const UserFederationMapperRepresentation: z.ZodType<UserFederationMapperRepresentation> = z
  .object({
    id: z.string(),
    name: z.string(),
    federationProviderDisplayName: z.string(),
    federationMapperType: z.string(),
    config: z.record(z.string()),
  })
  .partial()
  .passthrough();
const RequiredActionProviderRepresentation: z.ZodType<RequiredActionProviderRepresentation> = z
  .object({
    alias: z.string(),
    name: z.string(),
    providerId: z.string(),
    enabled: z.boolean(),
    defaultAction: z.boolean(),
    priority: z.number().int(),
    config: z.record(z.string()),
  })
  .partial()
  .passthrough();
const RealmRepresentation: z.ZodType<RealmRepresentation> = z
  .object({
    id: z.string(),
    realm: z.string(),
    displayName: z.string(),
    displayNameHtml: z.string(),
    notBefore: z.number().int(),
    defaultSignatureAlgorithm: z.string(),
    revokeRefreshToken: z.boolean(),
    refreshTokenMaxReuse: z.number().int(),
    accessTokenLifespan: z.number().int(),
    accessTokenLifespanForImplicitFlow: z.number().int(),
    ssoSessionIdleTimeout: z.number().int(),
    ssoSessionMaxLifespan: z.number().int(),
    ssoSessionIdleTimeoutRememberMe: z.number().int(),
    ssoSessionMaxLifespanRememberMe: z.number().int(),
    offlineSessionIdleTimeout: z.number().int(),
    offlineSessionMaxLifespanEnabled: z.boolean(),
    offlineSessionMaxLifespan: z.number().int(),
    clientSessionIdleTimeout: z.number().int(),
    clientSessionMaxLifespan: z.number().int(),
    clientOfflineSessionIdleTimeout: z.number().int(),
    clientOfflineSessionMaxLifespan: z.number().int(),
    accessCodeLifespan: z.number().int(),
    accessCodeLifespanUserAction: z.number().int(),
    accessCodeLifespanLogin: z.number().int(),
    actionTokenGeneratedByAdminLifespan: z.number().int(),
    actionTokenGeneratedByUserLifespan: z.number().int(),
    oauth2DeviceCodeLifespan: z.number().int(),
    oauth2DevicePollingInterval: z.number().int(),
    enabled: z.boolean(),
    sslRequired: z.string(),
    passwordCredentialGrantAllowed: z.boolean(),
    registrationAllowed: z.boolean(),
    registrationEmailAsUsername: z.boolean(),
    rememberMe: z.boolean(),
    verifyEmail: z.boolean(),
    loginWithEmailAllowed: z.boolean(),
    duplicateEmailsAllowed: z.boolean(),
    resetPasswordAllowed: z.boolean(),
    editUsernameAllowed: z.boolean(),
    bruteForceProtected: z.boolean(),
    permanentLockout: z.boolean(),
    maxTemporaryLockouts: z.number().int(),
    maxFailureWaitSeconds: z.number().int(),
    minimumQuickLoginWaitSeconds: z.number().int(),
    waitIncrementSeconds: z.number().int(),
    quickLoginCheckMilliSeconds: z.number().int(),
    maxDeltaTimeSeconds: z.number().int(),
    failureFactor: z.number().int(),
    privateKey: z.string(),
    publicKey: z.string(),
    certificate: z.string(),
    codeSecret: z.string(),
    roles: RolesRepresentation,
    groups: z.array(GroupRepresentation),
    defaultRoles: z.array(z.string()),
    defaultRole: RoleRepresentation,
    defaultGroups: z.array(z.string()),
    requiredCredentials: z.array(z.string()),
    passwordPolicy: z.string(),
    otpPolicyType: z.string(),
    otpPolicyAlgorithm: z.string(),
    otpPolicyInitialCounter: z.number().int(),
    otpPolicyDigits: z.number().int(),
    otpPolicyLookAheadWindow: z.number().int(),
    otpPolicyPeriod: z.number().int(),
    otpPolicyCodeReusable: z.boolean(),
    otpSupportedApplications: z.array(z.string()),
    localizationTexts: z.record(z.record(z.string())),
    webAuthnPolicyRpEntityName: z.string(),
    webAuthnPolicySignatureAlgorithms: z.array(z.string()),
    webAuthnPolicyRpId: z.string(),
    webAuthnPolicyAttestationConveyancePreference: z.string(),
    webAuthnPolicyAuthenticatorAttachment: z.string(),
    webAuthnPolicyRequireResidentKey: z.string(),
    webAuthnPolicyUserVerificationRequirement: z.string(),
    webAuthnPolicyCreateTimeout: z.number().int(),
    webAuthnPolicyAvoidSameAuthenticatorRegister: z.boolean(),
    webAuthnPolicyAcceptableAaguids: z.array(z.string()),
    webAuthnPolicyExtraOrigins: z.array(z.string()),
    webAuthnPolicyPasswordlessRpEntityName: z.string(),
    webAuthnPolicyPasswordlessSignatureAlgorithms: z.array(z.string()),
    webAuthnPolicyPasswordlessRpId: z.string(),
    webAuthnPolicyPasswordlessAttestationConveyancePreference: z.string(),
    webAuthnPolicyPasswordlessAuthenticatorAttachment: z.string(),
    webAuthnPolicyPasswordlessRequireResidentKey: z.string(),
    webAuthnPolicyPasswordlessUserVerificationRequirement: z.string(),
    webAuthnPolicyPasswordlessCreateTimeout: z.number().int(),
    webAuthnPolicyPasswordlessAvoidSameAuthenticatorRegister: z.boolean(),
    webAuthnPolicyPasswordlessAcceptableAaguids: z.array(z.string()),
    webAuthnPolicyPasswordlessExtraOrigins: z.array(z.string()),
    users: z.array(UserRepresentation),
    federatedUsers: z.array(UserRepresentation),
    scopeMappings: z.array(ScopeMappingRepresentation),
    clientScopeMappings: z.record(z.array(ScopeMappingRepresentation)),
    clients: z.array(ClientRepresentation),
    clientScopes: z.array(ClientScopeRepresentation),
    defaultDefaultClientScopes: z.array(z.string()),
    defaultOptionalClientScopes: z.array(z.string()),
    browserSecurityHeaders: z.record(z.string()),
    smtpServer: z.record(z.string()),
    userFederationProviders: z.array(UserFederationProviderRepresentation),
    userFederationMappers: z.array(UserFederationMapperRepresentation),
    loginTheme: z.string(),
    accountTheme: z.string(),
    adminTheme: z.string(),
    emailTheme: z.string(),
    eventsEnabled: z.boolean(),
    eventsExpiration: z.number().int(),
    eventsListeners: z.array(z.string()),
    enabledEventTypes: z.array(z.string()),
    adminEventsEnabled: z.boolean(),
    adminEventsDetailsEnabled: z.boolean(),
    identityProviders: z.array(IdentityProviderRepresentation),
    identityProviderMappers: z.array(IdentityProviderMapperRepresentation),
    protocolMappers: z.array(ProtocolMapperRepresentation),
    components: z.record(z.array(ComponentExportRepresentation)),
    internationalizationEnabled: z.boolean(),
    supportedLocales: z.array(z.string()),
    defaultLocale: z.string(),
    authenticationFlows: z.array(AuthenticationFlowRepresentation),
    authenticatorConfig: z.array(AuthenticatorConfigRepresentation),
    requiredActions: z.array(RequiredActionProviderRepresentation),
    browserFlow: z.string(),
    registrationFlow: z.string(),
    directGrantFlow: z.string(),
    resetCredentialsFlow: z.string(),
    clientAuthenticationFlow: z.string(),
    dockerAuthenticationFlow: z.string(),
    firstBrokerLoginFlow: z.string(),
    attributes: z.record(z.string()),
    keycloakVersion: z.string(),
    userManagedAccessAllowed: z.boolean(),
    social: z.boolean(),
    updateProfileOnInitialSocialLogin: z.boolean(),
    socialProviders: z.record(z.string()),
    applicationScopeMappings: z.record(z.array(ScopeMappingRepresentation)),
    applications: z.array(ApplicationRepresentation),
    oauthClients: z.array(OAuthClientRepresentation),
    clientTemplates: z.array(ClientTemplateRepresentation),
    clientProfiles: JsonNode,
    clientPolicies: JsonNode,
  })
  .partial()
  .passthrough();

export const schemas = {
  TenantDefaultUserPermissionsDto,
  TenantDto,
  UpdateTenantRequest,
  CreateTenantRequest,
  TaxonomyDto,
  TaxonomyNodeDto,
  PermissionDto,
  AssetGroupPermissionsDto,
  TaxonomyPermissionDto,
  Composites,
  RoleRepresentation,
  CreateUserRequest,
  UserDto,
  KeycloakUserSearch,
  TspDto,
  TspSecret,
  TspRequestTspSecret,
  CalampSecret,
  CalAmpRequest,
  GeotabSecret,
  GeoTabRequest,
  PlatSciSecret,
  PlatSciRequest,
  SamsaraSecret,
  SamsaraRequest,
  createTsp_Body,
  IdentityProviderMapper,
  CreateIdentityProviderRequest,
  UpdateUserRequest,
  SortObject,
  PageableObject,
  PageUserDto,
  UserEventDto,
  ProtocolMapperRepresentation,
  ResourceOwnerRepresentation,
  PolicyRepresentation,
  ScopeRepresentation,
  ResourceRepresentation,
  ResourceServerRepresentation,
  ClaimRepresentation,
  ApplicationRepresentation,
  AuthenticationExecutionExportRepresentation,
  AuthenticationFlowRepresentation,
  AuthenticatorConfigRepresentation,
  ClientRepresentation,
  ClientScopeRepresentation,
  ClientTemplateRepresentation,
  MultivaluedHashMapStringString,
  ComponentExportRepresentation,
  CredentialRepresentation,
  FederatedIdentityRepresentation,
  GroupRepresentation,
  IdentityProviderMapperRepresentation,
  IdentityProviderRepresentation,
  JsonNode,
  OAuthClientRepresentation,
  RolesRepresentation,
  UserProfileAttributeMetadata,
  UserProfileAttributeGroupMetadata,
  UserProfileMetadata,
  UserConsentRepresentation,
  SocialLinkRepresentation,
  UserRepresentation,
  ScopeMappingRepresentation,
  UserFederationProviderRepresentation,
  UserFederationMapperRepresentation,
  RequiredActionProviderRepresentation,
  RealmRepresentation,
};

const tenant_management_controllerEndpoints = makeApi([
  {
    method: 'get',
    path: '/v1/tenants/:tenantId',
    alias: 'findTenantById',
    requestFormat: 'json',
    parameters: [
      {
        name: 'tenantId',
        type: 'Path',
        schema: z.string(),
      },
    ],
    response: TenantDto,
    errors: [
      {
        status: 403,
        description: `Jwt token has invalid sub claim`,
        schema: z.void(),
      },
      {
        status: 404,
        description: `If the tenant is not found in keycloak`,
        schema: z.void(),
      },
    ],
  },
  {
    method: 'put',
    path: '/v1/tenants/:tenantId',
    alias: 'updateTenant',
    requestFormat: 'json',
    parameters: [
      {
        name: 'body',
        type: 'Body',
        schema: UpdateTenantRequest,
      },
      {
        name: 'tenantId',
        type: 'Path',
        schema: z.string(),
      },
    ],
    response: TenantDto,
    errors: [
      {
        status: 403,
        description: `Jwt token has invalid sub claim`,
        schema: z.void(),
      },
    ],
  },
  {
    method: 'post',
    path: '/v1/tenants/:tenantId',
    alias: 'createTenant',
    requestFormat: 'json',
    parameters: [
      {
        name: 'body',
        type: 'Body',
        schema: CreateTenantRequest,
      },
      {
        name: 'tenantId',
        type: 'Path',
        schema: z.string(),
      },
    ],
    response: TenantDto,
    errors: [
      {
        status: 403,
        description: `Jwt token has invalid sub claim`,
        schema: z.void(),
      },
    ],
  },
  {
    method: 'delete',
    path: '/v1/tenants/:tenantId',
    alias: 'deleteTenant',
    requestFormat: 'json',
    parameters: [
      {
        name: 'tenantId',
        type: 'Path',
        schema: z.string(),
      },
      {
        name: 'permanently',
        type: 'Query',
        schema: z.boolean().optional().default(false),
      },
    ],
    response: z.void(),
    errors: [
      {
        status: 403,
        description: `Jwt token has invalid sub claim`,
        schema: z.void(),
      },
    ],
  },
  {
    method: 'post',
    path: '/v1/tenants/:tenantId/identity-provider',
    alias: 'createIdentityProvider',
    requestFormat: 'json',
    parameters: [
      {
        name: 'body',
        type: 'Body',
        schema: CreateIdentityProviderRequest,
      },
      {
        name: 'tenantId',
        type: 'Path',
        schema: z.string(),
      },
    ],
    response: z.void(),
    errors: [
      {
        status: 403,
        description: `Jwt token has invalid sub claim`,
        schema: z.void(),
      },
    ],
  },
  {
    method: 'get',
    path: '/v1/tenants',
    alias: 'findAllTenants',
    requestFormat: 'json',
    response: z.array(TenantDto),
  },
]);

export const tenant_management_controllerApi = new Zodios(tenant_management_controllerEndpoints);

const user_management_controllerEndpoints = makeApi([
  {
    method: 'post',
    path: '/v1/tenants/:tenantId/users',
    alias: 'createUser',
    requestFormat: 'json',
    parameters: [
      {
        name: 'body',
        type: 'Body',
        schema: CreateUserRequest,
      },
      {
        name: 'tenantId',
        type: 'Path',
        schema: z.string(),
      },
    ],
    response: TenantDto,
    errors: [
      {
        status: 403,
        description: `Jwt token has invalid sub claim`,
        schema: UserDto,
      },
      {
        status: 404,
        description: `Tenant not found in Keycloak`,
        schema: UserDto,
      },
      {
        status: 409,
        description: `Username is not unique`,
        schema: UserDto,
      },
    ],
  },
  {
    method: 'post',
    path: '/v1/tenants/:tenantId/users/search',
    alias: 'searchUsersWithTenantId',
    requestFormat: 'json',
    parameters: [
      {
        name: 'body',
        type: 'Body',
        schema: KeycloakUserSearch,
      },
      {
        name: 'tenantId',
        type: 'Path',
        schema: z.string(),
      },
      {
        name: 'page',
        type: 'Query',
        schema: z
          .number()
          .int()
          .describe('Zero-based page index (0..N) (page ResponseFormat only)')
          .optional()
          .default(0),
      },
      {
        name: 'size',
        type: 'Query',
        schema: z
          .number()
          .int()
          .describe('The size of the page to be returned (page ResponseFormat only)')
          .optional()
          .default(20),
      },
    ],
    response: z.void(),
    errors: [
      {
        status: 403,
        description: `Jwt token has invalid sub claim`,
        schema: z.void(),
      },
      {
        status: 404,
        description: `Tenant not found in Keycloak`,
        schema: z.void(),
      },
    ],
  },
  {
    method: 'get',
    path: '/v1/tenants/:tenantId/users/id/:userId',
    alias: 'getUserById',
    requestFormat: 'json',
    parameters: [
      {
        name: 'tenantId',
        type: 'Path',
        schema: z.string(),
      },
      {
        name: 'userId',
        type: 'Path',
        schema: z.string().uuid(),
      },
    ],
    response: UserDto,
    errors: [
      {
        status: 403,
        description: `Jwt token has invalid sub claim`,
        schema: z.void(),
      },
      {
        status: 404,
        description: `Tenant not found in Keycloak`,
        schema: z.void(),
      },
    ],
  },
  {
    method: 'delete',
    path: '/v1/tenants/:tenantId/users/id/:userId',
    alias: 'disableUser',
    requestFormat: 'json',
    parameters: [
      {
        name: 'tenantId',
        type: 'Path',
        schema: z.string(),
      },
      {
        name: 'userId',
        type: 'Path',
        schema: z.string().uuid(),
      },
    ],
    response: z.string(),
    errors: [
      {
        status: 403,
        description: `Jwt token has invalid sub claim`,
        schema: z.void(),
      },
    ],
  },
  {
    method: 'patch',
    path: '/v1/tenants/:tenantId/users/id/:userId',
    alias: 'updateUser',
    requestFormat: 'json',
    parameters: [
      {
        name: 'body',
        type: 'Body',
        schema: UpdateUserRequest,
      },
      {
        name: 'tenantId',
        type: 'Path',
        schema: z.string(),
      },
      {
        name: 'userId',
        type: 'Path',
        schema: z.string().uuid(),
      },
    ],
    response: UserDto,
    errors: [
      {
        status: 403,
        description: `Jwt token has invalid sub claim`,
        schema: z.void(),
      },
      {
        status: 404,
        description: `Tenant not found in Keycloak`,
        schema: z.void(),
      },
    ],
  },
  {
    method: 'get',
    path: '/v2/tenants/:tenantId/users',
    alias: 'getUsersPageByTenantIdAndEnabledCriteria_1',
    requestFormat: 'json',
    parameters: [
      {
        name: 'tenantId',
        type: 'Path',
        schema: z.string(),
      },
      {
        name: 'responseFormat',
        type: 'Query',
        schema: z.enum(['list', 'page', 'stream']),
      },
      {
        name: 'enabledOnly',
        type: 'Query',
        schema: z.boolean().optional().default(true),
      },
      {
        name: 'page',
        type: 'Query',
        schema: z
          .number()
          .int()
          .describe('Zero-based page index (0..N) (page ResponseFormat only)')
          .optional()
          .default(0),
      },
      {
        name: 'size',
        type: 'Query',
        schema: z
          .number()
          .int()
          .describe('The size of the page to be returned (page ResponseFormat only)')
          .optional()
          .default(20),
      },
    ],
    response: z.union([z.array(UserDto), PageUserDto]),
    errors: [
      {
        status: 403,
        description: `Jwt token has invalid sub claim`,
        schema: PageUserDto,
      },
      {
        status: 404,
        description: `Tenant not found in Keycloak`,
        schema: PageUserDto,
      },
    ],
  },
  {
    method: 'get',
    path: '/v2/tenants/:tenantId/users/accesslevels',
    alias: 'getUsersPageByTenantIdAndAccessLevelNames',
    requestFormat: 'json',
    parameters: [
      {
        name: 'tenantId',
        type: 'Path',
        schema: z.string(),
      },
      {
        name: 'accessLevelNames',
        type: 'Query',
        schema: z.array(z.string()),
      },
      {
        name: 'page',
        type: 'Query',
        schema: z
          .number()
          .int()
          .describe('Zero-based page index (0..N) (page ResponseFormat only)')
          .optional()
          .default(0),
      },
      {
        name: 'size',
        type: 'Query',
        schema: z
          .number()
          .int()
          .describe('The size of the page to be returned (page ResponseFormat only)')
          .optional()
          .default(20),
      },
    ],
    response: z.void(),
  },
  {
    method: 'get',
    path: '/v2/tenants/:tenantId/roles',
    alias: 'getTenantUserRolesV2',
    requestFormat: 'json',
    parameters: [
      {
        name: 'tenantId',
        type: 'Path',
        schema: z.string(),
      },
    ],
    response: z.array(RoleRepresentation),
    errors: [
      {
        status: 403,
        description: `Jwt token has invalid sub claim`,
        schema: z.void(),
      },
    ],
  },
  {
    method: 'get',
    path: '/v2/tenants/:tenantId/accesslevels',
    alias: 'getTenantUserAccessLevelsV2',
    requestFormat: 'json',
    parameters: [
      {
        name: 'tenantId',
        type: 'Path',
        schema: z.string(),
      },
    ],
    response: z.array(RoleRepresentation),
    errors: [
      {
        status: 403,
        description: `Jwt token has invalid sub claim`,
        schema: z.void(),
      },
    ],
  },
  {
    method: 'get',
    path: '/v1/tenants/:tenantId/users/username/:username',
    alias: 'getUserByUsername',
    requestFormat: 'json',
    parameters: [
      {
        name: 'tenantId',
        type: 'Path',
        schema: z.string(),
      },
      {
        name: 'username',
        type: 'Path',
        schema: z.string(),
      },
    ],
    response: UserDto,
    errors: [
      {
        status: 403,
        description: `Jwt token has invalid sub claim`,
        schema: z.void(),
      },
      {
        status: 404,
        description: `Tenant not found in Keycloak`,
        schema: z.void(),
      },
    ],
  },
  {
    method: 'get',
    path: '/v1/tenants/:tenantId/users/events',
    alias: 'getTenantUserEvents',
    requestFormat: 'json',
    parameters: [
      {
        name: 'tenantId',
        type: 'Path',
        schema: z.string(),
      },
      {
        name: 'eventType',
        type: 'Query',
        schema: z
          .enum([
            'LOGIN',
            'LOGIN_ERROR',
            'REGISTER',
            'REGISTER_ERROR',
            'LOGOUT',
            'LOGOUT_ERROR',
            'CODE_TO_TOKEN',
            'CODE_TO_TOKEN_ERROR',
            'CLIENT_LOGIN',
            'CLIENT_LOGIN_ERROR',
            'REFRESH_TOKEN',
            'REFRESH_TOKEN_ERROR',
            'VALIDATE_ACCESS_TOKEN',
            'VALIDATE_ACCESS_TOKEN_ERROR',
            'INTROSPECT_TOKEN',
            'INTROSPECT_TOKEN_ERROR',
            'FEDERATED_IDENTITY_LINK',
            'FEDERATED_IDENTITY_LINK_ERROR',
            'REMOVE_FEDERATED_IDENTITY',
            'REMOVE_FEDERATED_IDENTITY_ERROR',
            'UPDATE_EMAIL',
            'UPDATE_EMAIL_ERROR',
            'UPDATE_PROFILE',
            'UPDATE_PROFILE_ERROR',
            'UPDATE_PASSWORD',
            'UPDATE_PASSWORD_ERROR',
            'UPDATE_TOTP',
            'UPDATE_TOTP_ERROR',
            'VERIFY_EMAIL',
            'VERIFY_EMAIL_ERROR',
            'VERIFY_PROFILE',
            'VERIFY_PROFILE_ERROR',
            'REMOVE_TOTP',
            'REMOVE_TOTP_ERROR',
            'GRANT_CONSENT',
            'GRANT_CONSENT_ERROR',
            'UPDATE_CONSENT',
            'UPDATE_CONSENT_ERROR',
            'REVOKE_GRANT',
            'REVOKE_GRANT_ERROR',
            'SEND_VERIFY_EMAIL',
            'SEND_VERIFY_EMAIL_ERROR',
            'SEND_RESET_PASSWORD',
            'SEND_RESET_PASSWORD_ERROR',
            'SEND_IDENTITY_PROVIDER_LINK',
            'SEND_IDENTITY_PROVIDER_LINK_ERROR',
            'RESET_PASSWORD',
            'RESET_PASSWORD_ERROR',
            'RESTART_AUTHENTICATION',
            'RESTART_AUTHENTICATION_ERROR',
            'INVALID_SIGNATURE',
            'INVALID_SIGNATURE_ERROR',
            'REGISTER_NODE',
            'REGISTER_NODE_ERROR',
            'UNREGISTER_NODE',
            'UNREGISTER_NODE_ERROR',
            'USER_INFO_REQUEST',
            'USER_INFO_REQUEST_ERROR',
            'IDENTITY_PROVIDER_LINK_ACCOUNT',
            'IDENTITY_PROVIDER_LINK_ACCOUNT_ERROR',
            'IDENTITY_PROVIDER_LOGIN',
            'IDENTITY_PROVIDER_LOGIN_ERROR',
            'IDENTITY_PROVIDER_FIRST_LOGIN',
            'IDENTITY_PROVIDER_FIRST_LOGIN_ERROR',
            'IDENTITY_PROVIDER_POST_LOGIN',
            'IDENTITY_PROVIDER_POST_LOGIN_ERROR',
            'IDENTITY_PROVIDER_RESPONSE',
            'IDENTITY_PROVIDER_RESPONSE_ERROR',
            'IDENTITY_PROVIDER_RETRIEVE_TOKEN',
            'IDENTITY_PROVIDER_RETRIEVE_TOKEN_ERROR',
            'IMPERSONATE',
            'IMPERSONATE_ERROR',
            'CUSTOM_REQUIRED_ACTION',
            'CUSTOM_REQUIRED_ACTION_ERROR',
            'EXECUTE_ACTIONS',
            'EXECUTE_ACTIONS_ERROR',
            'EXECUTE_ACTION_TOKEN',
            'EXECUTE_ACTION_TOKEN_ERROR',
            'CLIENT_INFO',
            'CLIENT_INFO_ERROR',
            'CLIENT_REGISTER',
            'CLIENT_REGISTER_ERROR',
            'CLIENT_UPDATE',
            'CLIENT_UPDATE_ERROR',
            'CLIENT_DELETE',
            'CLIENT_DELETE_ERROR',
            'CLIENT_INITIATED_ACCOUNT_LINKING',
            'CLIENT_INITIATED_ACCOUNT_LINKING_ERROR',
            'TOKEN_EXCHANGE',
            'TOKEN_EXCHANGE_ERROR',
            'OAUTH2_DEVICE_AUTH',
            'OAUTH2_DEVICE_AUTH_ERROR',
            'OAUTH2_DEVICE_VERIFY_USER_CODE',
            'OAUTH2_DEVICE_VERIFY_USER_CODE_ERROR',
            'OAUTH2_DEVICE_CODE_TO_TOKEN',
            'OAUTH2_DEVICE_CODE_TO_TOKEN_ERROR',
            'AUTHREQID_TO_TOKEN',
            'AUTHREQID_TO_TOKEN_ERROR',
            'PERMISSION_TOKEN',
            'PERMISSION_TOKEN_ERROR',
            'DELETE_ACCOUNT',
            'DELETE_ACCOUNT_ERROR',
            'PUSHED_AUTHORIZATION_REQUEST',
            'PUSHED_AUTHORIZATION_REQUEST_ERROR',
            'USER_DISABLED_BY_PERMANENT_LOCKOUT',
            'USER_DISABLED_BY_PERMANENT_LOCKOUT_ERROR',
            'USER_DISABLED_BY_TEMPORARY_LOCKOUT',
            'USER_DISABLED_BY_TEMPORARY_LOCKOUT_ERROR',
            'OAUTH2_EXTENSION_GRANT',
            'OAUTH2_EXTENSION_GRANT_ERROR',
          ])
          .optional(),
      },
    ],
    response: z.array(UserEventDto),
    errors: [
      {
        status: 403,
        description: `Jwt token has invalid sub claim`,
        schema: z.void(),
      },
      {
        status: 404,
        description: `User&#x27;s events not found in Keycloak for the given tenant`,
        schema: z.void(),
      },
    ],
  },
]);

export const user_management_controllerApi = new Zodios(user_management_controllerEndpoints);

const tsp_management_controllerEndpoints = makeApi([
  {
    method: 'get',
    path: '/v1/tenants/:tenantId/tsps',
    alias: 'getTspsByTenant',
    requestFormat: 'json',
    parameters: [
      {
        name: 'tenantId',
        type: 'Path',
        schema: z.string(),
      },
    ],
    response: z.array(TspDto),
    errors: [
      {
        status: 403,
        description: `Jwt token has invalid sub claim`,
        schema: z.void(),
      },
    ],
  },
  {
    method: 'post',
    path: '/v1/tenants/:tenantId/tsps',
    alias: 'createTsp',
    requestFormat: 'json',
    parameters: [
      {
        name: 'body',
        type: 'Body',
        schema: createTsp_Body,
      },
      {
        name: 'tenantId',
        type: 'Path',
        schema: z.string(),
      },
    ],
    response: z.string(),
    errors: [
      {
        status: 403,
        description: `Jwt token has invalid sub claim`,
        schema: z.void(),
      },
    ],
  },
  {
    method: 'patch',
    path: '/v1/tenants/:tenantId/tsps/:configurationId',
    alias: 'updateTsp',
    requestFormat: 'json',
    parameters: [
      {
        name: 'body',
        type: 'Body',
        schema: createTsp_Body,
      },
      {
        name: 'tenantId',
        type: 'Path',
        schema: z.string(),
      },
      {
        name: 'configurationId',
        type: 'Path',
        schema: z.string().uuid(),
      },
    ],
    response: z.string(),
    errors: [
      {
        status: 403,
        description: `Jwt token has invalid sub claim`,
        schema: z.void(),
      },
    ],
  },
  {
    method: 'get',
    path: '/v1/tenants/:tenantId/tsps/:tspType',
    alias: 'getTspsByTenantAndTspType',
    requestFormat: 'json',
    parameters: [
      {
        name: 'tenantId',
        type: 'Path',
        schema: z.string(),
      },
      {
        name: 'tspType',
        type: 'Path',
        schema: z.string(),
      },
    ],
    response: z.void(),
    errors: [
      {
        status: 403,
        description: `Jwt token has invalid sub claim`,
        schema: z.void(),
      },
      {
        status: 404,
        description: `If no Tsp details are found for the supplied tenant and tsp type`,
        schema: z.void(),
      },
    ],
  },
  {
    method: 'delete',
    path: '/v1/tenants/:tenantId/tsps/:tspType/:connectorName/:configurationId',
    alias: 'deleteTspWithConnectorName',
    requestFormat: 'json',
    parameters: [
      {
        name: 'tenantId',
        type: 'Path',
        schema: z.string(),
      },
      {
        name: 'tspType',
        type: 'Path',
        schema: z.string(),
      },
      {
        name: 'connectorName',
        type: 'Path',
        schema: z.string(),
      },
      {
        name: 'configurationId',
        type: 'Path',
        schema: z.string().uuid(),
      },
    ],
    response: z.string(),
    errors: [
      {
        status: 403,
        description: `Jwt token has invalid sub claim`,
        schema: z.void(),
      },
    ],
  },
  {
    method: 'delete',
    path: '/v1/tenants/:tenantId/tsps/:tspType/:configurationId',
    alias: 'deleteTsp',
    requestFormat: 'json',
    parameters: [
      {
        name: 'tenantId',
        type: 'Path',
        schema: z.string(),
      },
      {
        name: 'tspType',
        type: 'Path',
        schema: z.string(),
      },
      {
        name: 'configurationId',
        type: 'Path',
        schema: z.string().uuid(),
      },
    ],
    response: z.string(),
    errors: [
      {
        status: 403,
        description: `Jwt token has invalid sub claim`,
        schema: z.void(),
      },
    ],
  },
]);

export const tsp_management_controllerApi = new Zodios(tsp_management_controllerEndpoints);

const customer_tsp_management_controllerEndpoints = makeApi([
  {
    method: 'get',
    path: '/v1/tenants/tsps',
    alias: 'getTspsByTenant_1',
    requestFormat: 'json',
    response: z.array(TspDto),
    errors: [
      {
        status: 403,
        description: `Jwt token has invalid sub claim`,
        schema: z.void(),
      },
    ],
  },
  {
    method: 'post',
    path: '/v1/tenants/tsps',
    alias: 'createTsp_1',
    requestFormat: 'json',
    parameters: [
      {
        name: 'body',
        type: 'Body',
        schema: createTsp_Body,
      },
    ],
    response: z.string(),
    errors: [
      {
        status: 403,
        description: `Jwt token has invalid sub claim`,
        schema: z.void(),
      },
    ],
  },
  {
    method: 'patch',
    path: '/v1/tenants/tsps/:configurationId',
    alias: 'updateTsp_1',
    requestFormat: 'json',
    parameters: [
      {
        name: 'body',
        type: 'Body',
        schema: createTsp_Body,
      },
      {
        name: 'configurationId',
        type: 'Path',
        schema: z.string().uuid(),
      },
    ],
    response: z.string(),
    errors: [
      {
        status: 403,
        description: `Jwt token has invalid sub claim`,
        schema: z.void(),
      },
    ],
  },
  {
    method: 'get',
    path: '/v1/tenants/tsps/:tspType',
    alias: 'getTspsByTenantAndTspType_1',
    requestFormat: 'json',
    parameters: [
      {
        name: 'tspType',
        type: 'Path',
        schema: z.string(),
      },
    ],
    response: z.void(),
    errors: [
      {
        status: 403,
        description: `Jwt token has invalid sub claim`,
        schema: z.void(),
      },
      {
        status: 404,
        description: `If no Tsp details are found for the supplied tenant and tsp type`,
        schema: z.void(),
      },
    ],
  },
]);

export const customer_tsp_management_controllerApi = new Zodios(customer_tsp_management_controllerEndpoints);

const customer_user_management_controllerEndpoints = makeApi([
  {
    method: 'post',
    path: '/v1/tenant/users',
    alias: 'createUser_1',
    requestFormat: 'json',
    parameters: [
      {
        name: 'body',
        type: 'Body',
        schema: CreateUserRequest,
      },
    ],
    response: UserDto,
    errors: [
      {
        status: 403,
        description: `Jwt token has invalid sub claim`,
        schema: z.void(),
      },
      {
        status: 409,
        description: `Username is not unique`,
        schema: z.void(),
      },
    ],
  },
  {
    method: 'post',
    path: '/v1/tenant/users/search',
    alias: 'searchCustomerUsers',
    requestFormat: 'json',
    parameters: [
      {
        name: 'body',
        type: 'Body',
        schema: KeycloakUserSearch,
      },
      {
        name: 'page',
        type: 'Query',
        schema: z
          .number()
          .int()
          .describe('Zero-based page index (0..N) (page ResponseFormat only)')
          .optional()
          .default(0),
      },
      {
        name: 'size',
        type: 'Query',
        schema: z
          .number()
          .int()
          .describe('The size of the page to be returned (page ResponseFormat only)')
          .optional()
          .default(20),
      },
    ],
    response: z.void(),
    errors: [
      {
        status: 403,
        description: `Jwt token has invalid sub claim`,
        schema: z.void(),
      },
    ],
  },
  {
    method: 'delete',
    path: '/v1/tenant/users/id/:userId',
    alias: 'disableUser_1',
    requestFormat: 'json',
    parameters: [
      {
        name: 'userId',
        type: 'Path',
        schema: z.string().uuid(),
      },
    ],
    response: z.string(),
    errors: [
      {
        status: 403,
        description: `Jwt token has invalid sub claim`,
        schema: z.void(),
      },
    ],
  },
  {
    method: 'patch',
    path: '/v1/tenant/users/id/:userId',
    alias: 'updateUser_1',
    requestFormat: 'json',
    parameters: [
      {
        name: 'body',
        type: 'Body',
        schema: UpdateUserRequest,
      },
      {
        name: 'userId',
        type: 'Path',
        schema: z.string().uuid(),
      },
    ],
    response: UserDto,
    errors: [
      {
        status: 403,
        description: `Jwt token has invalid sub claim`,
        schema: z.void(),
      },
    ],
  },
  {
    method: 'get',
    path: '/v2/tenant/users',
    alias: 'getUsersPageByTenantIdAndEnabledCriteria_2',
    requestFormat: 'json',
    parameters: [
      {
        name: 'responseFormat',
        type: 'Query',
        schema: z.enum(['list', 'page', 'stream']),
      },
      {
        name: 'enabledOnly',
        type: 'Query',
        schema: z.boolean().optional().default(true),
      },
      {
        name: 'page',
        type: 'Query',
        schema: z
          .number()
          .int()
          .describe('Zero-based page index (0..N) (page ResponseFormat only)')
          .optional()
          .default(0),
      },
      {
        name: 'size',
        type: 'Query',
        schema: z
          .number()
          .int()
          .describe('The size of the page to be returned (page ResponseFormat only)')
          .optional()
          .default(20),
      },
    ],
    response: z.union([UserDto, PageUserDto]),
    errors: [
      {
        status: 403,
        description: `Jwt token has invalid sub claim`,
        schema: PageUserDto,
      },
    ],
  },
  {
    method: 'get',
    path: '/v2/tenant/users/accesslevels',
    alias: 'getUsersByAccessLevel',
    requestFormat: 'json',
    parameters: [
      {
        name: 'accessLevelNames',
        type: 'Query',
        schema: z.array(z.string()),
      },
      {
        name: 'page',
        type: 'Query',
        schema: z
          .number()
          .int()
          .describe('Zero-based page index (0..N) (page ResponseFormat only)')
          .optional()
          .default(0),
      },
      {
        name: 'size',
        type: 'Query',
        schema: z
          .number()
          .int()
          .describe('The size of the page to be returned (page ResponseFormat only)')
          .optional()
          .default(20),
      },
    ],
    response: z.void(),
    errors: [
      {
        status: 403,
        description: `Jwt token has invalid sub claim`,
        schema: z.void(),
      },
    ],
  },
  {
    method: 'get',
    path: '/v2/tenant/roles',
    alias: 'getCustomerTenantUserRolesV2',
    requestFormat: 'json',
    response: z.array(RoleRepresentation),
    errors: [
      {
        status: 403,
        description: `Jwt token has invalid sub claim`,
        schema: z.void(),
      },
    ],
  },
  {
    method: 'get',
    path: '/v2/tenant/accesslevels',
    alias: 'getCustomerTenantUserAccessLevelsV2',
    requestFormat: 'json',
    response: z.array(RoleRepresentation),
    errors: [
      {
        status: 403,
        description: `Jwt token has invalid sub claim`,
        schema: z.void(),
      },
    ],
  },
  {
    method: 'get',
    path: '/v1/tenants/users/username/:username',
    alias: 'getUserByUsername_1',
    requestFormat: 'json',
    parameters: [
      {
        name: 'username',
        type: 'Path',
        schema: z.string(),
      },
    ],
    response: UserDto,
    errors: [
      {
        status: 403,
        description: `Jwt token has invalid sub claim`,
        schema: z.void(),
      },
      {
        status: 404,
        description: `Tenant not found in Keycloak`,
        schema: z.void(),
      },
    ],
  },
  {
    method: 'get',
    path: '/v1/tenants/users/id/:userId',
    alias: 'getUserById_1',
    requestFormat: 'json',
    parameters: [
      {
        name: 'userId',
        type: 'Path',
        schema: z.string().uuid(),
      },
    ],
    response: UserDto,
    errors: [
      {
        status: 403,
        description: `Jwt token has invalid sub claim`,
        schema: z.void(),
      },
      {
        status: 404,
        description: `Tenant not found in Keycloak`,
        schema: z.void(),
      },
    ],
  },
  {
    method: 'get',
    path: '/v1/tenant/users/events',
    alias: 'getTenantUserEvents_1',
    requestFormat: 'json',
    parameters: [
      {
        name: 'eventType',
        type: 'Query',
        schema: z
          .enum([
            'LOGIN',
            'LOGIN_ERROR',
            'REGISTER',
            'REGISTER_ERROR',
            'LOGOUT',
            'LOGOUT_ERROR',
            'CODE_TO_TOKEN',
            'CODE_TO_TOKEN_ERROR',
            'CLIENT_LOGIN',
            'CLIENT_LOGIN_ERROR',
            'REFRESH_TOKEN',
            'REFRESH_TOKEN_ERROR',
            'VALIDATE_ACCESS_TOKEN',
            'VALIDATE_ACCESS_TOKEN_ERROR',
            'INTROSPECT_TOKEN',
            'INTROSPECT_TOKEN_ERROR',
            'FEDERATED_IDENTITY_LINK',
            'FEDERATED_IDENTITY_LINK_ERROR',
            'REMOVE_FEDERATED_IDENTITY',
            'REMOVE_FEDERATED_IDENTITY_ERROR',
            'UPDATE_EMAIL',
            'UPDATE_EMAIL_ERROR',
            'UPDATE_PROFILE',
            'UPDATE_PROFILE_ERROR',
            'UPDATE_PASSWORD',
            'UPDATE_PASSWORD_ERROR',
            'UPDATE_TOTP',
            'UPDATE_TOTP_ERROR',
            'VERIFY_EMAIL',
            'VERIFY_EMAIL_ERROR',
            'VERIFY_PROFILE',
            'VERIFY_PROFILE_ERROR',
            'REMOVE_TOTP',
            'REMOVE_TOTP_ERROR',
            'GRANT_CONSENT',
            'GRANT_CONSENT_ERROR',
            'UPDATE_CONSENT',
            'UPDATE_CONSENT_ERROR',
            'REVOKE_GRANT',
            'REVOKE_GRANT_ERROR',
            'SEND_VERIFY_EMAIL',
            'SEND_VERIFY_EMAIL_ERROR',
            'SEND_RESET_PASSWORD',
            'SEND_RESET_PASSWORD_ERROR',
            'SEND_IDENTITY_PROVIDER_LINK',
            'SEND_IDENTITY_PROVIDER_LINK_ERROR',
            'RESET_PASSWORD',
            'RESET_PASSWORD_ERROR',
            'RESTART_AUTHENTICATION',
            'RESTART_AUTHENTICATION_ERROR',
            'INVALID_SIGNATURE',
            'INVALID_SIGNATURE_ERROR',
            'REGISTER_NODE',
            'REGISTER_NODE_ERROR',
            'UNREGISTER_NODE',
            'UNREGISTER_NODE_ERROR',
            'USER_INFO_REQUEST',
            'USER_INFO_REQUEST_ERROR',
            'IDENTITY_PROVIDER_LINK_ACCOUNT',
            'IDENTITY_PROVIDER_LINK_ACCOUNT_ERROR',
            'IDENTITY_PROVIDER_LOGIN',
            'IDENTITY_PROVIDER_LOGIN_ERROR',
            'IDENTITY_PROVIDER_FIRST_LOGIN',
            'IDENTITY_PROVIDER_FIRST_LOGIN_ERROR',
            'IDENTITY_PROVIDER_POST_LOGIN',
            'IDENTITY_PROVIDER_POST_LOGIN_ERROR',
            'IDENTITY_PROVIDER_RESPONSE',
            'IDENTITY_PROVIDER_RESPONSE_ERROR',
            'IDENTITY_PROVIDER_RETRIEVE_TOKEN',
            'IDENTITY_PROVIDER_RETRIEVE_TOKEN_ERROR',
            'IMPERSONATE',
            'IMPERSONATE_ERROR',
            'CUSTOM_REQUIRED_ACTION',
            'CUSTOM_REQUIRED_ACTION_ERROR',
            'EXECUTE_ACTIONS',
            'EXECUTE_ACTIONS_ERROR',
            'EXECUTE_ACTION_TOKEN',
            'EXECUTE_ACTION_TOKEN_ERROR',
            'CLIENT_INFO',
            'CLIENT_INFO_ERROR',
            'CLIENT_REGISTER',
            'CLIENT_REGISTER_ERROR',
            'CLIENT_UPDATE',
            'CLIENT_UPDATE_ERROR',
            'CLIENT_DELETE',
            'CLIENT_DELETE_ERROR',
            'CLIENT_INITIATED_ACCOUNT_LINKING',
            'CLIENT_INITIATED_ACCOUNT_LINKING_ERROR',
            'TOKEN_EXCHANGE',
            'TOKEN_EXCHANGE_ERROR',
            'OAUTH2_DEVICE_AUTH',
            'OAUTH2_DEVICE_AUTH_ERROR',
            'OAUTH2_DEVICE_VERIFY_USER_CODE',
            'OAUTH2_DEVICE_VERIFY_USER_CODE_ERROR',
            'OAUTH2_DEVICE_CODE_TO_TOKEN',
            'OAUTH2_DEVICE_CODE_TO_TOKEN_ERROR',
            'AUTHREQID_TO_TOKEN',
            'AUTHREQID_TO_TOKEN_ERROR',
            'PERMISSION_TOKEN',
            'PERMISSION_TOKEN_ERROR',
            'DELETE_ACCOUNT',
            'DELETE_ACCOUNT_ERROR',
            'PUSHED_AUTHORIZATION_REQUEST',
            'PUSHED_AUTHORIZATION_REQUEST_ERROR',
            'USER_DISABLED_BY_PERMANENT_LOCKOUT',
            'USER_DISABLED_BY_PERMANENT_LOCKOUT_ERROR',
            'USER_DISABLED_BY_TEMPORARY_LOCKOUT',
            'USER_DISABLED_BY_TEMPORARY_LOCKOUT_ERROR',
            'OAUTH2_EXTENSION_GRANT',
            'OAUTH2_EXTENSION_GRANT_ERROR',
          ])
          .optional(),
      },
    ],
    response: z.array(UserEventDto),
    errors: [
      {
        status: 403,
        description: `Jwt token has invalid sub claim`,
        schema: z.void(),
      },
      {
        status: 404,
        description: `User&#x27;s events not found in Keycloak for the given tenant`,
        schema: z.void(),
      },
    ],
  },
]);

export const customer_user_management_controllerApi = new Zodios(customer_user_management_controllerEndpoints);

export function createApiClient(baseUrl: string, options?: ZodiosOptions) {
  return new Zodios(baseUrl, endpoints, options);
}
