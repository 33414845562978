import { makeApi, Zodios } from '@zodios/core';
import { z } from 'zod';

type AssetQueryDto = Partial<{
  attributeFilters: AttributeFilters;
  /**
   * Asset Ids to filter on
   */
  assetIds: Array<string>;
}>;
type AttributeFilters = Partial<{
  patternFilters: {};
  collectionFilters: {};
}>;
type UserSavedAssetFilterRequest = {
  filterName: string;
  filterType: string;
  description?: string | undefined;
  filters: AssetQueryDto;
  isDefault?: boolean | undefined;
  isHidden?: boolean | undefined;
};
type UserSavedFilterDtoAssetQueryDto = Partial<{
  userSavedFilterId: string;
  description: string;
  filterName: string;
  filters: AssetQueryDto;
  isDefault: boolean;
  isHidden: boolean;
}>;
type AssetInsightActionQueryDto = Partial<{
  insightActions: Array<InsightAction>;
}>;
type InsightAction =
  /**
   * @enum NEW, ACCEPTED, ASSIGNED_TO_CASE, DISREGARDED
   */
  'NEW' | 'ACCEPTED' | 'ASSIGNED_TO_CASE' | 'DISREGARDED';
type AssetInsightQueryDto = Partial<{
  assetQuery: AssetQueryDto;
  insightActionQuery: AssetInsightActionQueryDto;
  insightMetadataQuery: InsightMetadataQueryDto;
  insightQuery: InsightQueryDto;
  /**
   * The lower bound of when an AssetInsight was last modified. Will return insights that were modified >= this value
   *
   * @example "2000-10-31T01:30:00-05:00"
   */
  lastModifiedLowerBound: string;
  /**
   * The upper bound of when an AssetInsight was modified. Will return insights that were modified < this value
   *
   * @example "2000-10-31T01:30:00-05:00"
   */
  lastModifiedUpperBound: string;
  /**
   * Insight severities to filter on
   */
  insightSeverities: Array<InsightSeverity>;
}>;
type InsightMetadataQueryDto = Partial<{
  titles: Array<string>;
}>;
type InsightQueryDto = Partial<{
  /**
   * DEPRECATED use 'firstDetectedLowerBound'. The lower bound of the time period when a insight was first detected. Will return insights with 'start' timestamps (AKA 'first detected' timestamps) that are >= this value
   *
   * @example "2000-10-31T01:30:00-05:00"
   * @deprecated
   */
  startTs: string;
  /**
   * DEPRECATED use 'lastDetectedUpperBound'. The upper bound of the time period when a insight was last detected. Will return insights with 'end' timestamps (AKA 'last detected' timestamps) that are < this value
   *
   * @example "2000-10-31T01:30:00-05:00"
   * @deprecated
   */
  endTs: string;
  /**
   * DEPRECATED use 'inactivityTsLowerBound'. The lower bound of the timestamp when an insight will no longer be extended.  Will return insights with 'inactivity' timestamps that are >= this value
   *
   * @example "2000-10-31T01:30:00-05:00"
   * @deprecated
   */
  activeOnTs: string;
  /**
   * DEPRECATED use 'inactivityTsUpperBound'. The upper bound of the timestamp when an insight will no longer be extended.  Will return insights with 'inactivity' timestamps that are < this value
   *
   * @example "2000-10-31T01:30:00-05:00"
   * @deprecated
   */
  inactiveOnTs: string;
  /**
   * The lower bound of the time period when a insight was first detected. Will return insights with 'start' timestamps (AKA 'first detected' timestamps) that are >= this value
   *
   * @example "2000-10-31T01:30:00-05:00"
   */
  firstDetectedLowerBound: string;
  /**
   * The upper bound of the time period when a insight was first detected. Will return insights with 'start' timestamps (AKA 'first detected' timestamps) that are < this value
   *
   * @example "2000-10-31T01:30:00-05:00"
   */
  firstDetectedUpperBound: string;
  /**
   * The lower bound of the timestamp when an insight will no longer be extended.  Will return insights with 'inactivity' timestamps that are >= this value
   *
   * @example "2000-10-31T01:30:00-05:00"
   */
  inactivityTsLowerBound: string;
  /**
   * The upper bound of the timestamp when an insight will no longer be extended.  Will return insights with 'inactivity' timestamps that are < this value
   *
   * @example "2000-10-31T01:30:00-05:00"
   */
  inactivityTsUpperBound: string;
  /**
   * Field The lower bound of the time period when a insight was last detected. Will return insights with 'end' timestamps (AKA 'last detected' timestamps) that are >= this value
   *
   * @example "2000-10-31T01:30:00-05:00"
   */
  lastDetectedLowerBound: string;
  /**
   * The upper bound of the time period when a insight was last detected. Will return insights with 'end' timestamps (AKA 'last detected' timestamps) that are < this value
   *
   * @example "2000-10-31T01:30:00-05:00"
   */
  lastDetectedUpperBound: string;
}>;
type InsightSeverity =
  /**
   * @enum NEGLIGIBLE, MARGINAL, CRITICAL, CATASTROPHIC
   */
  'NEGLIGIBLE' | 'MARGINAL' | 'CRITICAL' | 'CATASTROPHIC';
type AssetDto = {
  /**
   * Uptake's unique asset identifier. An MD5 hash of the tenantId and the customerAssetId
   *
   * @example "9b4215115d1776b354fb4152d26cd289"
   */
  assetId: string;
  tenantId?: /**
   * The id of the tenant that owns the asset
   *
   * @example "lnc-trucking"
   */
  string | undefined;
  /**
   * The customer's unique id for the asset
   *
   * @example "90210"
   */
  customerAssetId: string;
  attributes?: /**
   * A set of key/value pairs representing the asset's attributes
   */
  {} | undefined;
  transactionTs?: string | undefined;
  assetRiskScore?:
    | /**
     * A numeric value based on an asset's recently detected Insights
     *
     * @example 87
     */
    (number | null)
    | undefined;
  assetRiskScoreTs?:
    | /**
     * The maximum lastModifiedTimestampe of the Insights used to calculate the asserRiskScore
     */
    (string | null)
    | undefined;
  insightsSummary?: InsightsSummaryDto | undefined;
  casesSummary?: CasesSummaryDto | undefined;
  workordersSummaryDto?: WorkordersSummaryDto | undefined;
};
type InsightsSummaryDto = Partial<{
  /**
   * The number of Insights that are 'active' AKA Insights that may be extended
   *
   * @example 87
   */
  activeInsights: number;
  /**
   * The number of Insights that are in the 'NEW' state. They are awaiting review/action
   *
   * @example 100
   */
  awaitingAction: number;
  /**
   * The number of Insights that are assigned to a Case.
   *
   * @example 47
   */
  assignedToACase: number;
  /**
   * The number of 'visible' Insights that are assigned to a Case.
   *
   * @example 100
   */
  actionedInsights: number;
  /**
   * The total number of 'visible' Insights associated to this Asset.
   *
   * @example 200
   */
  totalInsights: number;
}>;
type CasesSummaryDto = Partial<{
  openCases: number;
  closedCases: number;
  totalCases: number;
}>;
type WorkordersSummaryDto = Partial<{
  openWorkorders: number;
  closedWorkorders: number;
  totalWorkorders: number;
}>;
type AssetInsightActionDto = Partial<{
  assetInsightActionId: string;
  assetInsight: AssetInsightDto;
  actionState: InsightAction;
  /**
   * @deprecated
   */
  actionedBy: string;
  actionedByUser: TenantUserDto;
  actionedTs: string;
  customerActionTakenTs: string;
  notes: string;
  resolutionReason: InsightResolutionReason;
}>;
type InsightDto = Partial<{
  insightId: string;
  insightMetadata: InsightMetadataDto;
  modelName: string;
  modelVersion: string;
  startTs: string;
  endTs: string;
  insightDuration: Partial<{
    seconds: number;
    zero: boolean;
    nano: number;
    negative: boolean;
    units: Array<
      Partial<{
        durationEstimated: boolean;
        timeBased: boolean;
        dateBased: boolean;
      }>
    >;
  }>;
  inactivityTs: string;
  attributes: {};
  sinceLastDetected: Partial<{
    seconds: number;
    zero: boolean;
    nano: number;
    negative: boolean;
    units: Array<
      Partial<{
        durationEstimated: boolean;
        timeBased: boolean;
        dateBased: boolean;
      }>
    >;
  }>;
  visibleInUi: boolean;
  media: Array<MediaDto>;
}>;
type InsightMetadataDto = Partial<{
  insightMetadataKey: string;
  riskFactor: string;
  title: string;
  description: string;
  recommendedAction: string;
  insightCategory: InsightCategory;
  insightMetadataAttributes: {};
}>;
type InsightCategory =
  /**
   * @enum WORK_ORDER, FLUID, SENSOR, FAULT
   */
  'WORK_ORDER' | 'FLUID' | 'SENSOR' | 'FAULT';
type MediaDto = Partial<{
  mediaFileLink: string;
  mediaDataType: string;
}>;
type TenantUserDto = {
  userId: string;
  tenantId: string;
  preferredUsername?: string | undefined;
  givenName?: string | undefined;
  familyName?: string | undefined;
  email?: string | undefined;
  enabled?: boolean | undefined;
};
type InsightResolutionReason =
  /**
   * @enum ACTION_TAKEN, ACTION_ON_HOLD, OTHER, NO_ISSUE_FOUND, INCONCLUSIVE_INSPECTION, INSIGHT_IS_A_DUPLICATE
   */
  'ACTION_TAKEN' | 'ACTION_ON_HOLD' | 'OTHER' | 'NO_ISSUE_FOUND' | 'INCONCLUSIVE_INSPECTION' | 'INSIGHT_IS_A_DUPLICATE';
type AssetInsightCommentDto = Partial<{
  assetInsightCommentId: string;
  assetInsight: AssetInsightDto;
  replyToAssetInsightComment: AssetInsightCommentDto;
  comment: string;
  transactionTs: string;
  deletedTs: string;
  author: TenantUserDto;
}>;
type AssetInsightDto = Partial<{
  assetInsightId: string;
  asset: AssetDto;
  insight: InsightDto;
  riskScore: number;
  severity: InsightSeverity;
  confidence: number;
  actions: Array<AssetInsightActionDto>;
  comments: Array<AssetInsightCommentDto>;
  firstAction: AssetInsightActionDto;
  latestAction: AssetInsightActionDto;
  lastModifiedTs: string;
  assetInsightUiLink: string;
  assetCaseUiLink: string;
}>;
type AssetInsightDtoPage = Partial<{
  content: Array<AssetInsightDto>;
  pageable: PageableObject;
  last: boolean;
  totalPages: number;
  totalElements: number;
  size: number;
  number: number;
  sort: Array<SortObject>;
  first: boolean;
  numberOfElements: number;
  empty: boolean;
}>;
type PageableObject = Partial<{
  paged: boolean;
  pageNumber: number;
  pageSize: number;
  offset: number;
  sort: Array<SortObject>;
  unpaged: boolean;
}>;
type SortObject = Partial<{
  direction: string;
  nullHandling: string;
  ascending: boolean;
  property: string;
  ignoreCase: boolean;
}>;
type AssetCaseDto = Partial<{
  asset: AssetDto;
  case: CaseDto;
}>;
type CaseActionDto = Partial<{
  caseActionId: string;
  /**
   * The state this action transitioned the case to
   *
   * @enum OPEN, CLOSED
   */
  caseState: 'OPEN' | 'CLOSED';
  /**
   * User supplied details when the case is closed
   */
  caseResolution: string | null;
  /**
   * The user id or the user that performed this Case Action
   *
   * @deprecated
   */
  actionedBy: string | null;
  actionedByUser: TenantUserDto;
  /**
   * The timestamp when the user performed this action
   */
  actionedTs: string;
  case: CaseDto;
}>;
type CaseDto = Partial<{
  /**
   * The case's unique identifier
   */
  caseId: string;
  /**
   * A user generated case identifier
   */
  caseNum: string | null;
  /**
   * The id of the tenant associated to this case
   *
   * @example "lnc-trucking"
   */
  tenantId: string;
  title: string;
  description: string;
  actions: Array<CaseActionDto>;
  assetInsights: Array<AssetInsightDto>;
  notes: Array<CaseNoteDto>;
  lastModifiedTs: string;
}>;
type CaseNoteDto = Partial<{
  caseNoteId: string;
  replyToCaseNote: CaseNoteDto;
  note: string;
  transactionTs: string;
  deletedTs: string;
  author: TenantUserDto;
  /**
   * @deprecated
   */
  authorId: string;
  case: CaseDto;
}>;
type PageAssetCaseDto = Partial<{
  totalPages: number;
  totalElements: number;
  pageable: PageableObject;
  size: number;
  content: Array<AssetCaseDto>;
  number: number;
  sort: Array<SortObject>;
  first: boolean;
  last: boolean;
  numberOfElements: number;
  empty: boolean;
}>;
type PageAssetRiskFactorRiskScoreDto = Partial<{
  totalPages: number;
  totalElements: number;
  pageable: PageableObject;
  size: number;
  content: Array<AssetRiskFactorRiskScoreDto>;
  number: number;
  sort: Array<SortObject>;
  first: boolean;
  last: boolean;
  numberOfElements: number;
  empty: boolean;
}>;
type AssetRiskFactorRiskScoreDto = Partial<{
  assetId: string;
  riskFactor: string;
  category: string;
  riskScore: number;
  lastUpdatedTs: string;
}>;
type AssetRiskScoreDto = Partial<{
  asset: AssetDto;
  assetRiskScore: number;
  assetRiskScoreTs: string;
}>;
type PageAssetRiskScoreDto = Partial<{
  totalPages: number;
  totalElements: number;
  pageable: PageableObject;
  size: number;
  content: Array<AssetRiskScoreDto>;
  number: number;
  sort: Array<SortObject>;
  first: boolean;
  last: boolean;
  numberOfElements: number;
  empty: boolean;
}>;
type AssetInsightActionUpdateRequest = {
  action: InsightAction;
  actionedTs?: /**
   * @deprecated
   */
  string | undefined;
  customerActionTakenTs?: string | undefined;
  notes?: string | undefined;
  resolutionReason?: InsightResolutionReason | undefined;
};
type CloseCaseRequest = {
  caseResolution: string;
  assetInsightsToRemove?: Array<string> | undefined;
  assetInsightsToResolve?: {} | undefined;
};
type CloseCaseBulkRequest = {
  caseResolution: string;
  action: InsightAction;
  customerActionTakenTs?: string | undefined;
  assetInsightNotes?: string | undefined;
  resolutionReason?: InsightResolutionReason | undefined;
};
type CaseDtoPage = Partial<{
  content: Array<CaseDto>;
  pageable: PageableObject;
  last: boolean;
  totalPages: number;
  totalElements: number;
  size: number;
  number: number;
  sort: Array<SortObject>;
  first: boolean;
  numberOfElements: number;
  empty: boolean;
}>;
type PageAssetDto = Partial<{
  totalPages: number;
  totalElements: number;
  pageable: PageableObject;
  size: number;
  content: Array<AssetDto>;
  number: number;
  sort: Array<SortObject>;
  first: boolean;
  last: boolean;
  numberOfElements: number;
  empty: boolean;
}>;
type WorkorderAttributesDto = Partial<{
  tasks: Array<TaskDto>;
}>;
type TaskDto = Partial<{
  taskAttributes: {};
}>;
type WorkorderDto = Partial<{
  workorderId: string;
  customerWorkorderId: string;
  openedTs: string;
  closedTs: string;
  tenantId: string;
  summary: string;
  workDescription: string;
  workorderAttributes: WorkorderAttributesDto;
  caseId: string;
  lastModifiedTs: string;
}>;
type PageAssetInsightActionDto = Partial<{
  totalPages: number;
  totalElements: number;
  pageable: PageableObject;
  size: number;
  content: Array<AssetInsightActionDto>;
  number: number;
  sort: Array<SortObject>;
  first: boolean;
  last: boolean;
  numberOfElements: number;
  empty: boolean;
}>;

const AttributeFilters: z.ZodType<AttributeFilters> = z
  .object({ patternFilters: z.record(z.string()), collectionFilters: z.record(z.array(z.string())) })
  .partial()
  .passthrough();
const AssetQueryDto: z.ZodType<AssetQueryDto> = z
  .object({
    attributeFilters: AttributeFilters.describe('Asset attributes filters'),
    assetIds: z.array(z.string()).describe('Asset Ids to filter on'),
  })
  .partial()
  .passthrough();
const UserSavedAssetFilterRequest: z.ZodType<UserSavedAssetFilterRequest> = z
  .object({
    filterName: z.string(),
    filterType: z.string(),
    description: z.string().optional(),
    filters: AssetQueryDto.describe('Asset Query Filters'),
    isDefault: z.boolean().optional(),
    isHidden: z.boolean().optional(),
  })
  .passthrough();
const UserSavedFilterDtoAssetQueryDto: z.ZodType<UserSavedFilterDtoAssetQueryDto> = z
  .object({
    userSavedFilterId: z.string().uuid(),
    description: z.string(),
    filterName: z.string(),
    filters: AssetQueryDto.describe('Asset Query Filters'),
    isDefault: z.boolean(),
    isHidden: z.boolean(),
  })
  .partial()
  .passthrough();
const InsightAction = z.enum(['NEW', 'ACCEPTED', 'ASSIGNED_TO_CASE', 'DISREGARDED']);
const AssetInsightActionQueryDto: z.ZodType<AssetInsightActionQueryDto> = z
  .object({ insightActions: z.array(InsightAction) })
  .partial()
  .passthrough();
const InsightMetadataQueryDto: z.ZodType<InsightMetadataQueryDto> = z
  .object({ titles: z.array(z.string()) })
  .partial()
  .passthrough();
const InsightQueryDto: z.ZodType<InsightQueryDto> = z
  .object({
    startTs: z
      .string()
      .datetime({ offset: true })
      .describe(
        "DEPRECATED use 'firstDetectedLowerBound'. The lower bound of the time period when a insight was first detected. Will return insights with 'start' timestamps (AKA 'first detected' timestamps) that are >= this value"
      ),
    endTs: z
      .string()
      .datetime({ offset: true })
      .describe(
        "DEPRECATED use 'lastDetectedUpperBound'. The upper bound of the time period when a insight was last detected. Will return insights with 'end' timestamps (AKA 'last detected' timestamps) that are < this value"
      ),
    activeOnTs: z
      .string()
      .datetime({ offset: true })
      .describe(
        "DEPRECATED use 'inactivityTsLowerBound'. The lower bound of the timestamp when an insight will no longer be extended.  Will return insights with 'inactivity' timestamps that are >= this value"
      ),
    inactiveOnTs: z
      .string()
      .datetime({ offset: true })
      .describe(
        "DEPRECATED use 'inactivityTsUpperBound'. The upper bound of the timestamp when an insight will no longer be extended.  Will return insights with 'inactivity' timestamps that are < this value"
      ),
    firstDetectedLowerBound: z
      .string()
      .datetime({ offset: true })
      .describe(
        "The lower bound of the time period when a insight was first detected. Will return insights with 'start' timestamps (AKA 'first detected' timestamps) that are >= this value"
      ),
    firstDetectedUpperBound: z
      .string()
      .datetime({ offset: true })
      .describe(
        "The upper bound of the time period when a insight was first detected. Will return insights with 'start' timestamps (AKA 'first detected' timestamps) that are < this value"
      ),
    inactivityTsLowerBound: z
      .string()
      .datetime({ offset: true })
      .describe(
        "The lower bound of the timestamp when an insight will no longer be extended.  Will return insights with 'inactivity' timestamps that are >= this value"
      ),
    inactivityTsUpperBound: z
      .string()
      .datetime({ offset: true })
      .describe(
        "The upper bound of the timestamp when an insight will no longer be extended.  Will return insights with 'inactivity' timestamps that are < this value"
      ),
    lastDetectedLowerBound: z
      .string()
      .datetime({ offset: true })
      .describe(
        "Field The lower bound of the time period when a insight was last detected. Will return insights with 'end' timestamps (AKA 'last detected' timestamps) that are >= this value"
      ),
    lastDetectedUpperBound: z
      .string()
      .datetime({ offset: true })
      .describe(
        "The upper bound of the time period when a insight was last detected. Will return insights with 'end' timestamps (AKA 'last detected' timestamps) that are < this value"
      ),
  })
  .partial()
  .passthrough();
const InsightSeverity = z.enum(['NEGLIGIBLE', 'MARGINAL', 'CRITICAL', 'CATASTROPHIC']);
const AssetInsightQueryDto: z.ZodType<AssetInsightQueryDto> = z
  .object({
    assetQuery: AssetQueryDto.describe('Asset Query Filters'),
    insightActionQuery: AssetInsightActionQueryDto,
    insightMetadataQuery: InsightMetadataQueryDto.describe('Query filters for InsighMetadataDtos'),
    insightQuery: InsightQueryDto.describe('Query filters for InsightDtos'),
    lastModifiedLowerBound: z
      .string()
      .datetime({ offset: true })
      .describe(
        'The lower bound of when an AssetInsight was last modified. Will return insights that were modified >= this value'
      ),
    lastModifiedUpperBound: z
      .string()
      .datetime({ offset: true })
      .describe(
        'The upper bound of when an AssetInsight was modified. Will return insights that were modified < this value'
      ),
    insightSeverities: z.array(InsightSeverity).describe('Insight severities to filter on'),
  })
  .partial()
  .passthrough();
const SortObject: z.ZodType<SortObject> = z
  .object({
    direction: z.string(),
    nullHandling: z.string(),
    ascending: z.boolean(),
    property: z.string(),
    ignoreCase: z.boolean(),
  })
  .partial()
  .passthrough();
const PageableObject: z.ZodType<PageableObject> = z
  .object({
    paged: z.boolean(),
    pageNumber: z.number().int(),
    pageSize: z.number().int(),
    offset: z.number().int(),
    sort: z.array(SortObject),
    unpaged: z.boolean(),
  })
  .partial()
  .passthrough();
const InsightsSummaryDto: z.ZodType<InsightsSummaryDto> = z
  .object({
    activeInsights: z
      .number()
      .int()
      .describe("The number of Insights that are 'active' AKA Insights that may be extended"),
    awaitingAction: z
      .number()
      .int()
      .describe("The number of Insights that are in the 'NEW' state. They are awaiting review/action"),
    assignedToACase: z.number().int().describe('The number of Insights that are assigned to a Case.'),
    actionedInsights: z.number().int().describe("The number of 'visible' Insights that are assigned to a Case."),
    totalInsights: z.number().int().describe("The total number of 'visible' Insights associated to this Asset."),
  })
  .partial()
  .passthrough();
const CasesSummaryDto: z.ZodType<CasesSummaryDto> = z
  .object({ openCases: z.number().int(), closedCases: z.number().int(), totalCases: z.number().int() })
  .partial()
  .passthrough();
const WorkordersSummaryDto: z.ZodType<WorkordersSummaryDto> = z
  .object({ openWorkorders: z.number().int(), closedWorkorders: z.number().int(), totalWorkorders: z.number().int() })
  .partial()
  .passthrough();
const AssetDto: z.ZodType<AssetDto> = z
  .object({
    assetId: z
      .string()
      .describe("Uptake's unique asset identifier. An MD5 hash of the tenantId and the customerAssetId"),
    tenantId: z.string().describe('The id of the tenant that owns the asset').optional(),
    customerAssetId: z.string().describe("The customer's unique id for the asset"),
    attributes: z
      .record(z.string().describe("A set of key/value pairs representing the asset's attributes"))
      .describe("A set of key/value pairs representing the asset's attributes")
      .optional(),
    transactionTs: z.string().datetime({ offset: true }).optional(),
    assetRiskScore: z
      .number()
      .int()
      .describe("A numeric value based on an asset's recently detected Insights")
      .nullish(),
    assetRiskScoreTs: z
      .string()
      .datetime({ offset: true })
      .describe('The maximum lastModifiedTimestampe of the Insights used to calculate the asserRiskScore')
      .nullish(),
    insightsSummary: InsightsSummaryDto.optional(),
    casesSummary: CasesSummaryDto.optional(),
    workordersSummaryDto: WorkordersSummaryDto.optional(),
  })
  .passthrough();
const TenantUserDto: z.ZodType<TenantUserDto> = z
  .object({
    userId: z.string().uuid(),
    tenantId: z.string(),
    preferredUsername: z.string().optional(),
    givenName: z.string().optional(),
    familyName: z.string().optional(),
    email: z.string().optional(),
    enabled: z.boolean().optional(),
  })
  .passthrough();
const CaseActionDto: z.ZodType<CaseActionDto> = z.lazy(() =>
  z
    .object({
      caseActionId: z.string().uuid(),
      caseState: z.enum(['OPEN', 'CLOSED']).describe('The state this action transitioned the case to'),
      caseResolution: z.string().describe('User supplied details when the case is closed').nullable(),
      actionedBy: z.string().uuid().describe('The user id or the user that performed this Case Action').nullable(),
      actionedByUser: TenantUserDto,
      actionedTs: z.string().datetime({ offset: true }).describe('The timestamp when the user performed this action'),
      case: CaseDto.describe('One of the Cases associated the asset'),
    })
    .partial()
    .passthrough()
);
const InsightCategory = z.enum(['WORK_ORDER', 'FLUID', 'SENSOR', 'FAULT']);
const InsightMetadataDto: z.ZodType<InsightMetadataDto> = z
  .object({
    insightMetadataKey: z.string(),
    riskFactor: z.string(),
    title: z.string(),
    description: z.string(),
    recommendedAction: z.string(),
    insightCategory: InsightCategory,
    insightMetadataAttributes: z.record(z.object({}).partial().passthrough()),
  })
  .partial()
  .passthrough();
const MediaDto: z.ZodType<MediaDto> = z
  .object({ mediaFileLink: z.string(), mediaDataType: z.string() })
  .partial()
  .passthrough();
const InsightDto: z.ZodType<InsightDto> = z
  .object({
    insightId: z.string().uuid(),
    insightMetadata: InsightMetadataDto,
    modelName: z.string(),
    modelVersion: z.string(),
    startTs: z.string().datetime({ offset: true }),
    endTs: z.string().datetime({ offset: true }),
    insightDuration: z
      .object({
        seconds: z.number().int(),
        zero: z.boolean(),
        nano: z.number().int(),
        negative: z.boolean(),
        units: z.array(
          z
            .object({ durationEstimated: z.boolean(), timeBased: z.boolean(), dateBased: z.boolean() })
            .partial()
            .passthrough()
        ),
      })
      .partial()
      .passthrough(),
    inactivityTs: z.string().datetime({ offset: true }),
    attributes: z.record(z.object({}).partial().passthrough()),
    sinceLastDetected: z
      .object({
        seconds: z.number().int(),
        zero: z.boolean(),
        nano: z.number().int(),
        negative: z.boolean(),
        units: z.array(
          z
            .object({ durationEstimated: z.boolean(), timeBased: z.boolean(), dateBased: z.boolean() })
            .partial()
            .passthrough()
        ),
      })
      .partial()
      .passthrough(),
    visibleInUi: z.boolean(),
    media: z.array(MediaDto),
  })
  .partial()
  .passthrough();
const InsightResolutionReason = z.enum([
  'ACTION_TAKEN',
  'ACTION_ON_HOLD',
  'OTHER',
  'NO_ISSUE_FOUND',
  'INCONCLUSIVE_INSPECTION',
  'INSIGHT_IS_A_DUPLICATE',
]);
const AssetInsightActionDto: z.ZodType<AssetInsightActionDto> = z.lazy(() =>
  z
    .object({
      assetInsightActionId: z.string().uuid(),
      assetInsight: AssetInsightDto,
      actionState: InsightAction,
      actionedBy: z.string().uuid(),
      actionedByUser: TenantUserDto,
      actionedTs: z.string().datetime({ offset: true }),
      customerActionTakenTs: z.string().datetime({ offset: true }),
      notes: z.string(),
      resolutionReason: InsightResolutionReason,
    })
    .partial()
    .passthrough()
);
const AssetInsightCommentDto: z.ZodType<AssetInsightCommentDto> = z.lazy(() =>
  z
    .object({
      assetInsightCommentId: z.string().uuid(),
      assetInsight: AssetInsightDto,
      replyToAssetInsightComment: AssetInsightCommentDto,
      comment: z.string(),
      transactionTs: z.string().datetime({ offset: true }),
      deletedTs: z.string().datetime({ offset: true }),
      author: TenantUserDto,
    })
    .partial()
    .passthrough()
);
const AssetInsightDto: z.ZodType<AssetInsightDto> = z.lazy(() =>
  z
    .object({
      assetInsightId: z.string().uuid(),
      asset: AssetDto,
      insight: InsightDto,
      riskScore: z.number().int(),
      severity: InsightSeverity,
      confidence: z.number(),
      actions: z.array(AssetInsightActionDto),
      comments: z.array(AssetInsightCommentDto),
      firstAction: AssetInsightActionDto,
      latestAction: AssetInsightActionDto,
      lastModifiedTs: z.string().datetime({ offset: true }),
      assetInsightUiLink: z.string().url(),
      assetCaseUiLink: z.string().url(),
    })
    .partial()
    .passthrough()
);
const CaseNoteDto: z.ZodType<CaseNoteDto> = z.lazy(() =>
  z
    .object({
      caseNoteId: z.string().uuid(),
      replyToCaseNote: CaseNoteDto,
      note: z.string(),
      transactionTs: z.string().datetime({ offset: true }),
      deletedTs: z.string().datetime({ offset: true }),
      author: TenantUserDto,
      authorId: z.string().uuid(),
      case: CaseDto.describe('One of the Cases associated the asset'),
    })
    .partial()
    .passthrough()
);
const CaseDto: z.ZodType<CaseDto> = z.lazy(() =>
  z
    .object({
      caseId: z.string().uuid().describe("The case's unique identifier"),
      caseNum: z.string().describe('A user generated case identifier').nullable(),
      tenantId: z.string().describe('The id of the tenant associated to this case'),
      title: z.string(),
      description: z.string(),
      actions: z.array(CaseActionDto),
      assetInsights: z.array(AssetInsightDto),
      notes: z.array(CaseNoteDto),
      lastModifiedTs: z.string().datetime({ offset: true }),
    })
    .partial()
    .passthrough()
);
const AssetCaseDto: z.ZodType<AssetCaseDto> = z
  .object({ asset: AssetDto, case: CaseDto.describe('One of the Cases associated the asset') })
  .partial()
  .passthrough();
const PageAssetCaseDto: z.ZodType<PageAssetCaseDto> = z
  .object({
    totalPages: z.number().int(),
    totalElements: z.number().int(),
    pageable: PageableObject,
    size: z.number().int(),
    content: z.array(AssetCaseDto),
    number: z.number().int(),
    sort: z.array(SortObject),
    first: z.boolean(),
    last: z.boolean(),
    numberOfElements: z.number().int(),
    empty: z.boolean(),
  })
  .partial()
  .passthrough();
const AssetRiskFactorRiskScoreDto: z.ZodType<AssetRiskFactorRiskScoreDto> = z
  .object({
    assetId: z.string(),
    riskFactor: z.string(),
    category: z.string(),
    riskScore: z.number().int(),
    lastUpdatedTs: z.string().datetime({ offset: true }),
  })
  .partial()
  .passthrough();
const PageAssetRiskFactorRiskScoreDto: z.ZodType<PageAssetRiskFactorRiskScoreDto> = z
  .object({
    totalPages: z.number().int(),
    totalElements: z.number().int(),
    pageable: PageableObject,
    size: z.number().int(),
    content: z.array(AssetRiskFactorRiskScoreDto),
    number: z.number().int(),
    sort: z.array(SortObject),
    first: z.boolean(),
    last: z.boolean(),
    numberOfElements: z.number().int(),
    empty: z.boolean(),
  })
  .partial()
  .passthrough();
const AssetRiskScoreDto: z.ZodType<AssetRiskScoreDto> = z
  .object({
    asset: AssetDto,
    assetRiskScore: z.number().int(),
    assetRiskScoreTs: z.string().datetime({ offset: true }),
  })
  .partial()
  .passthrough();
const PageAssetRiskScoreDto: z.ZodType<PageAssetRiskScoreDto> = z
  .object({
    totalPages: z.number().int(),
    totalElements: z.number().int(),
    pageable: PageableObject,
    size: z.number().int(),
    content: z.array(AssetRiskScoreDto),
    number: z.number().int(),
    sort: z.array(SortObject),
    first: z.boolean(),
    last: z.boolean(),
    numberOfElements: z.number().int(),
    empty: z.boolean(),
  })
  .partial()
  .passthrough();
const AssetRiskScoreSummaryDto = z
  .object({
    total: z.number().int(),
    veryHigh: z.number().int(),
    high: z.number().int(),
    medium: z.number().int(),
    low: z.number().int(),
    veryLow: z.number().int(),
  })
  .partial()
  .passthrough();
const OpenCaseRequest = z
  .object({
    caseNum: z.string().optional(),
    title: z.string(),
    description: z.string(),
    assetInsightIds: z.array(z.string()),
  })
  .passthrough();
const CaseNoteRequest = z.object({ note: z.string() }).passthrough();
const AssetInsightCommentRequest = z.object({ comment: z.string() }).passthrough();
const AssetFiltersRequest = z
  .object({
    filterId: z.string().uuid(),
    customerAssetId: z.string(),
    filters: z.record(z.array(z.string())),
    attributes: z.array(z.string()),
  })
  .partial()
  .passthrough();
const AssetFiltersDto = z
  .object({ count: z.number().int(), filters: z.record(z.array(z.string())) })
  .partial()
  .passthrough();
const AdminOpenCaseRequest = z
  .object({
    caseNum: z.string().optional(),
    title: z.string(),
    description: z.string(),
    assetInsightIds: z.array(z.string()),
    tenantId: z
      .string()
      .regex(/^(?=[a-z0-9-]{3,36}$)[a-z0-9]+(-[a-z0-9]+){0,17}$/)
      .describe('The tenantId'),
  })
  .passthrough();
const AssetInsightActionUpdateRequest: z.ZodType<AssetInsightActionUpdateRequest> = z
  .object({
    action: InsightAction,
    actionedTs: z.string().datetime({ offset: true }).optional(),
    customerActionTakenTs: z.string().datetime({ offset: true }).optional(),
    notes: z.string().optional(),
    resolutionReason: InsightResolutionReason.optional(),
  })
  .passthrough();
const CloseCaseRequest: z.ZodType<CloseCaseRequest> = z
  .object({
    caseResolution: z.string(),
    assetInsightsToRemove: z.array(z.string()).optional(),
    assetInsightsToResolve: z.record(AssetInsightActionUpdateRequest).optional(),
  })
  .passthrough();
const CloseCaseBulkRequest: z.ZodType<CloseCaseBulkRequest> = z
  .object({
    caseResolution: z.string(),
    action: InsightAction,
    customerActionTakenTs: z.string().datetime({ offset: true }).optional(),
    assetInsightNotes: z.string().optional(),
    resolutionReason: InsightResolutionReason.optional(),
  })
  .passthrough();
const PageAssetDto: z.ZodType<PageAssetDto> = z
  .object({
    totalPages: z.number().int(),
    totalElements: z.number().int(),
    pageable: PageableObject,
    size: z.number().int(),
    content: z.array(AssetDto),
    number: z.number().int(),
    sort: z.array(SortObject),
    first: z.boolean(),
    last: z.boolean(),
    numberOfElements: z.number().int(),
    empty: z.boolean(),
  })
  .partial()
  .passthrough();
const TaskDto: z.ZodType<TaskDto> = z
  .object({ taskAttributes: z.record(z.string()) })
  .partial()
  .passthrough();
const WorkorderAttributesDto: z.ZodType<WorkorderAttributesDto> = z
  .object({ tasks: z.array(TaskDto) })
  .partial()
  .passthrough();
const WorkorderDto: z.ZodType<WorkorderDto> = z
  .object({
    workorderId: z.string(),
    customerWorkorderId: z.string(),
    openedTs: z.string().datetime({ offset: true }),
    closedTs: z.string().datetime({ offset: true }),
    tenantId: z.string(),
    summary: z.string(),
    workDescription: z.string(),
    workorderAttributes: WorkorderAttributesDto,
    caseId: z.string().uuid(),
    lastModifiedTs: z.string().datetime({ offset: true }),
  })
  .partial()
  .passthrough();
const AssetInsightDtoPage: z.ZodType<AssetInsightDtoPage> = z
  .object({
    content: z.array(AssetInsightDto),
    pageable: PageableObject,
    last: z.boolean(),
    totalPages: z.number().int(),
    totalElements: z.number().int(),
    size: z.number().int(),
    number: z.number().int(),
    sort: z.array(SortObject),
    first: z.boolean(),
    numberOfElements: z.number().int(),
    empty: z.boolean(),
  })
  .partial()
  .passthrough();
const CaseDtoPage: z.ZodType<CaseDtoPage> = z
  .object({
    content: z.array(CaseDto),
    pageable: PageableObject,
    last: z.boolean(),
    totalPages: z.number().int(),
    totalElements: z.number().int(),
    size: z.number().int(),
    number: z.number().int(),
    sort: z.array(SortObject),
    first: z.boolean(),
    numberOfElements: z.number().int(),
    empty: z.boolean(),
  })
  .partial()
  .passthrough();
const PageAssetInsightActionDto: z.ZodType<PageAssetInsightActionDto> = z
  .object({
    totalPages: z.number().int(),
    totalElements: z.number().int(),
    pageable: PageableObject,
    size: z.number().int(),
    content: z.array(AssetInsightActionDto),
    number: z.number().int(),
    sort: z.array(SortObject),
    first: z.boolean(),
    last: z.boolean(),
    numberOfElements: z.number().int(),
    empty: z.boolean(),
  })
  .partial()
  .passthrough();

export const schemas = {
  AttributeFilters,
  AssetQueryDto,
  UserSavedAssetFilterRequest,
  UserSavedFilterDtoAssetQueryDto,
  InsightAction,
  AssetInsightActionQueryDto,
  InsightMetadataQueryDto,
  InsightQueryDto,
  InsightSeverity,
  AssetInsightQueryDto,
  SortObject,
  PageableObject,
  InsightsSummaryDto,
  CasesSummaryDto,
  WorkordersSummaryDto,
  AssetDto,
  TenantUserDto,
  CaseActionDto,
  InsightCategory,
  InsightMetadataDto,
  MediaDto,
  InsightDto,
  InsightResolutionReason,
  AssetInsightActionDto,
  AssetInsightCommentDto,
  AssetInsightDto,
  CaseNoteDto,
  CaseDto,
  AssetCaseDto,
  PageAssetCaseDto,
  AssetRiskFactorRiskScoreDto,
  PageAssetRiskFactorRiskScoreDto,
  AssetRiskScoreDto,
  PageAssetRiskScoreDto,
  AssetRiskScoreSummaryDto,
  OpenCaseRequest,
  CaseNoteRequest,
  AssetInsightCommentRequest,
  AssetFiltersRequest,
  AssetFiltersDto,
  AdminOpenCaseRequest,
  AssetInsightActionUpdateRequest,
  CloseCaseRequest,
  CloseCaseBulkRequest,
  PageAssetDto,
  TaskDto,
  WorkorderAttributesDto,
  WorkorderDto,
  AssetInsightDtoPage,
  CaseDtoPage,
  PageAssetInsightActionDto,
};

const User_Saved_FiltersEndpoints = makeApi([
  {
    method: 'put',
    path: '/v2/assets/filters/:userSavedFilterId',
    alias: 'updateNamedAssetFilter',
    requestFormat: 'json',
    parameters: [
      {
        name: 'body',
        type: 'Body',
        schema: UserSavedAssetFilterRequest,
      },
      {
        name: 'userSavedFilterId',
        type: 'Path',
        schema: z.string().uuid().describe('The user saved filter id'),
      },
    ],
    response: UserSavedFilterDtoAssetQueryDto,
  },
  {
    method: 'get',
    path: '/v2/assets/filters',
    alias: 'getAllUserSavedFilters',
    requestFormat: 'json',
    response: z.array(UserSavedFilterDtoAssetQueryDto),
  },
  {
    method: 'post',
    path: '/v2/assets/filters',
    alias: 'saveNamedAssetFilter',
    requestFormat: 'json',
    parameters: [
      {
        name: 'body',
        type: 'Body',
        schema: UserSavedAssetFilterRequest,
      },
    ],
    response: UserSavedFilterDtoAssetQueryDto,
  },
  {
    method: 'delete',
    path: '/v2/assets/filters',
    alias: 'deleteNamedFilters',
    requestFormat: 'json',
    parameters: [
      {
        name: 'body',
        type: 'Body',
        schema: z.array(z.string()),
      },
    ],
    response: z.void(),
  },
  {
    method: 'post',
    path: '/assets/filter',
    alias: 'getAssetsByFiltersAndAttributesPost',
    requestFormat: 'json',
    parameters: [
      {
        name: 'body',
        type: 'Body',
        schema: AssetFiltersRequest,
      },
    ],
    response: AssetFiltersDto,
  },
  {
    method: 'post',
    path: '/v1/assets/filter',
    alias: 'getAssetsByFiltersAndAttributesPost_1',
    requestFormat: 'json',
    parameters: [
      {
        name: 'body',
        type: 'Body',
        schema: AssetFiltersRequest,
      },
    ],
    response: AssetFiltersDto,
  },
]);

export const User_Saved_FiltersApi = new Zodios(User_Saved_FiltersEndpoints);

const InsightsEndpoints = makeApi([
  {
    method: 'post',
    path: '/v4/assets/insights',
    alias: 'queryAssetInsightsPaged',
    requestFormat: 'json',
    parameters: [
      {
        name: 'body',
        type: 'Body',
        schema: AssetInsightQueryDto,
      },
      {
        name: 'responseFormat',
        type: 'Query',
        schema: z.enum(['page', 'array']),
      },
      {
        name: 'userSavedFilterId',
        type: 'Query',
        schema: z.string().uuid().describe('The id of the saved asset attribute filter.').optional(),
      },
      {
        name: 'page',
        type: 'Query',
        schema: z
          .number()
          .int()
          .describe('Zero-based page index (0..N) (page ResponseFormat only)')
          .optional()
          .default(0),
      },
      {
        name: 'size',
        type: 'Query',
        schema: z
          .number()
          .int()
          .describe('The size of the page to be returned (page ResponseFormat only)')
          .optional()
          .default(20),
      },
      {
        name: 'sort',
        type: 'Query',
        schema: z
          .array(z.string())
          .describe(
            `Sorting criteria in the format: property(,asc|desc).
 - Default sort order is ascending.
 - Multiple sort criteria are supported.
 - Valid sort values:

    - assetId (alias for asset.asset_id)
    - assetTenantId
    - assetTransactionTs (alias for asset.transaction_ts)
    - asset.attributes.* (example asset.attributes.vin)
    - customerAssetId (alias for asset.customer_asset_id)
    - description (alias of insight_metadata.description)
    - insightCategory (alias of insight_metadata.insight_category);
    - recommendedAction (alias of insight_metadata.recommended_action)
    - riskFactor (alias of insight_metadata.risk_factor)
    - title (alias of insight_metadata.title)
    - endTs (alias for insight.end_ts)
    - inactivityTs (alias for insight.inactivity_ts)
    - insightDuration (alias for insight.insight_duration)
    - lastModifiedTs (GREATEST of asset_insight_action.actioned_ts, insight.end_ts, insight.updated_ts)
    - sinceLastDetected (now()-insight.end_ts)
    - modelName (alias for insight.model_name)
    - modelVersion (alias for insight.model_version)
    - startTs (alias for insight.start_ts)
    - riskScore
    - severity
    - confidence`
          )
          .optional(),
      },
    ],
    response: z.void(),
    errors: [
      {
        status: 400,
        description: `Jwt token has one or more invalid sub claims`,
        schema: z.void(),
      },
    ],
  },
  {
    method: 'post',
    path: '/v1/assets/:assetId/insights',
    alias: 'queryAssetInsightActionsPaged',
    requestFormat: 'json',
    parameters: [
      {
        name: 'body',
        type: 'Body',
        schema: AssetInsightQueryDto,
      },
      {
        name: 'responseFormat',
        type: 'Query',
        schema: z.enum(['page', 'array']),
      },
      {
        name: 'assetId',
        type: 'Path',
        schema: z.string().describe('The asset id'),
      },
      {
        name: 'page',
        type: 'Query',
        schema: z
          .number()
          .int()
          .describe('Zero-based page index (0..N) (page ResponseFormat only)')
          .optional()
          .default(0),
      },
      {
        name: 'size',
        type: 'Query',
        schema: z
          .number()
          .int()
          .describe('The size of the page to be returned (page ResponseFormat only)')
          .optional()
          .default(20),
      },
      {
        name: 'sort',
        type: 'Query',
        schema: z
          .array(z.string())
          .describe(
            `Sorting criteria in the format: property(,asc|desc).
 - Default sort order is ascending.
 - Multiple sort criteria are supported.
 - Valid sort values:

    - assetId (alias for asset.asset_id)
    - assetTenantId
    - assetTransactionTs (alias for asset.transaction_ts)
    - asset.attributes.* (example asset.attributes.vin)
    - customerAssetId (alias for asset.customer_asset_id)
    - description (alias of insight_metadata.description)
    - insightCategory (alias of insight_metadata.insight_category);
    - recommendedAction (alias of insight_metadata.recommended_action)
    - riskFactor (alias of insight_metadata.risk_factor)
    - title (alias of insight_metadata.title)
    - endTs (alias for insight.end_ts)
    - inactivityTs (alias for insight.inactivity_ts)
    - insightDuration (alias for insight.insight_duration)
    - lastModifiedTs (GREATEST of asset_insight_action.actioned_ts, insight.end_ts, insight.updated_ts)
    - sinceLastDetected (now()-insight.end_ts)
    - modelName (alias for insight.model_name)
    - modelVersion (alias for insight.model_version)
    - startTs (alias for insight.start_ts)
    - riskScore
    - severity
    - confidence`
          )
          .optional(),
      },
    ],
    response: z.void(),
    errors: [
      {
        status: 400,
        description: `Jwt token has one or more invalid sub claims`,
        schema: z.void(),
      },
    ],
  },
  {
    method: 'post',
    path: '/v1/assets/:assetId/insights/:assetInsightId/comments',
    alias: 'addAssetInsightComment',
    requestFormat: 'json',
    parameters: [
      {
        name: 'body',
        type: 'Body',
        schema: z.object({ comment: z.string() }).passthrough(),
      },
      {
        name: 'assetId',
        type: 'Path',
        schema: z.string().describe('The asset id'),
      },
      {
        name: 'assetInsightId',
        type: 'Path',
        schema: z.string().uuid().describe('The id of the asset insight to which we are adding a comment'),
      },
    ],
    response: AssetInsightDto,
    errors: [
      {
        status: 404,
        description: `Asset insight not found`,
        schema: z.void(),
      },
    ],
  },
  {
    method: 'post',
    path: '/v1/assets/:assetId/insights/:assetInsightId/comments/:commentId/replies',
    alias: 'replyToAssetInsightComment',
    requestFormat: 'json',
    parameters: [
      {
        name: 'body',
        type: 'Body',
        schema: z.object({ comment: z.string() }).passthrough(),
      },
      {
        name: 'assetId',
        type: 'Path',
        schema: z.string().describe('The asset id'),
      },
      {
        name: 'assetInsightId',
        type: 'Path',
        schema: z.string().uuid().describe('The id of the asset insight to which we are adding a comment reply'),
      },
      {
        name: 'commentId',
        type: 'Path',
        schema: z.string().uuid().describe('The id of the comment to which we are replying'),
      },
    ],
    response: AssetInsightDto,
    errors: [
      {
        status: 404,
        description: `assetInsightId not found`,
        schema: z.void(),
      },
    ],
  },
  {
    method: 'post',
    path: '/v1/assets/:assetId/insights/count',
    alias: 'queryAssetInsightActionsCount',
    requestFormat: 'json',
    parameters: [
      {
        name: 'body',
        type: 'Body',
        schema: AssetInsightQueryDto,
      },
      {
        name: 'assetId',
        type: 'Path',
        schema: z.string().describe('The asset id'),
      },
      {
        name: 'sort',
        type: 'Query',
        schema: z
          .array(z.string())
          .describe(
            `Sorting criteria in the format: property(,asc|desc).
 - Default sort order is ascending.
 - Multiple sort criteria are supported.
 - Valid sort values:

    - assetId (alias for asset.asset_id)
    - assetTenantId
    - assetTransactionTs (alias for asset.transaction_ts)
    - asset.attributes.* (example asset.attributes.vin)
    - customerAssetId (alias for asset.customer_asset_id)
    - description (alias of insight_metadata.description)
    - insightCategory (alias of insight_metadata.insight_category);
    - recommendedAction (alias of insight_metadata.recommended_action)
    - riskFactor (alias of insight_metadata.risk_factor)
    - title (alias of insight_metadata.title)
    - endTs (alias for insight.end_ts)
    - inactivityTs (alias for insight.inactivity_ts)
    - insightDuration (alias for insight.insight_duration)
    - lastModifiedTs (GREATEST of asset_insight_action.actioned_ts, insight.end_ts, insight.updated_ts)
    - sinceLastDetected (now()-insight.end_ts)
    - modelName (alias for insight.model_name)
    - modelVersion (alias for insight.model_version)
    - startTs (alias for insight.start_ts)
    - riskScore
    - severity
    - confidence`
          )
          .optional(),
      },
    ],
    response: z.number().int(),
    errors: [
      {
        status: 400,
        description: `Jwt token has one or more invalid sub claims`,
        schema: z.void(),
      },
    ],
  },
  {
    method: 'post',
    path: '/v1/assets/insights/count',
    alias: 'queryAssetInsightsCount',
    requestFormat: 'json',
    parameters: [
      {
        name: 'body',
        type: 'Body',
        schema: AssetInsightQueryDto,
      },
      {
        name: 'userSavedFilterId',
        type: 'Query',
        schema: z.string().uuid().describe('The id of the saved asset attribute filter.').optional(),
      },
    ],
    response: z.void(),
    errors: [
      {
        status: 400,
        description: `Jwt token has one or more invalid sub claims`,
        schema: z.void(),
      },
    ],
  },
  {
    method: 'post',
    path: '/assets/:assetId/insights/:assetInsightId/action',
    alias: 'applyActionToInsight',
    requestFormat: 'json',
    parameters: [
      {
        name: 'body',
        type: 'Body',
        schema: AssetInsightActionUpdateRequest,
      },
      {
        name: 'assetId',
        type: 'Path',
        schema: z.string().describe('The asset id'),
      },
      {
        name: 'assetInsightId',
        type: 'Path',
        schema: z.string().uuid().describe('The asset insight id'),
      },
    ],
    response: AssetInsightActionDto,
    errors: [
      {
        status: 400,
        description: `Jwt token has one or more invalid sub claims`,
        schema: z.void(),
      },
      {
        status: 403,
        description: `Jwt token does not have any of the required roles: UPTAKE_ADMIN, TENANT_ADMIN or TENANT_WRITE,
to perform this operation`,
        schema: z.void(),
      },
    ],
  },
  {
    method: 'delete',
    path: '/v1/assets/:assetId/insights/:assetInsightId/comments/:commentId',
    alias: 'deleteAssetInsightComment',
    requestFormat: 'json',
    parameters: [
      {
        name: 'assetId',
        type: 'Path',
        schema: z.string().describe('The asset id'),
      },
      {
        name: 'assetInsightId',
        type: 'Path',
        schema: z.string().uuid().describe('The asset insight id'),
      },
      {
        name: 'commentId',
        type: 'Path',
        schema: z.string().uuid().describe('The comment id'),
      },
    ],
    response: z.void(),
  },
  {
    method: 'patch',
    path: '/v1/assets/:assetId/insights/:assetInsightId/comments/:commentId',
    alias: 'editAssetInsightComment',
    requestFormat: 'json',
    parameters: [
      {
        name: 'body',
        type: 'Body',
        schema: z.object({ comment: z.string() }).passthrough(),
      },
      {
        name: 'assetId',
        type: 'Path',
        schema: z.string().describe('The asset id'),
      },
      {
        name: 'assetInsightId',
        type: 'Path',
        schema: z.string().uuid().describe('The asset insight id'),
      },
      {
        name: 'commentId',
        type: 'Path',
        schema: z.string().uuid().describe('The comment id'),
      },
    ],
    response: z.void(),
  },
  {
    method: 'get',
    path: '/v1/insights/metadata/titles',
    alias: 'getInsightTitlesByTenant',
    requestFormat: 'json',
    parameters: [
      {
        name: 'title',
        type: 'Query',
        schema: z.string().describe('The title segment to match').optional(),
      },
    ],
    response: z.array(z.string()),
    errors: [
      {
        status: 400,
        description: `Jwt token has one or more invalid sub claims`,
        schema: z.void(),
      },
    ],
  },
  {
    method: 'get',
    path: '/assets/:assetId/insights/:assetInsightId',
    alias: 'getAssetInsight',
    requestFormat: 'json',
    parameters: [
      {
        name: 'assetId',
        type: 'Path',
        schema: z.string().describe('The asset id'),
      },
      {
        name: 'assetInsightId',
        type: 'Path',
        schema: z.string().uuid().describe('The asset insight id'),
      },
    ],
    response: AssetInsightDto,
    errors: [
      {
        status: 400,
        description: `Jwt token has one or more invalid sub claims`,
        schema: z.void(),
      },
    ],
  },
  {
    method: 'delete',
    path: '/assets/:assetId/insights/:assetInsightId/action/:assetInsightActionId',
    alias: 'undoUpdateReasonToAssetInsightId',
    requestFormat: 'json',
    parameters: [
      {
        name: 'assetId',
        type: 'Path',
        schema: z.string().describe('The asset id'),
      },
      {
        name: 'assetInsightId',
        type: 'Path',
        schema: z.string().uuid().describe('The asset insight id'),
      },
      {
        name: 'assetInsightActionId',
        type: 'Path',
        schema: z.string().uuid().describe('The assetInsightActionId to undo'),
      },
    ],
    response: AssetInsightActionDto,
    errors: [
      {
        status: 400,
        description: `Jwt token has one or more invalid sub claims`,
        schema: z.void(),
      },
      {
        status: 403,
        description: `Jwt token does not have any of the required roles: UPTAKE_ADMIN, TENANT_ADMIN or TENANT_WRITE,
to perform this operation`,
        schema: z.void(),
      },
    ],
  },
  {
    method: 'delete',
    path: '/assets/:assetId/insights/:assetInsightId/action/:assetInsightActionId/undo',
    alias: 'undoUpdateReasonToAssetInsightId_1',
    requestFormat: 'json',
    parameters: [
      {
        name: 'assetId',
        type: 'Path',
        schema: z.string().describe('The asset id'),
      },
      {
        name: 'assetInsightId',
        type: 'Path',
        schema: z.string().uuid().describe('The asset insight id'),
      },
      {
        name: 'assetInsightActionId',
        type: 'Path',
        schema: z.string().uuid().describe('The assetInsightActionId to undo'),
      },
    ],
    response: AssetInsightActionDto,
    errors: [
      {
        status: 400,
        description: `Jwt token has one or more invalid sub claims`,
        schema: z.void(),
      },
      {
        status: 403,
        description: `Jwt token does not have any of the required roles: UPTAKE_ADMIN, TENANT_ADMIN or TENANT_WRITE,
to perform this operation`,
        schema: z.void(),
      },
    ],
  },
]);

export const InsightsApi = new Zodios(InsightsEndpoints);

const CasesEndpoints = makeApi([
  {
    method: 'post',
    path: '/v4/assets/cases',
    alias: 'findCaseAssetsByTenantIdAndAssetAttributes',
    requestFormat: 'json',
    parameters: [
      {
        name: 'body',
        type: 'Body',
        schema: AssetQueryDto,
      },
      {
        name: 'userSavedFilterId',
        type: 'Query',
        schema: z.string().uuid().describe('The id of the saved asset attribute filter.').optional(),
      },
      {
        name: 'page',
        type: 'Query',
        schema: z.number().int().describe('Zero-based page index (0..N)').optional().default(0),
      },
      {
        name: 'size',
        type: 'Query',
        schema: z.number().int().describe('The size of the page to be returned').optional().default(20),
      },
      {
        name: 'sort',
        type: 'Query',
        schema: z
          .array(z.string())
          .describe(
            `Sorting criteria in the format: property(,asc|desc).
 - Default sort order is ascending.
 - Multiple sort criteria are supported.
 - Valid sort values:

    - assetId (alias for asset.asset_id)
    - assetTenantId
    - assetTransactionTs (alias for asset.transaction_ts)
    - asset.attributes.* (example asset.attributes.vin)
    - customerAssetId (alias for asset.customer_asset_id)
    - caseId (alias for case.case_id)
    - caseNum (alias for case.case_num)
    - description (alias for case.description)
    - title (alias for case.title)
    - caseResolution (alias for closed_case_action.case_resolution)
    - closedBy (alias for closed_case_action.actioned_by)
    - closedTs (alias for closed_case_action.actioned_ts)
    - openedBy (alias for opened_case_action.actioned_by)
    - openedTs (alias for opened_case_action.actioned_ts)
    - status`
          )
          .optional(),
      },
    ],
    response: PageAssetCaseDto,
    errors: [
      {
        status: 400,
        description: `Jwt token has one or more invalid sub claims`,
        schema: z.void(),
      },
    ],
  },
  {
    method: 'post',
    path: '/v1/cases',
    alias: 'createCase',
    requestFormat: 'json',
    parameters: [
      {
        name: 'body',
        type: 'Body',
        schema: OpenCaseRequest,
      },
    ],
    response: z.void(),
  },
  {
    method: 'post',
    path: '/v1/cases/:caseId/notes',
    alias: 'addCaseNote',
    requestFormat: 'json',
    parameters: [
      {
        name: 'body',
        description: `hello`,
        type: 'Body',
        schema: z.object({ note: z.string() }).passthrough(),
      },
      {
        name: 'caseId',
        type: 'Path',
        schema: z.string().uuid().describe('The case id'),
      },
    ],
    response: CaseDto,
    errors: [
      {
        status: 404,
        description: `Case not found`,
        schema: z.void(),
      },
    ],
  },
  {
    method: 'post',
    path: '/v1/cases/:caseId/notes/:noteId/replies',
    alias: 'replyToCaseNote',
    requestFormat: 'json',
    parameters: [
      {
        name: 'body',
        type: 'Body',
        schema: z.object({ note: z.string() }).passthrough(),
      },
      {
        name: 'caseId',
        type: 'Path',
        schema: z.string().uuid().describe('The case id'),
      },
      {
        name: 'noteId',
        type: 'Path',
        schema: z.string().uuid().describe('The id of the note to which we are replying'),
      },
    ],
    response: AssetCaseDto,
    errors: [
      {
        status: 404,
        description: `noteId not found`,
        schema: z.void(),
      },
    ],
  },
  {
    method: 'post',
    path: '/v1/cases/:caseId/assetinsights',
    alias: 'addAssetInsightsToCase',
    requestFormat: 'json',
    parameters: [
      {
        name: 'body',
        type: 'Body',
        schema: z.array(z.string()),
      },
      {
        name: 'caseId',
        type: 'Path',
        schema: z.string().uuid().describe('The case id'),
      },
    ],
    response: z.void(),
  },
  {
    method: 'post',
    path: '/v1/cases/:caseId/assetinsight/:assetInsightId',
    alias: 'addAssetInsightToCase',
    requestFormat: 'json',
    parameters: [
      {
        name: 'caseId',
        type: 'Path',
        schema: z.string().uuid().describe('The case id'),
      },
      {
        name: 'assetInsightId',
        type: 'Path',
        schema: z.string().uuid(),
      },
    ],
    response: z.void(),
  },
  {
    method: 'post',
    path: '/v1/admin/cases',
    alias: 'openCase',
    requestFormat: 'json',
    parameters: [
      {
        name: 'body',
        type: 'Body',
        schema: AdminOpenCaseRequest,
      },
    ],
    response: z.void(),
  },
  {
    method: 'delete',
    path: '/v1/cases/:caseId/notes/:noteId',
    alias: 'deleteNotesCase',
    requestFormat: 'json',
    parameters: [
      {
        name: 'caseId',
        type: 'Path',
        schema: z.string().uuid().describe('The case id'),
      },
      {
        name: 'noteId',
        type: 'Path',
        schema: z.string().uuid().describe('The case note id'),
      },
    ],
    response: z.void(),
  },
  {
    method: 'patch',
    path: '/v1/cases/:caseId/notes/:noteId',
    alias: 'editNote',
    requestFormat: 'json',
    parameters: [
      {
        name: 'body',
        type: 'Body',
        schema: z.object({ note: z.string() }).passthrough(),
      },
      {
        name: 'caseId',
        type: 'Path',
        schema: z.string().uuid().describe('The case id'),
      },
      {
        name: 'noteId',
        type: 'Path',
        schema: z.string().uuid().describe('The case note id'),
      },
    ],
    response: z.void(),
  },
  {
    method: 'patch',
    path: '/v1/cases/:caseId/close',
    alias: 'closeCase',
    requestFormat: 'json',
    parameters: [
      {
        name: 'body',
        type: 'Body',
        schema: CloseCaseRequest,
      },
      {
        name: 'caseId',
        type: 'Path',
        schema: z.string().uuid().describe('The case id'),
      },
    ],
    response: z.void(),
    errors: [
      {
        status: 400,
        description: `Jwt token has one or more invalid sub claims`,
        schema: z.void(),
      },
    ],
  },
  {
    method: 'patch',
    path: '/v1/cases/:caseId/close/bulk',
    alias: 'closeCaseBulk',
    requestFormat: 'json',
    parameters: [
      {
        name: 'body',
        type: 'Body',
        schema: CloseCaseBulkRequest,
      },
      {
        name: 'caseId',
        type: 'Path',
        schema: z.string().uuid().describe('The case id'),
      },
    ],
    response: z.void(),
    errors: [
      {
        status: 400,
        description: `Jwt token has one or more invalid sub claims`,
        schema: z.void(),
      },
    ],
  },
  {
    method: 'get',
    path: '/v3/cases',
    alias: 'getModifiedCasesAsPage',
    requestFormat: 'json',
    parameters: [
      {
        name: 'responseFormat',
        type: 'Query',
        schema: z.enum(['page', 'array']),
      },
      {
        name: 'lastModifiedTsLowerBound',
        type: 'Query',
        schema: z
          .string()
          .datetime({ offset: true })
          .describe('The case last modified  timestamp lower bound.')
          .optional(),
      },
      {
        name: 'lastModifiedTsUpperBound',
        type: 'Query',
        schema: z
          .string()
          .datetime({ offset: true })
          .describe('The case last modified  timestamp lower bound.')
          .optional(),
      },
      {
        name: 'sort',
        type: 'Query',
        schema: z
          .array(z.string())
          .describe(
            `Sorting criteria in the format: property(,asc|desc).
 - Default sort order is ascending.
 - Multiple sort criteria are supported.
 - Valid sort values:

- caseId
- tenantId
- title
- description
- lastModifiedTs`
          )
          .optional(),
      },
      {
        name: 'page',
        type: 'Query',
        schema: z
          .number()
          .int()
          .describe('Zero-based page index (0..N) (page ResponseFormat only)')
          .optional()
          .default(0),
      },
      {
        name: 'size',
        type: 'Query',
        schema: z
          .number()
          .int()
          .describe('The size of the page to be returned (page ResponseFormat only)')
          .optional()
          .default(20),
      },
    ],
    response: z.void(),
    errors: [
      {
        status: 400,
        description: `Jwt token has one or more invalid sub claims`,
        schema: z.void(),
      },
    ],
  },
  {
    method: 'get',
    path: '/v1/cases/:caseId',
    alias: 'findCaseById',
    requestFormat: 'json',
    parameters: [
      {
        name: 'caseId',
        type: 'Path',
        schema: z.string().uuid().describe('The case id'),
      },
    ],
    response: CaseDto,
    errors: [
      {
        status: 400,
        description: `Jwt token has one or more invalid sub claims`,
        schema: z.void(),
      },
      {
        status: 404,
        description: `A case was not found for the supplied caseId`,
        schema: z.void(),
      },
    ],
  },
  {
    method: 'get',
    path: '/cases/:caseId',
    alias: 'findCaseById_1',
    requestFormat: 'json',
    parameters: [
      {
        name: 'caseId',
        type: 'Path',
        schema: z.string().uuid().describe('The case id'),
      },
    ],
    response: CaseDto,
    errors: [
      {
        status: 400,
        description: `Jwt token has one or more invalid sub claims`,
        schema: z.void(),
      },
      {
        status: 404,
        description: `A case was not found for the supplied caseId`,
        schema: z.void(),
      },
    ],
  },
  {
    method: 'get',
    path: '/v1/assets/:assetId/cases',
    alias: 'getAssetCases',
    requestFormat: 'json',
    parameters: [
      {
        name: 'assetId',
        type: 'Path',
        schema: z.string().describe('The asset id'),
      },
      {
        name: 'caseState',
        type: 'Query',
        schema: z.enum(['OPEN', 'CLOSED']).optional(),
      },
      {
        name: 'sort',
        type: 'Query',
        schema: z
          .array(z.string())
          .describe(
            `Sorting criteria in the format: property(,asc|desc).
 - Default sort order is ascending.
 - Multiple sort criteria are supported.
 - Valid sort values:

    - caseId (alias for case.case_id)
    - caseNum (alias for case.case_num)
    - description (alias for case.description)
    - title (alias for case.title)
    - caseResolution (alias for closed_case_action.case_resolution)
    - closedBy (alias for closed_case_action.actioned_by)
    - closedTs (alias for closed_case_action.actioned_ts)
    - openedBy (alias for opened_case_action.actioned_by)
    - openedTs (alias for opened_case_action.actioned_ts)
    - status`
          )
          .optional(),
      },
    ],
    response: AssetCaseDto,
    errors: [
      {
        status: 400,
        description: `Jwt token has one or more invalid sub claims`,
        schema: z.void(),
      },
      {
        status: NaN,
        description: `Jwt token has an invalid sub claim.`,
        schema: z.void(),
      },
      {
        status: NaN,
        description: `Jwt token has an invalid tenant_id claim.`,
        schema: z.void(),
      },
    ],
  },
  {
    method: 'get',
    path: '/v1/assets/:assetId/cases/history',
    alias: 'findAssetCaseHistory',
    requestFormat: 'json',
    parameters: [
      {
        name: 'assetId',
        type: 'Path',
        schema: z.string().describe('The asset id'),
      },
      {
        name: 'startTs',
        type: 'Query',
        schema: z.string().datetime({ offset: true }).describe('The case actioned timestamp lower bound.'),
      },
      {
        name: 'endTs',
        type: 'Query',
        schema: z.string().datetime({ offset: true }).describe('The case actioned timestamp upper bound.'),
      },
      {
        name: 'page',
        type: 'Query',
        schema: z.number().int().describe('Zero-based page index (0..N)').optional().default(0),
      },
      {
        name: 'size',
        type: 'Query',
        schema: z.number().int().describe('The size of the page to be returned').optional().default(20),
      },
      {
        name: 'sort',
        type: 'Query',
        schema: z
          .array(z.string())
          .describe(
            `Sorting criteria in the format: property(,asc|desc).
 - Default sort order is ascending.
 - Multiple sort criteria are supported.
 - Valid sort values:

    - caseId (alias for case.case_id)
    - caseNum (alias for case.case_num)
    - description (alias for case.description)
    - title (alias for case.title)
    - caseResolution (alias for closed_case_action.case_resolution)
    - closedBy (alias for closed_case_action.actioned_by)
    - closedTs (alias for closed_case_action.actioned_ts)
    - openedBy (alias for opened_case_action.actioned_by)
    - openedTs (alias for opened_case_action.actioned_ts)
    - status`
          )
          .optional(),
      },
    ],
    response: PageAssetCaseDto,
    errors: [
      {
        status: 400,
        description: `Jwt token has one or more invalid sub claims`,
        schema: z.void(),
      },
      {
        status: NaN,
        description: `Jwt token has an invalid sub claim.`,
        schema: z.void(),
      },
      {
        status: NaN,
        description: `Jwt token has an invalid tenant_id claim.`,
        schema: z.void(),
      },
    ],
  },
  {
    method: 'get',
    path: '/v1/assets/:assetId/cases/active/between',
    alias: 'getAssetCaseActiveOn',
    requestFormat: 'json',
    parameters: [
      {
        name: 'assetId',
        type: 'Path',
        schema: z.string().describe('The asset id'),
      },
      {
        name: 'startTs',
        type: 'Query',
        schema: z.string().datetime({ offset: true }).describe('The case actioned timestamp lower bound.'),
      },
      {
        name: 'endTs',
        type: 'Query',
        schema: z.string().datetime({ offset: true }).describe('The case actioned timestamp upper bound.'),
      },
      {
        name: 'sort',
        type: 'Query',
        schema: z
          .array(z.string())
          .describe(
            `Sorting criteria in the format: property(,asc|desc).
 - Default sort order is ascending.
 - Multiple sort criteria are supported.
 - Valid sort values:

    - caseId (alias for case.case_id)
    - caseNum (alias for case.case_num)
    - description (alias for case.description)
    - title (alias for case.title)
    - caseResolution (alias for closed_case_action.case_resolution)
    - closedBy (alias for closed_case_action.actioned_by)
    - closedTs (alias for closed_case_action.actioned_ts)
    - openedBy (alias for opened_case_action.actioned_by)
    - openedTs (alias for opened_case_action.actioned_ts)
    - status`
          )
          .optional(),
      },
    ],
    response: AssetCaseDto,
    errors: [
      {
        status: 400,
        description: `Jwt token has one or more invalid sub claims`,
        schema: z.void(),
      },
      {
        status: NaN,
        description: `Jwt token has an invalid sub claim.`,
        schema: z.void(),
      },
      {
        status: NaN,
        description: `Jwt token has an invalid tenant_id claim.`,
        schema: z.void(),
      },
    ],
  },
  {
    method: 'delete',
    path: '/v1/cases/:caseId/assetinsights/:assetInsightId',
    alias: 'removeAssetInsightFromCase',
    requestFormat: 'json',
    parameters: [
      {
        name: 'caseId',
        type: 'Path',
        schema: z.string().uuid().describe('The case id'),
      },
      {
        name: 'assetInsightId',
        type: 'Path',
        schema: z.string().uuid().describe('The assetInsightId'),
      },
    ],
    response: z.void(),
  },
]);

export const CasesApi = new Zodios(CasesEndpoints);

const RiskFactorsEndpoints = makeApi([
  {
    method: 'post',
    path: '/v2/assets/riskfactors/riskscores',
    alias: 'getTopRiskFactors',
    requestFormat: 'json',
    parameters: [
      {
        name: 'body',
        type: 'Body',
        schema: AssetQueryDto,
      },
      {
        name: 'activeOnTs',
        type: 'Query',
        schema: z.string().datetime({ offset: true }).describe('Asset state on this date').optional(),
      },
      {
        name: 'veryHigh',
        type: 'Query',
        schema: z.number().int().describe('The assets >= [high] threshold sent'),
      },
      {
        name: 'high',
        type: 'Query',
        schema: z.number().int().describe('The assets >= [medium] and < [high] threshold sent'),
      },
      {
        name: 'medium',
        type: 'Query',
        schema: z.number().int().describe('The assets >= [low] and < [medium] threshold sent'),
      },
      {
        name: 'low',
        type: 'Query',
        schema: z.number().int().describe('The assets >= [veryLow] and < [low] threshold sent'),
      },
      {
        name: 'userSavedFilterId',
        type: 'Query',
        schema: z.string().uuid().describe('The id of the saved asset attribute filter.').optional(),
      },
      {
        name: 'page',
        type: 'Query',
        schema: z.number().int().describe('Zero-based page index (0..N)').optional().default(0),
      },
      {
        name: 'size',
        type: 'Query',
        schema: z.number().int().describe('The size of the page to be returned').optional().default(20),
      },
      {
        name: 'sort',
        type: 'Query',
        schema: z
          .array(z.string())
          .describe(
            `Sorting criteria in the format: property(,asc|desc).
 - Default sort order is ascending.
 - Multiple sort criteria are supported.
 - Valid sort values:

    - assetId (alias for asset.asset_id)
    - assetTenantId
    - assetTransactionTs (alias for asset.transaction_ts)
    - asset.attributes.* (example asset.attributes.vin)
    - customerAssetId (alias for asset.customer_asset_id)
    - category
    - lastUpdatedTs (alias for last_updated_ts)
    - riskFactor (alias for risk_factor)
    - riskScore (alias for risk_score)`
          )
          .optional(),
      },
    ],
    response: PageAssetRiskFactorRiskScoreDto,
    errors: [
      {
        status: 400,
        description: `Jwt token has one or more invalid sub claims`,
        schema: z.void(),
      },
    ],
  },
  {
    method: 'get',
    path: '/assets/:assetId/riskfactors/riskscores',
    alias: 'getAssetRiskFactorRiskScores',
    requestFormat: 'json',
    parameters: [
      {
        name: 'assetId',
        type: 'Path',
        schema: z.string().describe('The asset id'),
      },
      {
        name: 'activeOnTs',
        type: 'Query',
        schema: z.string().datetime({ offset: true }).describe('Asset state on this date').optional(),
      },
      {
        name: 'page',
        type: 'Query',
        schema: z.number().int().describe('Zero-based page index (0..N)').optional().default(0),
      },
      {
        name: 'size',
        type: 'Query',
        schema: z.number().int().describe('The size of the page to be returned').optional().default(20),
      },
      {
        name: 'sort',
        type: 'Query',
        schema: z
          .array(z.string())
          .describe(
            `Sorting criteria in the format: property(,asc|desc).
 - Default sort order is ascending.
 - Multiple sort criteria are supported.
 - Valid sort values:

    - assetId (alias for asset.asset_id)
    - assetTenantId
    - assetTransactionTs (alias for asset.transaction_ts)
    - asset.attributes.* (example asset.attributes.vin)
    - customerAssetId (alias for asset.customer_asset_id)
    - category
    - lastUpdatedTs (alias for last_updated_ts)
    - riskFactor (alias for risk_factor)
    - riskScore (alias for risk_score)`
          )
          .optional(),
      },
    ],
    response: PageAssetRiskFactorRiskScoreDto,
  },
]);

export const RiskFactorsApi = new Zodios(RiskFactorsEndpoints);

const AssetsEndpoints = makeApi([
  {
    method: 'post',
    path: '/v2/assets/risk/scores',
    alias: 'getAssetRiskScores',
    requestFormat: 'json',
    parameters: [
      {
        name: 'body',
        type: 'Body',
        schema: AssetQueryDto,
      },
      {
        name: 'activeOnTs',
        type: 'Query',
        schema: z.string().datetime({ offset: true }).describe('Asset state on this date').optional(),
      },
      {
        name: 'userSavedFilterId',
        type: 'Query',
        schema: z.string().uuid().describe('The id of the saved asset attribute filter.').optional(),
      },
      {
        name: 'page',
        type: 'Query',
        schema: z.number().int().describe('Zero-based page index (0..N)').optional().default(0),
      },
      {
        name: 'size',
        type: 'Query',
        schema: z.number().int().describe('The size of the page to be returned').optional().default(20),
      },
      {
        name: 'sort',
        type: 'Query',
        schema: z
          .array(z.string())
          .describe(
            `Sorting criteria in the format: property(,asc|desc).
 - Default sort order is ascending.
 - Multiple sort criteria are supported.
 - Valid sort values:

    - assetId (alias for asset.asset_id)
    - assetTenantId
    - assetTransactionTs (alias for asset.transaction_ts)
    - asset.attributes.* (example asset.attributes.vin)
    - customerAssetId (alias for asset.customer_asset_id)
    - assetRiskScore (alias for asset_risk_score)
    - assetRiskScoreTs (alias asset_risk_score_ts)`
          )
          .optional(),
      },
    ],
    response: PageAssetRiskScoreDto,
    errors: [
      {
        status: 400,
        description: `Jwt token has one or more invalid sub claims`,
        schema: z.void(),
      },
    ],
  },
  {
    method: 'post',
    path: '/v2/assets/risk/scores/summary',
    alias: 'queryAssetRiskScoreSummary',
    requestFormat: 'json',
    parameters: [
      {
        name: 'body',
        type: 'Body',
        schema: AssetQueryDto,
      },
      {
        name: 'activeOnTs',
        type: 'Query',
        schema: z.string().datetime({ offset: true }).describe('Asset state on this date').optional(),
      },
      {
        name: 'veryHigh',
        type: 'Query',
        schema: z.number().int().describe('The assets >= [high] threshold sent'),
      },
      {
        name: 'high',
        type: 'Query',
        schema: z.number().int().describe('The assets >= [medium] and < [high] threshold sent'),
      },
      {
        name: 'medium',
        type: 'Query',
        schema: z.number().int().describe('The assets >= [low] and < [medium] threshold sent'),
      },
      {
        name: 'low',
        type: 'Query',
        schema: z.number().int().describe('The assets >= [veryLow] and < [low] threshold sent'),
      },
      {
        name: 'userSavedFilterId',
        type: 'Query',
        schema: z.string().uuid().describe('The id of the saved asset attribute filter.').optional(),
      },
    ],
    response: AssetRiskScoreSummaryDto,
    errors: [
      {
        status: 400,
        description: `Jwt token has one or more invalid sub claims`,
        schema: z.void(),
      },
    ],
  },
  {
    method: 'get',
    path: '/v2/assets/count',
    alias: 'getAssetCountsByAttributes',
    requestFormat: 'json',
    parameters: [
      {
        name: 'userSavedFilterId',
        type: 'Query',
        schema: z.string().uuid().describe('The id of the saved asset attribute filter.').optional(),
      },
    ],
    response: z.number().int(),
    errors: [
      {
        status: 400,
        description: `Jwt token has one or more invalid sub claims`,
        schema: z.void(),
      },
    ],
  },
  {
    method: 'post',
    path: '/v2/assets/count',
    alias: 'queryAssetCountsByAttributes',
    requestFormat: 'json',
    parameters: [
      {
        name: 'body',
        type: 'Body',
        schema: AssetQueryDto,
      },
    ],
    response: z.number().int(),
    errors: [
      {
        status: 400,
        description: `Jwt token has one or more invalid sub claims`,
        schema: z.void(),
      },
    ],
  },
  {
    method: 'get',
    path: '/v2/assets',
    alias: 'getAssetsByTenantIdAndAttributes',
    requestFormat: 'json',
    parameters: [
      {
        name: 'userSavedFilterId',
        type: 'Query',
        schema: z.string().uuid().describe('The id of the saved asset attribute filter.').optional(),
      },
      {
        name: 'page',
        type: 'Query',
        schema: z.number().int().describe('Zero-based page index (0..N)').optional().default(0),
      },
      {
        name: 'size',
        type: 'Query',
        schema: z.number().int().describe('The size of the page to be returned').optional().default(20),
      },
      {
        name: 'sort',
        type: 'Query',
        schema: z
          .array(z.string())
          .describe(
            `Sorting criteria in the format: property(,asc|desc).
 - Default sort order is ascending.
 - Multiple sort criteria are supported.
 - Valid sort values:

    - assetId (alias for asset.asset_id)
    - assetTenantId
    - assetTransactionTs (alias for asset.transaction_ts)
    - asset.attributes.* (example asset.attributes.vin)
    - customerAssetId (alias for asset.customer_asset_id)`
          )
          .optional(),
      },
      {
        name: 'assetAttributePatternFilters',
        type: 'Query',
        schema: z.string().describe('The asset attributes patterns filter by.').optional(),
      },
    ],
    response: PageAssetDto,
    errors: [
      {
        status: 400,
        description: `Jwt token has one or more invalid sub claims`,
        schema: z.void(),
      },
    ],
  },
  {
    method: 'get',
    path: '/assets/:assetId/summary',
    alias: 'getAssetSummary',
    requestFormat: 'json',
    parameters: [
      {
        name: 'assetId',
        type: 'Path',
        schema: z.string().describe('The asset id'),
      },
      {
        name: 'activeOnTs',
        type: 'Query',
        schema: z.string().datetime({ offset: true }).describe('Asset state on this date').optional(),
      },
    ],
    response: AssetDto,
    errors: [
      {
        status: 400,
        description: `Jwt token has one or more invalid sub claims`,
        schema: z.void(),
      },
    ],
  },
]);

export const AssetsApi = new Zodios(AssetsEndpoints);

const Work_OrdersEndpoints = makeApi([
  {
    method: 'get',
    path: '/v1/assets/:assetId/workorders/:workorderId',
    alias: 'getWorkorder',
    requestFormat: 'json',
    parameters: [
      {
        name: 'assetId',
        type: 'Path',
        schema: z.string().describe('The asset id'),
      },
      {
        name: 'workorderId',
        type: 'Path',
        schema: z.string().describe('The workorder id'),
      },
    ],
    response: WorkorderDto,
  },
  {
    method: 'get',
    path: '/assets/:assetId/workorders',
    alias: 'getAssetWorkorders',
    requestFormat: 'json',
    parameters: [
      {
        name: 'assetId',
        type: 'Path',
        schema: z.string().describe('The asset id'),
      },
      {
        name: 'page',
        type: 'Query',
        schema: z.number().int().describe('Zero-based page index (0..N)').optional().default(0),
      },
      {
        name: 'size',
        type: 'Query',
        schema: z.number().int().describe('The size of the page to be returned').optional().default(20),
      },
      {
        name: 'sort',
        type: 'Query',
        schema: z
          .array(z.string())
          .describe(
            `Sorting criteria in the format: property(,asc|desc).
 - Default sort order is ascending.
 - Multiple sort criteria are supported.
 - Valid sort values:

   - workorderId
   - workorderTenantId
   - customerWorkorderId
   - openedTs
   - closedTs
   - summary
   - workDescription
   - caseId
   - lastModifiedTs`
          )
          .optional(),
      },
    ],
    response: WorkorderDto,
  },
  {
    method: 'get',
    path: '/assets/:assetId/workorders/history',
    alias: 'getAssetWorkorderHistory',
    requestFormat: 'json',
    parameters: [
      {
        name: 'assetId',
        type: 'Path',
        schema: z.string().describe('The asset id'),
      },
      {
        name: 'openedTsLowerBound',
        type: 'Query',
        schema: z.string().describe('The workorder opened timestamp lower bound'),
      },
      {
        name: 'openedTsUpperBound',
        type: 'Query',
        schema: z.string().describe('The workorder closed timestamp upper bound'),
      },
      {
        name: 'page',
        type: 'Query',
        schema: z.number().int().describe('Zero-based page index (0..N)').optional().default(0),
      },
      {
        name: 'size',
        type: 'Query',
        schema: z.number().int().describe('The size of the page to be returned').optional().default(20),
      },
      {
        name: 'sort',
        type: 'Query',
        schema: z
          .array(z.string())
          .describe(
            `Sorting criteria in the format: property(,asc|desc).
 - Default sort order is ascending.
 - Multiple sort criteria are supported.
 - Valid sort values:

   - workorderId
   - workorderTenantId
   - customerWorkorderId
   - openedTs
   - closedTs
   - summary
   - workDescription
   - caseId
   - lastModifiedTs`
          )
          .optional(),
      },
    ],
    response: WorkorderDto,
  },
  {
    method: 'get',
    path: '/assets/:assetId/workorders/count',
    alias: 'getAssetWorkorderCount',
    requestFormat: 'json',
    parameters: [
      {
        name: 'assetId',
        type: 'Path',
        schema: z.string().describe('The asset id'),
      },
    ],
    response: WorkorderDto,
  },
]);

export const Work_OrdersApi = new Zodios(Work_OrdersEndpoints);

export function createApiClient(baseUrl: string, options?: ZodiosOptions) {
  return new Zodios(baseUrl, endpoints, options);
}
