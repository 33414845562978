import { cookieUtils } from '@/utils/cookieUtils';
import { isDefined } from './objectUtils';

export abstract class StorageUtils {
  private static readonly cookieManager = cookieUtils();
  private static defaultCookieExpirationTime: number = 10; // set expiration time in days

  static hasCookie(name: string): boolean {
    const cookie = this.getCookie(name);
    return isDefined(cookie);
  }

  static deleteCookies(...cookies: string[]): void {
    for (const cookie of cookies) {
      this.cookieManager.deleteCookie(cookie);
    }
  }

  static applyCookie(name: string, value: string, expirationTime: number = this.defaultCookieExpirationTime): void {
    this.cookieManager.setCookie(name, value, expirationTime);
  }

  static getCookie(name: string): string | undefined | null {
    return this.cookieManager.getCookie(name);
  }
}
