import Keycloak from 'keycloak-js';
import useAuthStore from '@/features/auth/store/authStore'
import {  authUrl, clientId } from './options';
import { useLocalStorage } from '@/utils/localStorageUtils';

const realm = import.meta.env.VITE_SINGLETENANT_REALM_NAME;

const params: any = {};
const locationUrl = new URL(location.toString());
for (const key of locationUrl.searchParams.keys()) {
  params[key] = locationUrl.searchParams.get(key);
}

export function checkRealm() {
  console.log('checkRealm')
  try {
    if(useLocalStorage().product === 'federal') {
      return realm;
    } else if(params['realm']){
      return params['realm'];
    } else if(localStorage.getItem('realm')) {
      return localStorage.getItem('realm')
    } else {
      window.location.href = authUrl + '/?redirect_uri=' + window.location;
      return
    }
  } catch (error) {
    window.location.href = authUrl + '/?redirect_uri=' + window.location;
  }
}

export const keycloak = new Keycloak({
  realm: useLocalStorage().product === 'federal' ? realm : params['realm'],
  url: authUrl + '/auth/',
  clientId,
});

export function buildBearerToken() {
  const bearerPrefix = 'Bearer ';
  const { token } = keycloak;
  return bearerPrefix + token;
}

keycloak.onTokenExpired = () => {
  if (keycloak.refreshToken) {
    keycloak
      .updateToken(100)
      .then((success) => {
        const authStore = useAuthStore();
        if (success) {
          authStore.token = buildBearerToken();
        } else {
          localStorage.removeItem('realm')
          keycloak.logout();
        }
      })
      .catch((error) => {
        console.error(error);
        localStorage.removeItem('realm')
        keycloak.logout();
      });
  } else {
    localStorage.removeItem('realm')
    keycloak.logout();
  }
};