import { makeApi, Zodios } from "@zodios/core";
import { z } from "zod";

type TenantConfigInsightPreferenceDto = {
    insightMetadataKey: string;
    autoCreateCase?: boolean | undefined;
    autoTransmitWorkorder?: boolean | undefined;
    visibleInUi?: boolean | undefined;
    severity?: InsightSeverity | undefined;
};
type InsightSeverity = /**
 * The insight severity
 *
 * @example "CATASTROPHIC"
 * @enum NEGLIGIBLE, MARGINAL, CRITICAL, CATASTROPHIC
 */
"NEGLIGIBLE" | "MARGINAL" | "CRITICAL" | "CATASTROPHIC";
type TenantConfigInsightPreferenceRequest = Partial<{
    tenantInsightPreferences: Array<TenantConfigInsightPreferenceDto>;
}>;
type InsightPreferencesDto = {
    /**
     * The insight metadata key
     *
     * @example "insight_metadata|FLEET_PREDICTIVE_CHECK_ENGINE_LIGHT"
     */
    insightMetadataKey: string;
    insightTitle?: string | undefined;
    insightDescription?: string | undefined;
    subsystem?: string | undefined;
    insightType?: string | undefined;
    autoCreateCase?: boolean | undefined;
    autoTransmitWorkorder?: boolean | undefined;
    visibleInUi?: boolean | undefined;
    severity?: InsightSeverity | undefined;
    insightSeverity?: InsightSeverity | undefined;
};
type MergedConfigurationDto = Partial<{
    /**
     * The source configurations used to generate this configuration
     */
    configurationSources: Array<MergedConfigurationSource>;
    /**
     * A configuration's domain id coordinate
     *
     * @example "frontend"
     */
    domainId: string;
    /**
     * A configuration's product id coordinate
     *
     * @example "fleet"
     */
    productId: string;
    /**
     * A configuration's app id coordinate
     *
     * @example "web-ui"
     */
    appId: string;
    /**
     * A configuration's component id coordinate
     *
     * @example "asset-details"
     */
    componentId: string;
    /**
     * A configuration's subcomponent id coordinate
     *
     * @example "asset-details-table-hover"
     */
    subcomponentId: string;
    /**
     * The configuration object
     */
    config: {};
    /**
     * DEPRECATED use config. The configuration object
     *
     * @deprecated
     */
    attributes: {};
}>;
type MergedConfigurationSource = {
    /**
     * The tenantId
     *
     * @example "lncm-trucking"
     * @pattern ^(?=[a-z0-9-]{3,36}$)[a-z0-9]+(-[a-z0-9]+){0,17}$
     */
    tenantId: string;
    inheritanceStrategy?: (/**
     * The configuration's inheritency strategy
     *
     * @enum INHERIT, NONE, OVERRIDE
     */
    "INHERIT" | "NONE" | "OVERRIDE") | undefined;
    configurationId?: /**
     * A configuration id
     */
    string | undefined;
};
type TenantDto = {
    /**
     * The tenantId
     *
     * @example "lncm-trucking"
     * @pattern ^(?=[a-z0-9-]{3,36}$)[a-z0-9]+(-[a-z0-9]+){0,17}$
     */
    tenantId: string;
    salesforceId?: /**
     * The salesforce id
     *
     * @example "0015500000WOHciAAH"
     * @pattern ^[a-zA-Z0-9]{18}|[a-zA-Z0-9]{15}$
     */
    string | undefined;
    /**
     * The tenant's parent id
     *
     * @example "geotab"
     * @pattern ^(?=[a-z0-9-]{3,36}$)[a-z0-9]+(-[a-z0-9]+){0,17}$
     */
    parentTenantId: string;
    parentTenant?: TenantDto | undefined;
    childTenants?: /**
     * The tenant's children
     */
    Array<TenantDto> | undefined;
    childTenantLimit?: /**
     * The number of child tenants a parent tenant is allowed to have.
     *
     * @minimum 0
     */
    number | undefined;
    tenantAttributes?: /**
     * The tenant's attributes
     */
    {} | undefined;
    partner?: /**
     * If the tenant is an affinity partner
     */
    boolean | undefined;
    deletedTs?: /**
     * When the tenant was soft-deleted
     */
    string | undefined;
};
type CreateSubcomponentRequest = Partial<{
    jsonSchema: JsonSchema;
}>;
type SchemaLocation = Partial<{
    absoluteIri: AbsoluteIri;
    fragment: JsonNodePath;
}>;
type AbsoluteIri = Partial<{
    scheme: string;
}>;
type JsonNode = {};
type JsonMetaSchema = Partial<{
    iri: string;
    idKeyword: string;
    keywords: {};
    vocabularies: {};
    /**
     * @enum V4, V6, V7, V201909, V202012
     */
    specification: "V4" | "V6" | "V7" | "V201909" | "V202012";
}>;
type Keyword = Partial<{
    value: string;
}>;
type JsonSchemaFactory = Partial<{
    schemaLoader: SchemaLoader;
}>;
type SchemaLoader = {};
type SchemaValidatorsConfig = Partial<{
    applyDefaultsStrategy: ApplyDefaultsStrategy;
    cacheRefs: boolean;
    errorMessageKeyword: string;
    executionContextCustomizer: ExecutionContextCustomizer;
    failFast: boolean;
    /**
     * @deprecated
     */
    formatAssertionsEnabled: boolean;
    nullableKeywordEnabled: boolean;
    javaSemantics: boolean;
    keywordWalkListenersMap: {};
    /**
     * @deprecated
     */
    locale: Partial<{
        language: string;
        displayName: string;
        country: string;
        variant: string;
        script: string;
        unicodeLocaleAttributes: Array<string>;
        unicodeLocaleKeys: Array<string>;
        displayLanguage: string;
        displayScript: string;
        displayCountry: string;
        displayVariant: string;
        extensionKeys: Array<string>;
        iso3Language: string;
        iso3Country: string;
    }>;
    losslessNarrowing: boolean;
    messageSource: MessageSource;
    discriminatorKeywordEnabled: boolean;
    /**
     * @enum LEGACY, JSON_PATH, JSON_POINTER, URI_REFERENCE
     */
    pathType: "LEGACY" | "JSON_PATH" | "JSON_POINTER" | "URI_REFERENCE";
    preloadJsonSchema: boolean;
    preloadJsonSchemaRefMaxNestingDepth: number;
    propertyWalkListeners: Array<JsonSchemaWalkListener>;
    readOnly: boolean;
    regularExpressionFactory: RegularExpressionFactory;
    schemaIdValidator: JsonSchemaIdValidator;
    typeLoose: boolean;
    writeOnly: boolean;
    /**
     * @deprecated
     */
    loadCollectors: boolean;
    arrayItemWalkListeners: Array<JsonSchemaWalkListener>;
    /**
     * @deprecated
     */
    customMessageSupported: boolean;
    ecma262Validator: boolean;
    /**
     * @deprecated
     */
    handleNullableField: boolean;
    /**
     * @deprecated
     */
    openAPI3StyleDiscriminators: boolean;
    /**
     * @deprecated
     */
    writeMode: boolean;
    /**
     * @deprecated
     */
    unevaluatedItemsAnalysisDisabled: boolean;
    /**
     * @deprecated
     */
    unevaluatedItemsAnalysisEnabled: boolean;
    /**
     * @deprecated
     */
    unevaluatedPropertiesAnalysisDisabled: boolean;
    /**
     * @deprecated
     */
    unevaluatedPropertiesAnalysisEnabled: boolean;
}>;
type ApplyDefaultsStrategy = {};
type ExecutionContextCustomizer = {};
type JsonSchemaWalkListener = {};
type MessageSource = {};
type RegularExpressionFactory = {};
type JsonSchemaIdValidator = {};
type JsonValidator = Partial<{
    schemaLocation: SchemaLocation;
    evaluationPath: JsonNodePath;
    keyword: string;
}>;
type JsonNodePath = Partial<{
    parent: JsonNodePath;
    nameCount: number;
    /**
     * @enum LEGACY, JSON_PATH, JSON_POINTER, URI_REFERENCE
     */
    pathType: "LEGACY" | "JSON_PATH" | "JSON_POINTER" | "URI_REFERENCE";
}>;
type JsonSchema = Partial<{
    keyword: string;
    parentSchema: JsonSchema;
    schemaLocation: SchemaLocation;
    evaluationPath: JsonNodePath;
    evaluationParentSchema: JsonSchema;
    schemaNode: JsonNode;
    validationContext: ValidationContext;
    id: string;
    validators: Array<JsonValidator>;
    recursiveAnchor: boolean;
    typeValidator: TypeValidator;
    schemaResourceRoot: boolean;
}>;
type TypeValidator = Partial<{
    keyword: string;
    parentSchema: JsonSchema;
    schemaLocation: SchemaLocation;
    evaluationPath: JsonNodePath;
    evaluationParentSchema: JsonSchema;
    schemaNode: JsonNode;
    /**
     * @enum object, array, string, number, integer, boolean, null, any, unknown, union
     */
    schemaType: "object" | "array" | "string" | "number" | "integer" | "boolean" | "null" | "any" | "unknown" | "union";
}>;
type ValidationContext = Partial<{
    metaSchema: JsonMetaSchema;
    jsonSchemaFactory: JsonSchemaFactory;
    config: SchemaValidatorsConfig;
    schemaReferences: {};
    schemaResources: {};
    dynamicAnchors: {};
}>;
type SubcomponentDto = Partial<{
    /**
     * A configuration's subcomponent id coordinate
     *
     * @example "asset-details-table-hover"
     */
    subcomponentId: string;
    jsonSchema: JsonSchema;
}>;
type CreateComponentRequest = Partial<{
    /**
     * A component's parent component id
     *
     * @example "asset-details-table"
     */
    parentComponentId: string;
    jsonSchema: JsonSchema;
}>;
type ComponentDto = Partial<{
    /**
     * A configuration's component id coordinate
     *
     * @example "asset-details"
     */
    componentId: string;
    parentComponent: ComponentDto;
    jsonSchema: JsonSchema;
}>;
type UpdateSubcomponentRequest = Partial<{
    jsonSchema: JsonSchema;
}>;
type UpdateComponentRequest = Partial<{
    /**
     * A component's parent component id
     *
     * @example "asset-details-table"
     */
    parentComponentId: string;
    jsonSchema: JsonSchema;
}>;

const ConfigurationDto = z.object({ configurationId: z.string().uuid().describe("A configuration id").optional(), tenantId: z.string().regex(/^(?=[a-z0-9-]{3,36}$)[a-z0-9]+(-[a-z0-9]+){0,17}$/).describe("The tenantId"), domainId: z.string().describe("A configuration's domain id coordinate").optional(), productId: z.string().describe("A configuration's product id coordinate").optional(), appId: z.string().describe("A configuration's app id coordinate").optional(), componentId: z.string().describe("A configuration's component id coordinate").optional(), subcomponentId: z.string().describe("A configuration's subcomponent id coordinate").optional(), inheritanceStrategy: z.enum(["INHERIT", "NONE", "OVERRIDE"]).describe("The configuration's inheritency strategy").optional(), config: z.record(z.object({}).partial().passthrough().describe("The configuration object")).describe("The configuration object").optional(), attributes: z.record(z.object({}).partial().passthrough().describe("DEPRECATED use config. The configuration object")).describe("DEPRECATED use config. The configuration object").optional() }).passthrough();
const UpdateConfigurationRequest = z.object({ config: z.record(z.object({}).partial().passthrough().describe("The configuration object")).describe("The configuration object"), attributes: z.record(z.object({}).partial().passthrough().describe("DEPRECATED use config. The configuration object")).describe("DEPRECATED use config. The configuration object").optional() }).passthrough();
const AutoOpenCaseConfigDto = z.object({ autoOpenCaseTitleTemplate: z.string(), autoOpenCaseDescriptionTemplate: z.string() }).partial().passthrough();
const AdminUpdateConfigurationRequest = z.object({ tenantId: z.string(), inheritanceStrategy: z.enum(["INHERIT", "NONE", "OVERRIDE"]).optional(), config: z.record(z.object({}).partial().passthrough().describe("The configuration object")).describe("The configuration object"), attributes: z.record(z.object({}).partial().passthrough().describe("DEPRECATED use config. The configuration object")).describe("DEPRECATED use config. The configuration object").optional() }).passthrough();
const InsightSeverity = z.enum(["NEGLIGIBLE", "MARGINAL", "CRITICAL", "CATASTROPHIC"]);
const InsightPreferencesDto: z.ZodType<InsightPreferencesDto> = z.object({ insightMetadataKey: z.string().describe("The insight metadata key"), insightTitle: z.string().optional(), insightDescription: z.string().optional(), subsystem: z.string().optional(), insightType: z.string().optional(), autoCreateCase: z.boolean().optional(), autoTransmitWorkorder: z.boolean().optional(), visibleInUi: z.boolean().optional(), severity: InsightSeverity.describe("The insight severity").optional(), insightSeverity: InsightSeverity.describe("The insight severity").optional() }).passthrough();
const TenantConfigInsightPreferenceDto: z.ZodType<TenantConfigInsightPreferenceDto> = z.object({ insightMetadataKey: z.string(), autoCreateCase: z.boolean().optional(), autoTransmitWorkorder: z.boolean().optional(), visibleInUi: z.boolean().optional(), severity: InsightSeverity.describe("The insight severity").optional() }).passthrough();
const TenantConfigInsightPreferenceRequest: z.ZodType<TenantConfigInsightPreferenceRequest> = z.object({ tenantInsightPreferences: z.array(TenantConfigInsightPreferenceDto) }).partial().passthrough();
const CreateConfigurationRequest = z.object({ productId: z.string().describe("A configuration's product id coordinate").optional(), domainId: z.string().describe("A configuration's domain id coordinate").optional(), appId: z.string().describe("A configuration's app id coordinate").optional(), componentId: z.string().describe("A configuration's component id coordinate").optional(), subcomponentId: z.string().describe("A configuration's subcomponent id coordinate").optional(), config: z.record(z.object({}).partial().passthrough().describe("The configuration object")).describe("The configuration object"), attributes: z.record(z.object({}).partial().passthrough().describe("DEPRECATED use config. The configuration object")).describe("DEPRECATED use config. The configuration object").optional() }).passthrough();
const MergedConfigurationSource: z.ZodType<MergedConfigurationSource> = z.object({ tenantId: z.string().regex(/^(?=[a-z0-9-]{3,36}$)[a-z0-9]+(-[a-z0-9]+){0,17}$/).describe("The tenantId"), inheritanceStrategy: z.enum(["INHERIT", "NONE", "OVERRIDE"]).describe("The configuration's inheritency strategy").optional(), configurationId: z.string().uuid().describe("A configuration id").optional() }).passthrough();
const MergedConfigurationDto: z.ZodType<MergedConfigurationDto> = z.object({ configurationSources: z.array(MergedConfigurationSource).describe("The source configurations used to generate this configuration"), domainId: z.string().describe("A configuration's domain id coordinate"), productId: z.string().describe("A configuration's product id coordinate"), appId: z.string().describe("A configuration's app id coordinate"), componentId: z.string().describe("A configuration's component id coordinate"), subcomponentId: z.string().describe("A configuration's subcomponent id coordinate"), config: z.record(z.object({}).partial().passthrough().describe("The configuration object")).describe("The configuration object"), attributes: z.record(z.object({}).partial().passthrough().describe("DEPRECATED use config. The configuration object")).describe("DEPRECATED use config. The configuration object") }).partial().passthrough();
const TenantDto: z.ZodType<TenantDto> = z.lazy(() => z.object({ tenantId: z.string().regex(/^(?=[a-z0-9-]{3,36}$)[a-z0-9]+(-[a-z0-9]+){0,17}$/).describe("The tenantId"), salesforceId: z.string().regex(/^[a-zA-Z0-9]{18}|[a-zA-Z0-9]{15}$/).describe("The salesforce id").optional(), parentTenantId: z.string().regex(/^(?=[a-z0-9-]{3,36}$)[a-z0-9]+(-[a-z0-9]+){0,17}$/).describe("The tenant's parent id"), parentTenant: TenantDto.describe("Tenant Config API's tenant detail dto").optional(), childTenants: z.array(TenantDto).describe("The tenant's children").optional(), childTenantLimit: z.number().int().gte(0).describe("The number of child tenants a parent tenant is allowed to have.").optional(), tenantAttributes: z.record(z.object({}).partial().passthrough().describe("The tenant's attributes")).describe("The tenant's attributes").optional(), partner: z.boolean().describe("If the tenant is an affinity partner").optional(), deletedTs: z.string().datetime({ offset: true }).describe("When the tenant was soft-deleted").optional() }).passthrough());
const CreateTenantConfigRequest = z.object({ salesforceId: z.string().regex(/^[a-zA-Z0-9]{18}|[a-zA-Z0-9]{15}$/).describe("The salesforce id").optional(), parentTenantId: z.string().regex(/^(?=[a-z0-9-]{3,36}$)[a-z0-9]+(-[a-z0-9]+){0,17}$/).describe("The tenant's parent id"), childTenantLimit: z.number().int().gte(0).describe("The number of child tenants a parent tenant is allowed to have.").optional(), partner: z.boolean().describe("If the tenant is an affinity partner").optional(), tenantAttributes: z.record(z.object({}).partial().passthrough().describe("The tenant's attributes")).describe("The tenant's attributes").optional() }).passthrough();
const UpdateTenantConfigRequest = z.object({ salesforceId: z.string().regex(/^[a-zA-Z0-9]{18}|[a-zA-Z0-9]{15}$/).describe("The salesforce id").optional(), parentTenantId: z.string().regex(/^(?=[a-z0-9-]{3,36}$)[a-z0-9]+(-[a-z0-9]+){0,17}$/).describe("The tenant's parent id"), childTenantLimit: z.number().int().gte(0).describe("The number of child tenants a parent tenant is allowed to have.").optional(), partner: z.boolean().describe("If the tenant is an affinity partner").optional(), tenantAttributes: z.record(z.object({}).partial().passthrough().describe("The tenant's attributes")).describe("The tenant's attributes").optional() }).passthrough();
const AdminCreateConfigurationRequest = z.object({ tenantId: z.string().regex(/^(?=[a-z0-9-]{3,36}$)[a-z0-9]+(-[a-z0-9]+){0,17}$/).describe("The tenantId"), productId: z.string().describe("A configuration's product id coordinate").optional(), domainId: z.string().describe("A configuration's domain id coordinate").optional(), appId: z.string().describe("A configuration's app id coordinate").optional(), componentId: z.string().describe("A configuration's component id coordinate").optional(), subcomponentId: z.string().describe("A configuration's subcomponent id coordinate").optional(), inheritanceStrategy: z.enum(["INHERIT", "NONE", "OVERRIDE"]).describe("The configuration's inheritency strategy").optional(), config: z.record(z.object({}).partial().passthrough().describe("The configuration object")).describe("The configuration object"), attributes: z.record(z.object({}).partial().passthrough().describe("DEPRECATED use config. The configuration object")).describe("DEPRECATED use config. The configuration object").optional() }).passthrough();
const AbsoluteIri: z.ZodType<AbsoluteIri> = z.object({ scheme: z.string() }).partial().passthrough();
const JsonNodePath: z.ZodType<JsonNodePath> = z.lazy(() => z.object({ parent: JsonNodePath, nameCount: z.number().int(), pathType: z.enum(["LEGACY", "JSON_PATH", "JSON_POINTER", "URI_REFERENCE"]) }).partial().passthrough());
const SchemaLocation: z.ZodType<SchemaLocation> = z.object({ absoluteIri: AbsoluteIri, fragment: JsonNodePath }).partial().passthrough();
const JsonNode: z.ZodType<JsonNode> = z.object({}).partial().passthrough();
const Keyword: z.ZodType<Keyword> = z.object({ value: z.string() }).partial().passthrough();
const JsonMetaSchema: z.ZodType<JsonMetaSchema> = z.object({ iri: z.string(), idKeyword: z.string(), keywords: z.record(Keyword), vocabularies: z.record(z.boolean()), specification: z.enum(["V4", "V6", "V7", "V201909", "V202012"]) }).partial().passthrough();
const SchemaLoader: z.ZodType<SchemaLoader> = z.object({}).partial().passthrough();
const JsonSchemaFactory: z.ZodType<JsonSchemaFactory> = z.object({ schemaLoader: SchemaLoader }).partial().passthrough();
const ApplyDefaultsStrategy: z.ZodType<ApplyDefaultsStrategy> = z.object({}).partial().passthrough();
const ExecutionContextCustomizer: z.ZodType<ExecutionContextCustomizer> = z.object({}).partial().passthrough();
const JsonSchemaWalkListener: z.ZodType<JsonSchemaWalkListener> = z.object({}).partial().passthrough();
const MessageSource: z.ZodType<MessageSource> = z.object({}).partial().passthrough();
const RegularExpressionFactory: z.ZodType<RegularExpressionFactory> = z.object({}).partial().passthrough();
const JsonSchemaIdValidator: z.ZodType<JsonSchemaIdValidator> = z.object({}).partial().passthrough();
const SchemaValidatorsConfig: z.ZodType<SchemaValidatorsConfig> = z.object({ applyDefaultsStrategy: ApplyDefaultsStrategy, cacheRefs: z.boolean(), errorMessageKeyword: z.string(), executionContextCustomizer: ExecutionContextCustomizer, failFast: z.boolean(), formatAssertionsEnabled: z.boolean(), nullableKeywordEnabled: z.boolean(), javaSemantics: z.boolean(), keywordWalkListenersMap: z.record(z.array(JsonSchemaWalkListener)), locale: z.object({ language: z.string(), displayName: z.string(), country: z.string(), variant: z.string(), script: z.string(), unicodeLocaleAttributes: z.array(z.string()), unicodeLocaleKeys: z.array(z.string()), displayLanguage: z.string(), displayScript: z.string(), displayCountry: z.string(), displayVariant: z.string(), extensionKeys: z.array(z.string()), iso3Language: z.string(), iso3Country: z.string() }).partial().passthrough(), losslessNarrowing: z.boolean(), messageSource: MessageSource, discriminatorKeywordEnabled: z.boolean(), pathType: z.enum(["LEGACY", "JSON_PATH", "JSON_POINTER", "URI_REFERENCE"]), preloadJsonSchema: z.boolean(), preloadJsonSchemaRefMaxNestingDepth: z.number().int(), propertyWalkListeners: z.array(JsonSchemaWalkListener), readOnly: z.boolean(), regularExpressionFactory: RegularExpressionFactory, schemaIdValidator: JsonSchemaIdValidator, typeLoose: z.boolean(), writeOnly: z.boolean(), loadCollectors: z.boolean(), arrayItemWalkListeners: z.array(JsonSchemaWalkListener), customMessageSupported: z.boolean(), ecma262Validator: z.boolean(), handleNullableField: z.boolean(), openAPI3StyleDiscriminators: z.boolean(), writeMode: z.boolean(), unevaluatedItemsAnalysisDisabled: z.boolean(), unevaluatedItemsAnalysisEnabled: z.boolean(), unevaluatedPropertiesAnalysisDisabled: z.boolean(), unevaluatedPropertiesAnalysisEnabled: z.boolean() }).partial().passthrough();
const ValidationContext: z.ZodType<ValidationContext> = z.lazy(() => z.object({ metaSchema: JsonMetaSchema, jsonSchemaFactory: JsonSchemaFactory, config: SchemaValidatorsConfig, schemaReferences: z.record(JsonSchema), schemaResources: z.record(JsonSchema), dynamicAnchors: z.record(JsonSchema) }).partial().passthrough());
const JsonValidator: z.ZodType<JsonValidator> = z.object({ schemaLocation: SchemaLocation, evaluationPath: JsonNodePath, keyword: z.string() }).partial().passthrough();
const TypeValidator: z.ZodType<TypeValidator> = z.lazy(() => z.object({ keyword: z.string(), parentSchema: JsonSchema.describe("The json schema"), schemaLocation: SchemaLocation, evaluationPath: JsonNodePath, evaluationParentSchema: JsonSchema.describe("The json schema"), schemaNode: JsonNode, schemaType: z.enum(["object", "array", "string", "number", "integer", "boolean", "null", "any", "unknown", "union"]) }).partial().passthrough());
const JsonSchema: z.ZodType<JsonSchema> = z.lazy(() => z.object({ keyword: z.string(), parentSchema: JsonSchema.describe("The json schema"), schemaLocation: SchemaLocation, evaluationPath: JsonNodePath, evaluationParentSchema: JsonSchema.describe("The json schema"), schemaNode: JsonNode, validationContext: ValidationContext, id: z.string(), validators: z.array(JsonValidator), recursiveAnchor: z.boolean(), typeValidator: TypeValidator, schemaResourceRoot: z.boolean() }).partial().passthrough());
const SubcomponentDto: z.ZodType<SubcomponentDto> = z.object({ subcomponentId: z.string().describe("A configuration's subcomponent id coordinate"), jsonSchema: JsonSchema.describe("The json schema") }).partial().passthrough();
const CreateSubcomponentRequest: z.ZodType<CreateSubcomponentRequest> = z.object({ jsonSchema: JsonSchema.describe("The json schema") }).partial().passthrough();
const UpdateSubcomponentRequest: z.ZodType<UpdateSubcomponentRequest> = z.object({ jsonSchema: JsonSchema.describe("The json schema") }).partial().passthrough();
const ProductDto = z.object({ productId: z.string().describe("A configuration's product id coordinate"), productAttributes: z.record(z.object({}).partial().passthrough().describe("A product coordinate's configuration")).describe("A product coordinate's configuration") }).partial().passthrough();
const CreateProductRequest = z.object({ productAttributes: z.record(z.object({}).partial().passthrough().describe("A product coordinate's configuration")).describe("A product coordinate's configuration") }).partial().passthrough();
const UpdateProductRequest = z.object({ productAttributes: z.record(z.object({}).partial().passthrough().describe("A product coordinate's configuration")).describe("A product coordinate's configuration") }).partial().passthrough();
const DomainDto = z.object({ domainId: z.string().describe("A configuration's domain id coordinate"), domainAttributes: z.record(z.object({}).partial().passthrough()) }).partial().passthrough();
const CreateDomainRequest = z.object({ domainAttributes: z.record(z.object({}).partial().passthrough().describe("An domain coordinate's configuration")).describe("An domain coordinate's configuration") }).partial().passthrough();
const UpdateDomainRequest = z.object({ domainAttributes: z.record(z.object({}).partial().passthrough().describe("An domain coordinate's configuration")).describe("An domain coordinate's configuration") }).partial().passthrough();
const ComponentDto: z.ZodType<ComponentDto> = z.lazy(() => z.object({ componentId: z.string().describe("A configuration's component id coordinate"), parentComponent: ComponentDto.describe("Tenant Config component coordinate details."), jsonSchema: JsonSchema.describe("The json schema") }).partial().passthrough());
const CreateComponentRequest: z.ZodType<CreateComponentRequest> = z.object({ parentComponentId: z.string().describe("A component's parent component id"), jsonSchema: JsonSchema.describe("The json schema") }).partial().passthrough();
const UpdateComponentRequest: z.ZodType<UpdateComponentRequest> = z.object({ parentComponentId: z.string().describe("A component's parent component id"), jsonSchema: JsonSchema.describe("The json schema") }).partial().passthrough();
const AppDto = z.object({ appId: z.string().describe("A configuration's app id coordinate"), appAttributes: z.record(z.object({}).partial().passthrough()) }).partial().passthrough();
const CreateAppRequest = z.object({ appAttributes: z.record(z.object({}).partial().passthrough().describe("An app coordinate's configuration")).describe("An app coordinate's configuration") }).partial().passthrough();
const UpdateAppRequest = z.object({ appAttributes: z.record(z.object({}).partial().passthrough().describe("An app coordinate's configuration")).describe("An app coordinate's configuration") }).partial().passthrough();

export const schemas = {
	ConfigurationDto,
	UpdateConfigurationRequest,
	AutoOpenCaseConfigDto,
	AdminUpdateConfigurationRequest,
	InsightSeverity,
	InsightPreferencesDto,
	TenantConfigInsightPreferenceDto,
	TenantConfigInsightPreferenceRequest,
	CreateConfigurationRequest,
	MergedConfigurationSource,
	MergedConfigurationDto,
	TenantDto,
	CreateTenantConfigRequest,
	UpdateTenantConfigRequest,
	AdminCreateConfigurationRequest,
	AbsoluteIri,
	JsonNodePath,
	SchemaLocation,
	JsonNode,
	Keyword,
	JsonMetaSchema,
	SchemaLoader,
	JsonSchemaFactory,
	ApplyDefaultsStrategy,
	ExecutionContextCustomizer,
	JsonSchemaWalkListener,
	MessageSource,
	RegularExpressionFactory,
	JsonSchemaIdValidator,
	SchemaValidatorsConfig,
	ValidationContext,
	JsonValidator,
	TypeValidator,
	JsonSchema,
	SubcomponentDto,
	CreateSubcomponentRequest,
	UpdateSubcomponentRequest,
	ProductDto,
	CreateProductRequest,
	UpdateProductRequest,
	DomainDto,
	CreateDomainRequest,
	UpdateDomainRequest,
	ComponentDto,
	CreateComponentRequest,
	UpdateComponentRequest,
	AppDto,
	CreateAppRequest,
	UpdateAppRequest,
};

const configuration_read_controllerEndpoints = makeApi([
	{
		method: "get",
		path: "/v1/configurations/:configurationId",
		alias: "getConfiguration",
		requestFormat: "json",
		parameters: [
			{
				name: "configurationId",
				type: "Path",
				schema: z.string().describe("The configuration id")
			},
		],
		response: ConfigurationDto,
		errors: [
			{
				status: 404,
				description: `If we couldn&#x27;t find a configuration that matched the supplied configurationId`,
				schema: z.void()
			},
			{
				status: 422,
				description: `Parameters or Request violates constraints`,
				schema: z.void()
			},
			{
				status: NaN,
				description: `Jwt token has an invalid tenant_id claim.`,
				schema: z.void()
			},
			{
				status: NaN,
				description: `Jwt token has an invalid sub claim.`,
				schema: z.void()
			},
		]
	},
	{
		method: "get",
		path: "/v1/configurations",
		alias: "getConfigurationDtos",
		requestFormat: "json",
		parameters: [
			{
				name: "domainId",
				type: "Query",
				schema: z.string().describe("A configuration's domain id coordinate").optional()
			},
			{
				name: "productId",
				type: "Query",
				schema: z.string().describe("A configuration's product id coordinate").optional()
			},
			{
				name: "appId",
				type: "Query",
				schema: z.string().optional()
			},
			{
				name: "componentId",
				type: "Query",
				schema: z.string().describe("A configuration's component id coordinate").optional()
			},
			{
				name: "subcomponentId",
				type: "Query",
				schema: z.string().describe("A configuration's subcomponent id coordinate").optional()
			},
			{
				name: "inheritanceStrategy",
				type: "Query",
				schema: z.enum(["INHERIT", "NONE", "OVERRIDE"]).optional()
			},
		],
		response: z.array(z.any()),
		errors: [
			{
				status: 422,
				description: `Parameters or Request violates constraints`,
				schema: z.void()
			},
			{
				status: NaN,
				description: `Jwt token has an invalid tenant_id claim.`,
				schema: z.void()
			},
			{
				status: NaN,
				description: `Jwt token has an invalid sub claim.`,
				schema: z.void()
			},
		]
	},
	{
		method: "get",
		path: "/v1/configurations/merged",
		alias: "getMergedConfigurations",
		requestFormat: "json",
		parameters: [
			{
				name: "domainId",
				type: "Query",
				schema: z.string().describe("A configuration's domain id coordinate").optional()
			},
			{
				name: "productId",
				type: "Query",
				schema: z.string().describe("A configuration's product id coordinate").optional()
			},
			{
				name: "appId",
				type: "Query",
				schema: z.string().optional()
			},
			{
				name: "componentId",
				type: "Query",
				schema: z.string().describe("A configuration's component id coordinate").optional()
			},
			{
				name: "subcomponentId",
				type: "Query",
				schema: z.string().describe("A configuration's subcomponent id coordinate").optional()
			},
		],
		response: z.array(z.any()),
		errors: [
			{
				status: 422,
				description: `Parameters or Request violates constraints`,
				schema: z.void()
			},
			{
				status: NaN,
				description: `Jwt token has an invalid tenant_id claim.`,
				schema: z.void()
			},
			{
				status: NaN,
				description: `Jwt token has an invalid sub claim.`,
				schema: z.void()
			},
		]
	},
	{
		method: "get",
		path: "/v1/configuration",
		alias: "getConfigurationDto",
		requestFormat: "json",
		parameters: [
			{
				name: "domainId",
				type: "Query",
				schema: z.string().describe("A configuration's domain id coordinate").optional()
			},
			{
				name: "productId",
				type: "Query",
				schema: z.string().describe("A configuration's product id coordinate").optional()
			},
			{
				name: "appId",
				type: "Query",
				schema: z.string().optional()
			},
			{
				name: "componentId",
				type: "Query",
				schema: z.string().describe("A configuration's component id coordinate").optional()
			},
			{
				name: "subcomponentId",
				type: "Query",
				schema: z.string().describe("A configuration's subcomponent id coordinate").optional()
			},
			{
				name: "inheritanceStrategy",
				type: "Query",
				schema: z.enum(["INHERIT", "NONE", "OVERRIDE"]).optional()
			},
		],
		response: ConfigurationDto,
		errors: [
			{
				status: 404,
				description: `If we couldn&#x27;t find a configuration that matched the supplied coordinates`,
				schema: z.void()
			},
			{
				status: 422,
				description: `Parameters or Request violates constraints`,
				schema: z.void()
			},
			{
				status: NaN,
				description: `Jwt token has an invalid tenant_id claim.`,
				schema: z.void()
			},
			{
				status: NaN,
				description: `Jwt token has an invalid sub claim.`,
				schema: z.void()
			},
			{
				status: NaN,
				description: `If more than one configuration matched the supplied coordinates`,
				schema: z.void()
			},
		]
	},
	{
		method: "get",
		path: "/v1/configuration/merged",
		alias: "getMergedConfiguration",
		requestFormat: "json",
		parameters: [
			{
				name: "domainId",
				type: "Query",
				schema: z.string().describe("A configuration's domain id coordinate").optional()
			},
			{
				name: "productId",
				type: "Query",
				schema: z.string().describe("A configuration's product id coordinate").optional()
			},
			{
				name: "appId",
				type: "Query",
				schema: z.string().optional()
			},
			{
				name: "componentId",
				type: "Query",
				schema: z.string().describe("A configuration's component id coordinate").optional()
			},
			{
				name: "subcomponentId",
				type: "Query",
				schema: z.string().describe("A configuration's subcomponent id coordinate").optional()
			},
		],
		response: MergedConfigurationDto,
		errors: [
			{
				status: 404,
				description: `If we couldn&#x27;t find a configuration that matched the supplied coordinates`,
				schema: z.void()
			},
			{
				status: 422,
				description: `Parameters or Request violates constraints`,
				schema: z.void()
			},
			{
				status: NaN,
				description: `Jwt token has an invalid tenant_id claim.`,
				schema: z.void()
			},
			{
				status: NaN,
				description: `Jwt token has an invalid sub claim.`,
				schema: z.void()
			},
			{
				status: NaN,
				description: `If more than one configuration matched the supplied coordinates`,
				schema: z.void()
			},
		]
	},
]);

export const configuration_read_controllerApi = new Zodios(configuration_read_controllerEndpoints);

const configuration_write_controllerEndpoints = makeApi([
	{
		method: "put",
		path: "/v1/configurations/:configurationId",
		alias: "updateConfiguration",
		requestFormat: "json",
		parameters: [
			{
				name: "body",
				type: "Body",
				schema: UpdateConfigurationRequest
			},
			{
				name: "configurationId",
				type: "Path",
				schema: z.string().describe("The configuration id")
			},
		],
		response: ConfigurationDto,
		errors: [
			{
				status: 400,
				description: `Jwt token has invalid tenantId or sub claims`,
				schema: z.void()
			},
		]
	},
	{
		method: "delete",
		path: "/v1/configurations/:configurationId",
		alias: "deleteConfiguration",
		requestFormat: "json",
		parameters: [
			{
				name: "configurationId",
				type: "Path",
				schema: z.string().describe("The configuration id")
			},
		],
		response: z.void(),
		errors: [
			{
				status: 400,
				description: `Jwt token has invalid tenantId or sub claims`,
				schema: z.void()
			},
		]
	},
	{
		method: "post",
		path: "/v1/configurations",
		alias: "createConfiguration",
		requestFormat: "json",
		parameters: [
			{
				name: "body",
				type: "Body",
				schema: CreateConfigurationRequest
			},
		],
		response: ConfigurationDto,
		errors: [
			{
				status: 400,
				description: `Jwt token has invalid tenantId or sub claims`,
				schema: z.void()
			},
		]
	},
	{
		method: "post",
		path: "/v1/configurations/:configurationId/preview",
		alias: "previewUpdateMergedConfiguration",
		requestFormat: "json",
		parameters: [
			{
				name: "body",
				type: "Body",
				schema: UpdateConfigurationRequest
			},
			{
				name: "configurationId",
				type: "Path",
				schema: z.string().describe("The configuration id")
			},
		],
		response: MergedConfigurationDto,
		errors: [
			{
				status: 400,
				description: `Jwt token has invalid tenantId or sub claims`,
				schema: z.void()
			},
		]
	},
	{
		method: "post",
		path: "/v1/configurations/preview",
		alias: "previewCreateMergedConfiguration",
		requestFormat: "json",
		parameters: [
			{
				name: "body",
				type: "Body",
				schema: CreateConfigurationRequest
			},
		],
		response: ConfigurationDto,
		errors: [
			{
				status: 400,
				description: `Jwt token has invalid tenantId or sub claims`,
				schema: z.void()
			},
		]
	},
]);

export const configuration_write_controllerApi = new Zodios(configuration_write_controllerEndpoints);

const case_controllerEndpoints = makeApi([
	{
		method: "put",
		path: "/v1/cases/auto-open/configurations/:configurationId",
		alias: "updateCase",
		requestFormat: "json",
		parameters: [
			{
				name: "body",
				type: "Body",
				schema: AutoOpenCaseConfigDto
			},
			{
				name: "configurationId",
				type: "Path",
				schema: z.string().describe("The configuration id")
			},
		],
		response: ConfigurationDto,
		errors: [
			{
				status: NaN,
				description: `Jwt token has an invalid tenant_id claim.`,
				schema: z.void()
			},
			{
				status: NaN,
				description: `Jwt token has an invalid sub claim.`,
				schema: z.void()
			},
		]
	},
	{
		method: "get",
		path: "/v1/cases/auto-open/configurations/",
		alias: "getCase",
		requestFormat: "json",
		response: ConfigurationDto,
		errors: [
			{
				status: NaN,
				description: `Jwt token has an invalid tenant_id claim.`,
				schema: z.void()
			},
			{
				status: NaN,
				description: `Jwt token has an invalid sub claim.`,
				schema: z.void()
			},
		]
	},
	{
		method: "post",
		path: "/v1/cases/auto-open/configurations/",
		alias: "createCase",
		requestFormat: "json",
		parameters: [
			{
				name: "body",
				type: "Body",
				schema: AutoOpenCaseConfigDto
			},
		],
		response: ConfigurationDto,
		errors: [
			{
				status: NaN,
				description: `Jwt token has an invalid tenant_id claim.`,
				schema: z.void()
			},
			{
				status: NaN,
				description: `Jwt token has an invalid sub claim.`,
				schema: z.void()
			},
		]
	},
]);

export const case_controllerApi = new Zodios(case_controllerEndpoints);

const configuration_admin_read_controllerEndpoints = makeApi([
	{
		method: "get",
		path: "/v1/admin/tenants/:tenantId/configurations/:configurationId",
		alias: "getTenantsConfigurationDtoByConfigurationId",
		requestFormat: "json",
		parameters: [
			{
				name: "tenantId",
				type: "Path",
				schema: z.string()
			},
			{
				name: "configurationId",
				type: "Path",
				schema: z.string().describe("The configuration id")
			},
		],
		response: z.void(),
		errors: [
			{
				status: 404,
				description: `If we couldn&#x27;t find a configuration that matched the supplied configurationId`,
				schema: z.void()
			},
			{
				status: 422,
				description: `Parameters or Request violates constraints`,
				schema: z.void()
			},
			{
				status: NaN,
				description: `Jwt token has an invalid sub claim.`,
				schema: z.void()
			},
			{
				status: NaN,
				description: `If more than one configuration matched the supplied coordinates`,
				schema: z.void()
			},
		]
	},
	{
		method: "get",
		path: "/v1/admin/tenants/:tenantId/configurations",
		alias: "getTenantsConfigurationDtos",
		requestFormat: "json",
		parameters: [
			{
				name: "tenantId",
				type: "Path",
				schema: z.string()
			},
			{
				name: "domainId",
				type: "Query",
				schema: z.string().describe("A configuration's domain id coordinate").optional()
			},
			{
				name: "productId",
				type: "Query",
				schema: z.string().describe("A configuration's product id coordinate").optional()
			},
			{
				name: "appId",
				type: "Query",
				schema: z.string().optional()
			},
			{
				name: "componentId",
				type: "Query",
				schema: z.string().describe("A configuration's component id coordinate").optional()
			},
			{
				name: "subcomponentId",
				type: "Query",
				schema: z.string().describe("A configuration's subcomponent id coordinate").optional()
			},
			{
				name: "inheritanceStrategy",
				type: "Query",
				schema: z.enum(["INHERIT", "NONE", "OVERRIDE"]).optional()
			},
		],
		response: z.void(),
		errors: [
			{
				status: 422,
				description: `Parameters or Request violates constraints`,
				schema: z.void()
			},
			{
				status: NaN,
				description: `Jwt token has an invalid sub claim.`,
				schema: z.void()
			},
		]
	},
	{
		method: "get",
		path: "/v2/admin/configurations",
		alias: "getConfigurationDtosByTenantId",
		requestFormat: "json",
		parameters: [
			{
				name: "tenantIds",
				type: "Query",
				schema: z.array(z.string()).optional()
			},
			{
				name: "domainId",
				type: "Query",
				schema: z.string().describe("A configuration's domain id coordinate").optional()
			},
			{
				name: "productId",
				type: "Query",
				schema: z.string().describe("A configuration's product id coordinate").optional()
			},
			{
				name: "appId",
				type: "Query",
				schema: z.string().optional()
			},
			{
				name: "componentId",
				type: "Query",
				schema: z.string().describe("A configuration's component id coordinate").optional()
			},
			{
				name: "subcomponentId",
				type: "Query",
				schema: z.string().describe("A configuration's subcomponent id coordinate").optional()
			},
			{
				name: "inheritanceStrategy",
				type: "Query",
				schema: z.enum(["INHERIT", "NONE", "OVERRIDE"]).optional()
			},
		],
		response: z.void(),
		errors: [
			{
				status: 422,
				description: `Parameters or Request violates constraints`,
				schema: z.void()
			},
			{
				status: NaN,
				description: `Jwt token has an invalid sub claim.`,
				schema: z.void()
			},
		]
	},
	{
		method: "get",
		path: "/v1/admin/tenants/:tenantId/configurations/merged",
		alias: "getTenantsMergedConfigurations",
		requestFormat: "json",
		parameters: [
			{
				name: "tenantId",
				type: "Path",
				schema: z.string()
			},
			{
				name: "domainId",
				type: "Query",
				schema: z.string().describe("A configuration's domain id coordinate").optional()
			},
			{
				name: "productId",
				type: "Query",
				schema: z.string().describe("A configuration's product id coordinate").optional()
			},
			{
				name: "appId",
				type: "Query",
				schema: z.string().optional()
			},
			{
				name: "componentId",
				type: "Query",
				schema: z.string().describe("A configuration's component id coordinate").optional()
			},
			{
				name: "subcomponentId",
				type: "Query",
				schema: z.string().describe("A configuration's subcomponent id coordinate").optional()
			},
		],
		response: z.void(),
		errors: [
			{
				status: 422,
				description: `Parameters or Request violates constraints`,
				schema: z.void()
			},
			{
				status: NaN,
				description: `Jwt token has an invalid sub claim.`,
				schema: z.void()
			},
		]
	},
	{
		method: "get",
		path: "/v1/admin/tenants/:tenantId/configuration",
		alias: "getTenantsConfigurationDto",
		requestFormat: "json",
		parameters: [
			{
				name: "tenantId",
				type: "Path",
				schema: z.string()
			},
			{
				name: "domainId",
				type: "Query",
				schema: z.string().describe("A configuration's domain id coordinate").optional()
			},
			{
				name: "productId",
				type: "Query",
				schema: z.string().describe("A configuration's product id coordinate").optional()
			},
			{
				name: "appId",
				type: "Query",
				schema: z.string().optional()
			},
			{
				name: "componentId",
				type: "Query",
				schema: z.string().describe("A configuration's component id coordinate").optional()
			},
			{
				name: "subcomponentId",
				type: "Query",
				schema: z.string().describe("A configuration's subcomponent id coordinate").optional()
			},
			{
				name: "inheritanceStrategy",
				type: "Query",
				schema: z.enum(["INHERIT", "NONE", "OVERRIDE"]).optional()
			},
		],
		response: z.void(),
		errors: [
			{
				status: 404,
				description: `If we couldn&#x27;t find a configuration that matched the supplied coordinates`,
				schema: z.void()
			},
			{
				status: 422,
				description: `Parameters or Request violates constraints`,
				schema: z.void()
			},
			{
				status: NaN,
				description: `Jwt token has an invalid sub claim.`,
				schema: z.void()
			},
			{
				status: NaN,
				description: `If more than one configuration matched the supplied coordinates`,
				schema: z.void()
			},
		]
	},
	{
		method: "get",
		path: "/v1/admin/tenants/:tenantId/configuration/merged",
		alias: "getTenantsMergedConfiguration",
		requestFormat: "json",
		parameters: [
			{
				name: "tenantId",
				type: "Path",
				schema: z.string()
			},
			{
				name: "domainId",
				type: "Query",
				schema: z.string().describe("A configuration's domain id coordinate").optional()
			},
			{
				name: "productId",
				type: "Query",
				schema: z.string().describe("A configuration's product id coordinate").optional()
			},
			{
				name: "appId",
				type: "Query",
				schema: z.string().optional()
			},
			{
				name: "componentId",
				type: "Query",
				schema: z.string().describe("A configuration's component id coordinate").optional()
			},
			{
				name: "subcomponentId",
				type: "Query",
				schema: z.string().describe("A configuration's subcomponent id coordinate").optional()
			},
		],
		response: z.void(),
		errors: [
			{
				status: 404,
				description: `If we couldn&#x27;t find a configuration that matched the supplied coordinates`,
				schema: z.void()
			},
			{
				status: 422,
				description: `Parameters or Request violates constraints`,
				schema: z.void()
			},
			{
				status: NaN,
				description: `Jwt token has an invalid sub claim.`,
				schema: z.void()
			},
			{
				status: NaN,
				description: `If more than one configuration matched the supplied coordinates`,
				schema: z.void()
			},
		]
	},
	{
		method: "get",
		path: "/v1/admin/configurations/merged",
		alias: "getMergedConfigurationDtosByTenantId",
		requestFormat: "json",
		parameters: [
			{
				name: "tenantIds",
				type: "Query",
				schema: z.array(z.string()).optional()
			},
			{
				name: "domainId",
				type: "Query",
				schema: z.string().describe("A configuration's domain id coordinate").optional()
			},
			{
				name: "productId",
				type: "Query",
				schema: z.string().describe("A configuration's product id coordinate").optional()
			},
			{
				name: "appId",
				type: "Query",
				schema: z.string().optional()
			},
			{
				name: "componentId",
				type: "Query",
				schema: z.string().describe("A configuration's component id coordinate").optional()
			},
			{
				name: "subcomponentId",
				type: "Query",
				schema: z.string().describe("A configuration's subcomponent id coordinate").optional()
			},
		],
		response: z.void(),
		errors: [
			{
				status: 422,
				description: `Parameters or Request violates constraints`,
				schema: z.void()
			},
			{
				status: NaN,
				description: `Jwt token has an invalid sub claim.`,
				schema: z.void()
			},
		]
	},
]);

export const configuration_admin_read_controllerApi = new Zodios(configuration_admin_read_controllerEndpoints);

const configuration_admin_write_controllerEndpoints = makeApi([
	{
		method: "put",
		path: "/v1/admin/tenants/:tenantId/configurations/:configurationId",
		alias: "updateConfiguration_1",
		requestFormat: "json",
		parameters: [
			{
				name: "body",
				type: "Body",
				schema: AdminUpdateConfigurationRequest
			},
			{
				name: "tenantId",
				type: "Path",
				schema: z.string()
			},
			{
				name: "configurationId",
				type: "Path",
				schema: z.string().describe("The configuration id")
			},
		],
		response: ConfigurationDto,
		errors: [
			{
				status: 404,
				description: `If we couldn&#x27;t find a configuration that matched the supplied configurationId`,
				schema: z.void()
			},
			{
				status: 422,
				description: `Parameters or Request violates constraints`,
				schema: z.void()
			},
			{
				status: NaN,
				description: `Jwt token has an invalid sub claim.`,
				schema: z.void()
			},
		]
	},
	{
		method: "delete",
		path: "/v1/admin/tenants/:tenantId/configurations/:configurationId",
		alias: "deleteConfiguration_1",
		requestFormat: "json",
		parameters: [
			{
				name: "tenantId",
				type: "Path",
				schema: z.string()
			},
			{
				name: "configurationId",
				type: "Path",
				schema: z.string().describe("The configuration id")
			},
		],
		response: z.void(),
		errors: [
			{
				status: 422,
				description: `Parameters or Request violates constraints`,
				schema: z.void()
			},
			{
				status: NaN,
				description: `Jwt token has an invalid sub claim.`,
				schema: z.void()
			},
		]
	},
	{
		method: "post",
		path: "/v1/admin/tenants/:tenantId/configurations",
		alias: "createConfiguration_1",
		requestFormat: "json",
		parameters: [
			{
				name: "body",
				type: "Body",
				schema: AdminCreateConfigurationRequest
			},
			{
				name: "tenantId",
				type: "Path",
				schema: z.string()
			},
		],
		response: ConfigurationDto,
		errors: [
			{
				status: 422,
				description: `Parameters or Request violates constraints`,
				schema: z.void()
			},
			{
				status: NaN,
				description: `Jwt token has an invalid sub claim.`,
				schema: z.void()
			},
		]
	},
	{
		method: "post",
		path: "/v1/admin/tenants/:tenantId/configurations/:configurationId/preview",
		alias: "previewUpdateMergedConfiguration_1",
		requestFormat: "json",
		parameters: [
			{
				name: "body",
				type: "Body",
				schema: AdminUpdateConfigurationRequest
			},
			{
				name: "tenantId",
				type: "Path",
				schema: z.string()
			},
			{
				name: "configurationId",
				type: "Path",
				schema: z.string().describe("The configuration id")
			},
		],
		response: ConfigurationDto,
		errors: [
			{
				status: 404,
				description: `If we couldn&#x27;t find a configuration that matched the supplied configurationId`,
				schema: z.void()
			},
			{
				status: 422,
				description: `Parameters or Request violates constraints`,
				schema: z.void()
			},
			{
				status: NaN,
				description: `Jwt token has an invalid sub claim.`,
				schema: z.void()
			},
		]
	},
	{
		method: "post",
		path: "/v1/admin/tenants/:tenantId/configurations/preview",
		alias: "previewCreateMergedConfiguration_1",
		requestFormat: "json",
		parameters: [
			{
				name: "body",
				type: "Body",
				schema: AdminCreateConfigurationRequest
			},
			{
				name: "tenantId",
				type: "Path",
				schema: z.string()
			},
		],
		response: ConfigurationDto,
		errors: [
			{
				status: 422,
				description: `Parameters or Request violates constraints`,
				schema: z.void()
			},
			{
				status: NaN,
				description: `Jwt token has an invalid sub claim.`,
				schema: z.void()
			},
		]
	},
]);

export const configuration_admin_write_controllerApi = new Zodios(configuration_admin_write_controllerEndpoints);

const insight_preferences_customer_read_controllerEndpoints = makeApi([
	{
		method: "get",
		path: "/v1/tenants/insights/preferences",
		alias: "getInsightPreferencesByTenantAsPage",
		requestFormat: "json",
		parameters: [
			{
				name: "responseFormat",
				type: "Query",
				schema: z.enum(["page", "array"])
			},
			{
				name: "page",
				type: "Query",
				schema: z.number().int().describe("Zero-based page index (0..N) (page ResponseFormat only)").optional().default(0)
			},
			{
				name: "size",
				type: "Query",
				schema: z.number().int().describe("The size of the page to be returned (page ResponseFormat only)").optional().default(20)
			},
		],
		response: InsightPreferencesDto,
		errors: [
			{
				status: NaN,
				description: `Jwt token has an invalid tenant_id claim.`,
				schema: z.void()
			},
			{
				status: NaN,
				description: `Jwt token has an invalid sub claim.`,
				schema: z.void()
			},
		]
	},
]);

export const insight_preferences_customer_read_controllerApi = new Zodios(insight_preferences_customer_read_controllerEndpoints);

const insight_preferences_customer_write_controllerEndpoints = makeApi([
	{
		method: "post",
		path: "/v1/tenants/insights/preferences",
		alias: "upsertInsertPreferences",
		requestFormat: "json",
		parameters: [
			{
				name: "body",
				type: "Body",
				schema: TenantConfigInsightPreferenceRequest
			},
		],
		response: InsightPreferencesDto,
		errors: [
			{
				status: 400,
				description: `Jwt token has invalid tenantId or sub claims`,
				schema: z.void()
			},
			{
				status: NaN,
				description: `Jwt token has an invalid tenant_id claim.`,
				schema: z.void()
			},
			{
				status: NaN,
				description: `Jwt token has an invalid sub claim.`,
				schema: z.void()
			},
		]
	},
]);

export const insight_preferences_customer_write_controllerApi = new Zodios(insight_preferences_customer_write_controllerEndpoints);

const tenant_admin_controllerEndpoints = makeApi([
	{
		method: "get",
		path: "/v1/admin/tenants/:tenantId",
		alias: "findTenantById",
		requestFormat: "json",
		parameters: [
			{
				name: "tenantId",
				type: "Path",
				schema: z.string()
			},
		],
		response: TenantDto,
		errors: [
			{
				status: 403,
				description: `Jwt token has invalid sub claim`,
				schema: z.void()
			},
			{
				status: 404,
				description: `Tenant not found`,
				schema: z.void()
			},
		]
	},
	{
		method: "post",
		path: "/v1/admin/tenants/:tenantId",
		alias: "createTenant",
		requestFormat: "json",
		parameters: [
			{
				name: "body",
				type: "Body",
				schema: CreateTenantConfigRequest
			},
			{
				name: "tenantId",
				type: "Path",
				schema: z.string()
			},
		],
		response: TenantDto,
		errors: [
			{
				status: 400,
				description: `Jwt token has invalid sub claim`,
				schema: z.void()
			},
		]
	},
	{
		method: "delete",
		path: "/v1/admin/tenants/:tenantId",
		alias: "deleteTenant",
		requestFormat: "json",
		parameters: [
			{
				name: "tenantId",
				type: "Path",
				schema: z.string()
			},
			{
				name: "permanently",
				type: "Query",
				schema: z.boolean().optional().default(false)
			},
		],
		response: z.void(),
	},
	{
		method: "patch",
		path: "/v1/admin/tenants/:tenantId",
		alias: "updateTenant",
		requestFormat: "json",
		parameters: [
			{
				name: "body",
				type: "Body",
				schema: UpdateTenantConfigRequest
			},
			{
				name: "tenantId",
				type: "Path",
				schema: z.string()
			},
		],
		response: TenantDto,
		errors: [
			{
				status: 400,
				description: `Jwt token has invalid sub claim`,
				schema: z.void()
			},
		]
	},
	{
		method: "get",
		path: "/v1/admin/tenants",
		alias: "findAllTenants_1",
		requestFormat: "json",
		parameters: [
			{
				name: "includeDeleted",
				type: "Query",
				schema: z.boolean().optional().default(true)
			},
			{
				name: "page",
				type: "Query",
				schema: z.number().int().describe("Zero-based page index (0..N)").optional().default(0)
			},
			{
				name: "size",
				type: "Query",
				schema: z.number().int().describe("The size of the page to be returned").optional().default(20)
			},
			{
				name: "sort",
				type: "Query",
				schema: z.array(z.string()).describe(`Sorting criteria in the format: property[ ,asc | ,desc]. Default sort order is ascending.
Multiple sort criteria are supported.Keys accepted:
-tenantId
-attributes`).optional()
			},
		],
		response: TenantDto,
	},
]);

export const tenant_admin_controllerApi = new Zodios(tenant_admin_controllerEndpoints);

const subcomponent_admin_controllerEndpoints = makeApi([
	{
		method: "get",
		path: "/v1/admin/subcomponents/:subcomponentId",
		alias: "findSubcomponentById",
		requestFormat: "json",
		parameters: [
			{
				name: "subcomponentId",
				type: "Path",
				schema: z.string()
			},
		],
		response: SubcomponentDto,
		errors: [
			{
				status: 400,
				description: `Jwt token has invalid sub claim`,
				schema: z.void()
			},
		]
	},
	{
		method: "post",
		path: "/v1/admin/subcomponents/:subcomponentId",
		alias: "createSubcomponent",
		requestFormat: "json",
		parameters: [
			{
				name: "body",
				type: "Body",
				schema: CreateSubcomponentRequest
			},
			{
				name: "subcomponentId",
				type: "Path",
				schema: z.string()
			},
		],
		response: SubcomponentDto,
		errors: [
			{
				status: 400,
				description: `Jwt token has invalid sub claim`,
				schema: z.void()
			},
		]
	},
	{
		method: "delete",
		path: "/v1/admin/subcomponents/:subcomponentId",
		alias: "deleteSubcomponent",
		requestFormat: "json",
		parameters: [
			{
				name: "subcomponentId",
				type: "Path",
				schema: z.string()
			},
		],
		response: z.void(),
		errors: [
			{
				status: 400,
				description: `Jwt token has invalid sub claim`,
				schema: z.void()
			},
		]
	},
	{
		method: "patch",
		path: "/v1/admin/subcomponents/:subcomponentId",
		alias: "updateSubcomponent",
		requestFormat: "json",
		parameters: [
			{
				name: "body",
				type: "Body",
				schema: UpdateSubcomponentRequest
			},
			{
				name: "subcomponentId",
				type: "Path",
				schema: z.string()
			},
		],
		response: SubcomponentDto,
		errors: [
			{
				status: 400,
				description: `Jwt token has invalid sub claim`,
				schema: z.void()
			},
		]
	},
	{
		method: "get",
		path: "/v1/admin/subcomponents",
		alias: "findAllSubcomponents_1",
		requestFormat: "json",
		parameters: [
			{
				name: "page",
				type: "Query",
				schema: z.number().int().describe("Zero-based page index (0..N)").optional().default(0)
			},
			{
				name: "size",
				type: "Query",
				schema: z.number().int().describe("The size of the page to be returned").optional().default(20)
			},
			{
				name: "sort",
				type: "Query",
				schema: z.array(z.string()).describe("Sorting criteria in the format: property,(asc|desc). Default sort order is ascending. Multiple sort criteria are supported.").optional()
			},
		],
		response: SubcomponentDto,
	},
]);

export const subcomponent_admin_controllerApi = new Zodios(subcomponent_admin_controllerEndpoints);

const product_admin_controllerEndpoints = makeApi([
	{
		method: "get",
		path: "/v1/admin/products/:productId",
		alias: "findProductById",
		requestFormat: "json",
		parameters: [
			{
				name: "productId",
				type: "Path",
				schema: z.string()
			},
		],
		response: ProductDto,
		errors: [
			{
				status: 400,
				description: `Jwt token has invalid sub claim`,
				schema: z.void()
			},
		]
	},
	{
		method: "post",
		path: "/v1/admin/products/:productId",
		alias: "createProduct",
		requestFormat: "json",
		parameters: [
			{
				name: "body",
				type: "Body",
				schema: CreateProductRequest
			},
			{
				name: "productId",
				type: "Path",
				schema: z.string()
			},
		],
		response: ProductDto,
		errors: [
			{
				status: 400,
				description: `Jwt token has invalid sub claim`,
				schema: z.void()
			},
		]
	},
	{
		method: "delete",
		path: "/v1/admin/products/:productId",
		alias: "deleteProduct",
		requestFormat: "json",
		parameters: [
			{
				name: "productId",
				type: "Path",
				schema: z.string()
			},
		],
		response: z.void(),
		errors: [
			{
				status: 400,
				description: `Jwt token has invalid sub claim`,
				schema: z.void()
			},
		]
	},
	{
		method: "patch",
		path: "/v1/admin/products/:productId",
		alias: "updateProduct",
		requestFormat: "json",
		parameters: [
			{
				name: "body",
				type: "Body",
				schema: UpdateProductRequest
			},
			{
				name: "productId",
				type: "Path",
				schema: z.string()
			},
		],
		response: ProductDto,
		errors: [
			{
				status: 400,
				description: `Jwt token has invalid sub claim`,
				schema: z.void()
			},
		]
	},
	{
		method: "get",
		path: "/v1/admin/products",
		alias: "findAllProducts_1",
		requestFormat: "json",
		parameters: [
			{
				name: "page",
				type: "Query",
				schema: z.number().int().describe("Zero-based page index (0..N)").optional().default(0)
			},
			{
				name: "size",
				type: "Query",
				schema: z.number().int().describe("The size of the page to be returned").optional().default(20)
			},
			{
				name: "sort",
				type: "Query",
				schema: z.array(z.string()).describe("Sorting criteria in the format: property,(asc|desc). Default sort order is ascending. Multiple sort criteria are supported.").optional()
			},
		],
		response: ProductDto,
	},
]);

export const product_admin_controllerApi = new Zodios(product_admin_controllerEndpoints);

const domain_admin_controllerEndpoints = makeApi([
	{
		method: "get",
		path: "/v1/admin/domains/:domainId",
		alias: "findDomainById",
		requestFormat: "json",
		parameters: [
			{
				name: "domainId",
				type: "Path",
				schema: z.string()
			},
		],
		response: DomainDto,
	},
	{
		method: "post",
		path: "/v1/admin/domains/:domainId",
		alias: "createDomain",
		requestFormat: "json",
		parameters: [
			{
				name: "body",
				type: "Body",
				schema: CreateDomainRequest
			},
			{
				name: "domainId",
				type: "Path",
				schema: z.string()
			},
		],
		response: DomainDto,
		errors: [
			{
				status: 400,
				description: `Jwt token has invalid sub claim`,
				schema: z.void()
			},
		]
	},
	{
		method: "delete",
		path: "/v1/admin/domains/:domainId",
		alias: "deleteDomain",
		requestFormat: "json",
		parameters: [
			{
				name: "domainId",
				type: "Path",
				schema: z.string()
			},
		],
		response: z.void(),
		errors: [
			{
				status: 400,
				description: `Jwt token has invalid sub claim`,
				schema: z.void()
			},
		]
	},
	{
		method: "patch",
		path: "/v1/admin/domains/:domainId",
		alias: "updateDomain",
		requestFormat: "json",
		parameters: [
			{
				name: "body",
				type: "Body",
				schema: UpdateDomainRequest
			},
			{
				name: "domainId",
				type: "Path",
				schema: z.string()
			},
		],
		response: DomainDto,
		errors: [
			{
				status: 400,
				description: `Jwt token has invalid sub claim`,
				schema: z.void()
			},
		]
	},
	{
		method: "get",
		path: "/v1/admin/domains",
		alias: "findAllDomains_1",
		requestFormat: "json",
		parameters: [
			{
				name: "page",
				type: "Query",
				schema: z.number().int().describe("Zero-based page index (0..N)").optional().default(0)
			},
			{
				name: "size",
				type: "Query",
				schema: z.number().int().describe("The size of the page to be returned").optional().default(20)
			},
			{
				name: "sort",
				type: "Query",
				schema: z.array(z.string()).describe("Sorting criteria in the format: property,(asc|desc). Default sort order is ascending. Multiple sort criteria are supported.").optional()
			},
		],
		response: DomainDto,
	},
]);

export const domain_admin_controllerApi = new Zodios(domain_admin_controllerEndpoints);

const component_admin_controllerEndpoints = makeApi([
	{
		method: "get",
		path: "/v1/admin/components/:componentId",
		alias: "findComponentById",
		requestFormat: "json",
		parameters: [
			{
				name: "componentId",
				type: "Path",
				schema: z.string()
			},
		],
		response: ComponentDto,
		errors: [
			{
				status: 400,
				description: `Jwt token has invalid sub claim`,
				schema: z.void()
			},
		]
	},
	{
		method: "post",
		path: "/v1/admin/components/:componentId",
		alias: "createComponent",
		requestFormat: "json",
		parameters: [
			{
				name: "body",
				type: "Body",
				schema: CreateComponentRequest
			},
			{
				name: "componentId",
				type: "Path",
				schema: z.string()
			},
		],
		response: ComponentDto,
		errors: [
			{
				status: 400,
				description: `Jwt token has invalid sub claim`,
				schema: z.void()
			},
		]
	},
	{
		method: "delete",
		path: "/v1/admin/components/:componentId",
		alias: "deleteComponent",
		requestFormat: "json",
		parameters: [
			{
				name: "componentId",
				type: "Path",
				schema: z.string()
			},
		],
		response: z.void(),
		errors: [
			{
				status: 400,
				description: `Jwt token has invalid sub claim`,
				schema: z.void()
			},
		]
	},
	{
		method: "patch",
		path: "/v1/admin/components/:componentId",
		alias: "updateComponent",
		requestFormat: "json",
		parameters: [
			{
				name: "body",
				type: "Body",
				schema: UpdateComponentRequest
			},
			{
				name: "componentId",
				type: "Path",
				schema: z.string()
			},
		],
		response: ComponentDto,
		errors: [
			{
				status: 400,
				description: `Jwt token has invalid sub claim`,
				schema: z.void()
			},
		]
	},
	{
		method: "get",
		path: "/v1/admin/components",
		alias: "findAllComponents_1",
		requestFormat: "json",
		parameters: [
			{
				name: "page",
				type: "Query",
				schema: z.number().int().describe("Zero-based page index (0..N)").optional().default(0)
			},
			{
				name: "size",
				type: "Query",
				schema: z.number().int().describe("The size of the page to be returned").optional().default(20)
			},
			{
				name: "sort",
				type: "Query",
				schema: z.array(z.string()).describe("Sorting criteria in the format: property,(asc|desc). Default sort order is ascending. Multiple sort criteria are supported.").optional()
			},
		],
		response: ComponentDto,
	},
]);

export const component_admin_controllerApi = new Zodios(component_admin_controllerEndpoints);

const app_admin_controllerEndpoints = makeApi([
	{
		method: "get",
		path: "/v1/admin/apps/:appId",
		alias: "findAppById",
		requestFormat: "json",
		parameters: [
			{
				name: "appId",
				type: "Path",
				schema: z.string()
			},
		],
		response: AppDto,
		errors: [
			{
				status: 400,
				description: `Jwt token has invalid sub claim`,
				schema: z.void()
			},
		]
	},
	{
		method: "post",
		path: "/v1/admin/apps/:appId",
		alias: "createApp",
		requestFormat: "json",
		parameters: [
			{
				name: "body",
				type: "Body",
				schema: CreateAppRequest
			},
			{
				name: "appId",
				type: "Path",
				schema: z.string()
			},
		],
		response: z.void(),
		errors: [
			{
				status: 400,
				description: `Jwt token has invalid sub claim`,
				schema: z.void()
			},
		]
	},
	{
		method: "delete",
		path: "/v1/admin/apps/:appId",
		alias: "deleteApp",
		requestFormat: "json",
		parameters: [
			{
				name: "appId",
				type: "Path",
				schema: z.string()
			},
		],
		response: z.void(),
		errors: [
			{
				status: 400,
				description: `Jwt token has invalid sub claim`,
				schema: z.void()
			},
		]
	},
	{
		method: "patch",
		path: "/v1/admin/apps/:appId",
		alias: "updateApp",
		requestFormat: "json",
		parameters: [
			{
				name: "body",
				type: "Body",
				schema: UpdateAppRequest
			},
			{
				name: "appId",
				type: "Path",
				schema: z.string()
			},
		],
		response: AppDto,
		errors: [
			{
				status: 400,
				description: `Jwt token has invalid sub claim`,
				schema: z.void()
			},
		]
	},
	{
		method: "get",
		path: "/v1/admin/apps",
		alias: "findAllApps_1",
		requestFormat: "json",
		parameters: [
			{
				name: "page",
				type: "Query",
				schema: z.number().int().describe("Zero-based page index (0..N)").optional().default(0)
			},
			{
				name: "size",
				type: "Query",
				schema: z.number().int().describe("The size of the page to be returned").optional().default(20)
			},
			{
				name: "sort",
				type: "Query",
				schema: z.array(z.string()).describe("Sorting criteria in the format: property,(asc|desc). Default sort order is ascending. Multiple sort criteria are supported.").optional()
			},
		],
		response: AppDto,
	},
]);

export const app_admin_controllerApi = new Zodios(app_admin_controllerEndpoints);

const insight_preferences_admin_read_controllerEndpoints = makeApi([
	{
		method: "get",
		path: "/v1/admin/tenants/:tenantId/insights/preferences",
		alias: "getInsightPreferencesByTenantAsStream",
		requestFormat: "json",
		parameters: [
			{
				name: "tenantId",
				type: "Path",
				schema: z.string()
			},
		],
		response: z.void(),
		errors: [
			{
				status: NaN,
				description: `Jwt token has an invalid tenant_id claim.`,
				schema: z.void()
			},
			{
				status: NaN,
				description: `Jwt token has an invalid sub claim.`,
				schema: z.void()
			},
		]
	},
	{
		method: "get",
		path: "/v1/admin/tenants/insights/preferences/sync",
		alias: "getAllTenantInsightPreferencesSyncDataAsStream",
		requestFormat: "json",
		response: z.void(),
		errors: [
			{
				status: NaN,
				description: `Jwt token has an invalid tenant_id claim.`,
				schema: z.void()
			},
			{
				status: NaN,
				description: `Jwt token has an invalid sub claim.`,
				schema: z.void()
			},
		]
	},
]);

export const insight_preferences_admin_read_controllerApi = new Zodios(insight_preferences_admin_read_controllerEndpoints);


export function createApiClient(baseUrl: string, options?: ZodiosOptions) {
    return new Zodios(baseUrl, endpoints, options);
}
