<template>
  <div
    id="AppVisibilityDiv"
    :class="[systemStore.darkMode ? 'up-ds-theme--dark' : 'up-ds-theme--light', 'theme-container']"
    :aria-hidden="systemStore.hideMainScreenBecauseOfModal"
    data-cy="AppVisibilityDiv"
  >
    <template v-if="!apiStatus.taxonomy && !apiStatus.assets">
      <FailedApi />
    </template>

    <template v-else>
      <template v-if="!isAuthenticated">
        <div class="app-main-container">
          <main class="app-main">
            <section class="app-view">
              <AccessDenied />
            </section>
          </main>
          <Toast />
        </div>
      </template>

      <template v-else-if="isAuthenticated && isConfigurationLoaded">
        <AppHeader v-if="!hideTopToolbar" />
        <div class="app-main-container">
          <main :class="`app-main ${sidebarClass}`">
            <aside
              v-if="systemStore.showSidebar && !$route.meta.hideSidebar"
              class="app-sidebar"
            >
              <Sidebar />
            </aside>
            <section class="app-view">
              <DSLoadingState v-if="loading" />
              <router-view />
            </section>
          </main>
          <Toast />
        </div>
      </template>

      <template v-else>
        <div class="app-main-container">
          <main :class="`app-main shimmer`">
            <section class="app-view">
              <router-view />
            </section>
          </main>
          <Toast />
        </div>
      </template>
    </template>
  </div>
</template>

<script lang="ts" setup>
import { computed, onMounted, ref, watch } from 'vue';
import AppHeader from '@/components/global/AppHeader.vue';
import Sidebar from '@/components/global/Sidebar.vue';
import Toast from '@/components/global/Toast.vue';
import AccessDenied from '@/components/shared/messages/AccessDenied.vue';
import authStore from '@/features/auth/store/authStore';
import useSystemStore from '@/features/auth/store/systemStore';
import useTenantStore from '@/features/auth/tenant/store/tenantStore';
import FailedApi from '@/components/shared/messages/FailedApi.vue';
import { RouterView } from 'vue-router';
import setupGoogleAnalyticsAndHotJar from './vendor/setupGoogleAnalyticsAndHotJar';
import setupCoralogix from './vendor/setupCoralogix';
import setupWalkMe from './vendor/setupWalkMe';

const tenantStore = useTenantStore();
const systemStore = useSystemStore();

const apiStatus = ref({
  taxonomy: true,
  assets: true,
});

const hideTopToolbar = computed(() => tenantStore.hideTopToolbar);
const loading = ref(true);
const isConfigurationLoaded = computed(() => Object.keys(tenantStore.config).length > 0);
const isAuthenticated = computed(() => authStore().isAuthenticated);
const sidebarClass = computed(() => (tenantStore.horizontalNav ? 'horizontal-sidebar' : 'vertical-sidebar'));
const fetchAppVersion = async () => {
  try {
    const response = await fetch('/meta.json', { cache: 'no-cache' });
    const metaData = await response.json();
    return metaData.version;
  } catch (error) {
    console.log('Error fetching app version:', error);
    return null;
  }
};

watch(
  () => tenantStore.styles,
  () => {
    tenantStore
      .styles.map((x) => (x = [`--${x[0]}`, x[1]]))
      .forEach((x) => {
        document.body.style.setProperty(x[0], x[1]);
      });
  }
);

const checkForAppUpdates = async () => {
  const currentVersion = localStorage.getItem('appVersion');
  const latestVersion = await fetchAppVersion();

  if (latestVersion && currentVersion !== latestVersion) {
    console.log('New version available. Updating...');
    localStorage.setItem('appVersion', latestVersion);
    window.location.reload();
  }
};

onMounted(async () => {
  if (isAuthenticated.value) {
    setupGoogleAnalyticsAndHotJar();
    setupCoralogix();
    setupWalkMe();
  }

  await checkForAppUpdates();

  setTimeout(() => {
    loading.value = false;
  }, 600);
});
</script>

<style lang="scss">
::-webkit-scrollbar {
  width: 1rem;
  height: 1rem;
}

::-webkit-scrollbar-track {
  border-radius: 50%;
}

::-webkit-scrollbar-thumb {
  height: 56px;
  border-radius: 0.5rem;
  border: 0.25rem solid transparent;
  background-clip: content-box;
  background-color: ds-get-color(uptake-dark-page-background);
}

::-webkit-scrollbar-thumb:hover {
  background-color: ds-get-color(uptake-interactive-text-color);
}

body {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  @include ds-font(regular);
  @include ds-get-typography-size(text-m);
}
.shimmer {
  background: ds-get-color(uptake-light-accent);
  background-repeat: no-repeat;
  background-image: linear-gradient(
    to right,
    ds-get-color(uptake-light-accent) 0%,
    ds-get-color(uptake-info-containers) 20%,
    ds-get-color(uptake-light-accent) 40%,
    ds-get-color(uptake-light-accent) 60%,
    ds-get-color(uptake-light-accent) 80%,
    ds-get-color(uptake-light-accent) 100%
  );
  -webkit-animation-duration: 1s;
  -webkit-animation-fill-mode: forwards;
  -webkit-animation-iteration-count: infinite;
  -webkit-animation-name: placeholderShimmer;
  -webkit-animation-timing-function: linear;
}

#app {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
}

.theme-container {
  display: flex;
  flex-direction: column;
  height: 100%;
}

.app-main-container {
  background-color: ds-get-color(uptake-page-background);
  flex: 1;
  display: flex;
  width: 100%;

  .app-main {
    display: flex;
    gap: 1rem;
    padding: 0.5rem;
    padding-right: 0;
    flex: 1;
    overflow: auto;

    @include mobile {
      display: grid;
    }

    &.vertical-sidebar {
      .app-sidebar {
        position: sticky;
        top: 0;
        height: 100%;
        width: 10rem;
        overflow-y: auto;

        @include mobile {
          flex: 1;
          margin: 0 0 16px;
          width: 100%;
        }
      }
    }

    &.horizontal-sidebar {
      flex-direction: column;

      .app-sidebar {
        height: 100px;
        width: 100%;
      }
    }

    .app-view {
      width: calc(100% - 12.2rem);
      height: 100%;
    }
  }
}
</style>
@/composables/useInitialize
