<template>
  <div
    :class="$style['userprofile-dropdown']"
    data-test="UserProfileDropdown"
  >
    <DSModal
      type="alert"
      :showModal="showModal"
      :title="t('userProfile.logoutModal.title')"
      @close="onClose"
    >
      <template #title>
        <div :class="$style['userprofile-dropdown__modal-custom-title']">
          <ExclamationCircleIcon />
          <span style="padding-left: 10px">{{ t('userProfile.logoutModal.title') }}</span>
        </div>
      </template>

      <template #default>
        {{ t('userProfile.logoutModal.content') }}
      </template>
    </DSModal>
    <button
      v-if="!authStore.impersonator"
      :class="$style['userprofile-dropdown__trigger-button']"
      @mouseover="onDropOpen"
      @mouseleave="onDropClose"
    >
      <UserIcon :class="$style['userprofile-dropdown__trigger-button__icon']" />
    </button>
    <button
      v-else
      :class="$style['userprofile-dropdown__trigger-button']"
      @mouseover="onDropOpen"
      @mouseleave="onDropClose"
    >
      <UserIcon :class="$style['userprofile-dropdown__trigger-button__impersonate']" />
    </button>
    <div
      v-show="isDropOpen"
      id="drop-content"
      :class="$style['userprofile-dropdown__dropdown-content']"
      @mouseleave="onDropClose"
    >
      <div :class="$style['userprofile-dropdown__dropdown-content__clip']">
        <main :class="$style['userprofile-dropdown__dropdown-content__clip__userprofile']">
          <ul :class="$style['userprofile-dropdown__dropdown-content__clip__userprofile__userinfo']">
            <li>{{ t('userProfile.userData.userName') }} {{ authStore.userName }}</li>
            <li>{{ t('userProfile.userData.tenantCode') }} {{ authStore.tenant }}</li>
            <li>{{ t('userProfile.userData.email') }} {{ authStore.email }}</li>
            <li v-if="authStore.impersonator != ''">
              {{ t('userProfile.userData.impersonator') }} {{ authStore.impersonator.split('@')[0] }}
            </li>
          </ul>
        </main>
        <footer>
          <a
            :class="$style['userprofile-dropdown__dropdown-content__clip__userprofile__logout']"
            data-cy="HeaderPage_Logout"
            @click="logout"
          >
            {{ t('userProfile.logOut') }}
          </a>
        </footer>
      </div>
    </div>
  </div>
</template>

<script lang="ts" setup>
import { ref, inject } from 'vue';
import { useI18n } from 'vue-i18n';
import Keycloak from 'keycloak-js';
import useAuthStore from '@/features/auth/store/authStore';

const authStore = useAuthStore();
const keycloak = inject<Keycloak>('keycloak');
const showModal = ref(false);
const isDropOpen = ref(false);
const { t } = useI18n();

function onDropOpen() {
  isDropOpen.value = true;
}
function onDropClose(event: MouseEvent) {
  const relatedTarget = event.relatedTarget as HTMLElement;
  const dropdown = document.getElementById('drop-content');

  if (!relatedTarget || (dropdown && !relatedTarget.contains(dropdown))) {
    isDropOpen.value = false;
  }
}
function onClose() {
  showModal.value = false;
  keycloak?.logout();
}

async function checkImpersonation() {
  if (authStore.impersonator !== '') {
    const userProfile = await keycloak.loadUserProfile();
    authStore.email = userProfile.email;
    authStore.userName = userProfile.username;
    authStore.tenant = keycloak.realm;
  }
}

function logout(e: any) {
  localStorage.removeItem('lastVisitedRoute');
  sessionStorage.removeItem('isFirstVisitAfterLogin');
  e.preventDefault();
  isDropOpen.value = false;
  showModal.value = true;
  setTimeout(() => {
    keycloak?.logout();
  }, 5000);
}
</script>

<style module lang="scss">

$nib: 5px;
$padding-x: 12px;
$padding-y: 16px;
$dropdown-zindex: 1001;

.userprofile-dropdown {
  display: inline-block;
  position: relative;

  & + .userprofile-dropdown {
    margin-left: 4px;
  }

  &__modal-custom-title {
    display: flex;
    align-items: center;
  }

  &__dropdown-content {
    background-color: ds-get-color(uptake-modules-and-modals);
    border-radius: $border-radius;
    box-shadow: 0 0 3px rgba(ds-get-color(uptake-dark-accent), 0.29);
    min-width: 260px;
    position: absolute;
    right: -10px;
    top: 42px;
    z-index: $dropdown-zindex;
    @include ds-font(regular);
    @include ds-get-typography-size(text-s);

    @include ds-theme-inline-module('dark') {
      background-color: ds-get-color(uptake-dark-page-background);
      box-shadow: 0 0 3px rgba(ds-get-color(uptake-modules-and-modals), 0.29);
      color: ds-get-color(uptake-reverse-text-color);
    }

    &::before {
      background-color: ds-get-color(uptake-modules-and-modals);
      border: $nib solid transparent;
      border-color: transparent transparent ds-get-color(uptake-tertiary-borders) ds-get-color(uptake-tertiary-borders);
      box-shadow: -2px 2px 2px rgba(ds-get-color(uptake-dark-accent), 0.1);
      box-sizing: border-box;
      content: '';
      position: absolute;
      right: 24px;
      top: 0px;
      transform-origin: 0 0;
      transform: rotate(-225deg);

      @include ds-theme-inline-module('dark') {
        background-color: ds-get-color(uptake-dark-page-background);
        border-color: transparent transparent ds-get-color(uptake-quaternary-borders)
          ds-get-color(uptake-quaternary-borders);
        box-shadow: -2px 2px 2px rgba(ds-get-color(uptake-reverse-text-color), 0.1);
      }
    }

    &__clip {
      border-radius: $border-radius;
      overflow: hidden;

      &__userprofile {
        display: flex;

        &__userinfo {
          margin: 0;
          padding: 0;

          li {
            display: block;
            margin-bottom: 5px;
            @include ds-font(medium);
            @include ds-get-typography-size(text-xs);

            &:nth-child(1) {
              @include ds-font(bold);
              @include ds-get-typography-size(text-s);
            }

            &:nth-child(4) {
              @include ds-font(bold);
              @include ds-get-typography-size(text-s);
            }

            a,
            &:visited {
              color: ds-get-color(uptake-interactive-text-color);
              text-decoration: none;

              &:hover,
              &:active {
                text-decoration: underline;
              }

              &::after {
                background-color: ds-get-color(uptake-interactive-text-color);
                content: '';
                display: inline-block;
                height: 10px;
                margin-left: 0.25rem;
                width: 10px;
              }
            }
          }
        }

        &__logout {
          color: ds-get-color(uptake-interactive-text-color);
          cursor: pointer;
          margin: 2px 8px;
          text-decoration: none;
          text-transform: capitalize;
          @include ds-font(bold);
          @include ds-get-typography-size(text-m);
        }
      }

      main {
        padding: $padding-x $padding-y;
      }

      footer {
        background-color: ds-get-color(uptake-light-accent);
        display: flex;
        justify-content: flex-end;
        overflow: hidden;
        padding: $padding-x $padding-y;

        @include ds-theme-inline-module('dark') {
          background-color: ds-get-color(uptake-primary-background);
        }
      }
    }
  }

  &__trigger-button {
    border: none;
    background: none;

    &:hover {
      cursor: pointer;
    }

    &__icon,
    &__impersonate {
      // fill: ds-get-color(uptake-interactive-text-color);
      padding: 0px 10px;
    }

    &__impersonate {
      fill: ds-get-color(uptake-failure-text-color);
      &:hover {
        fill: ds-get-color(uptake-failure-text-color);
      }
    }
  }
}


</style>
